/* eslint-disable */
import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Locations,
  NOTI_MANAGEMENT_URL,
  SEND_NOTI_URL,
  WEB_APP_URL,
} from "../../Shared/constant";
import BackButton from "../BackButton";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useSelector } from "react-redux";
import CommonModel from "../CommonModel";
import { useLocation } from "react-router-dom";
import CommonLoader from "../CommonLoader";
import "react-toastify/dist/ReactToastify.css";
import PostRequestCallAPI from "../../Shared/PostRequestCallAPI";
import "select2/dist/css/select2.min.css";
import $ from "jquery";
import "select2";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
const AddEditNoti = () => {
  const { websocket } = useContext(WebSocketContext);
  useEffect(() => {
    let param = {
      transmit: "broadcast",
      url: "scheme_list",
      request: {
        filter: "all",
        limit: 5000,
        page_no: 1,
        search: "",
        type: "admin",
      },
    };
    wsSend_request(websocket, param);
  }, []);
  const Location = useLocation()?.pathname?.split("/")[1];
  const { schemeList, accessToken, device_id, loaderMain } = useSelector(
    (state) => state.adminReducers
  );

  const [selectedSchemeProvider, setSelectedSchemeProvider] = useState("");
  const selectLocationsRef = useRef();
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [showModal, setShowModal] = useState({
    open: false,
    title: "",
    modalType: "",
    Data: {},
  });

  const SendNotification = () => {
    const url = WEB_APP_URL + SEND_NOTI_URL;
    const payload = {
      tos: selectedLocations.join(","),
      message: selectedSchemeProvider,
    };
    const response = PostRequestCallAPI(url, payload, accessToken).then(
      (responseData) => {
        if (responseData) {
          toast.success("Notification send successfully");
        } else {
          toast.error(responseData?.error);
        }
      }
    );
  };

  useEffect(() => {
    const $select = $(selectLocationsRef.current);
    $select.select2();
  
    // Event handler for changes in the selection
    $select.on("change", function (e) {
      let selectedValues = $(this).val() || [];
      if (selectedValues.includes("Central Government")) {
        selectedValues = ["Central Government"];
      }
  
      console.log("selectedValues", selectedValues);
      setSelectedLocations(selectedValues);
    });
  
    return () => {
      $select.select2("destroy");
      $select.off("change");
    };
  }, [selectedLocations]);
  

  const locationOptions = Object.entries(Locations).map(
    ([locationName, locationAbbreviation]) => ({
      value: locationName, // Store state name instead of state code
      label: locationName,
    })
  );

  const selectSchemeProviderRef = useRef();

  useEffect(() => {
    const $select = $(selectSchemeProviderRef.current);
    $select.select2();

    // Event handler for changes in the selection
    $select.on("change", function (e) {
      const selectedValue = $(this).val() || "";
      setSelectedSchemeProvider(selectedValue);
    });

    return () => {
      $select.select2("destroy");
      $select.off("change");
    };
  }, []);

  return (
    <React.Fragment>
      <div
        className="header_back header_back_with_btn"
        style={{
          justifyContent: Location !== "edit-product" ? "end" : "end",
        }}
      >
        <BackButton targeturl={NOTI_MANAGEMENT_URL} title={"Back"} />
      </div>
      <section className="dashboard_wrapper_form">
        <div className="scrollform">
          <Scrollbars
            style={{ height: "calc(100vh - 150px)" }}
            className="ScrollbarsWrapper"
            renderView={(props) => <div {...props} className="view" />}
          >
            <div className="scrollformCustom">
              <div className="row" style={{ margin: "0 auto" }}>
                <div className="col-md-12">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Select State's</label>
                      <select
                        ref={selectLocationsRef}
                        className="form-control activestatusselet"
                        multiple={true}
                        value={selectedLocations}
                        style={{ width: "100%" }}
                      >
                        <option value="Central Government">
                          Central Government
                        </option>
                        {locationOptions.map((location) => (
                          <option value={location.value} key={location.value}>
                            {location.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Notification For</label>
                      <select
                        ref={selectSchemeProviderRef}
                        className="form-control activestatusselet"
                        value={selectedSchemeProvider}
                        style={{ width: "100%" }}
                      >
                        <option value="">Select Scheme</option>
                        {schemeList?.list?.map((provider) => (
                          <option value={provider.id} key={provider.id}>
                            {provider.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="form-group-btn" style={{ textAlign: "left" }}>
                    <button
                      onClick={() => SendNotification()}
                      className="btn addbtncmn"
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Scrollbars>
        </div>
        {/* loader start here */}
        {loaderMain && <CommonLoader />}
      </section>
      {showModal?.open && (
        <CommonModel
          fromUrl={"product"}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </React.Fragment>
  );
};

export default AddEditNoti;
