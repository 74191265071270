import { ADMIN_GET_USER_LIST_API_URL } from "../components/Shared/constant";

const GetUserListAPI = async (
  accessToken,
  userSearch,
  userType,
  userLimit,
  currentPage
) => {
  const responce = window.axios
    .get(
      `${ADMIN_GET_USER_LIST_API_URL}/${userType}/${userLimit}/${currentPage}/${userSearch}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
        },
      }
    )
    .then(function (result) {
      return {
        ...result.data,
        status: 200,
      };
    })
    .catch(function (result) {
      return {
        ...result?.response?.data,
        status: result?.response?.status,
      };
    });
  return responce;
};

export default GetUserListAPI;
