/* eslint-disable */
import { update } from "immutable";
import { ActionTypes } from "../actions/adminActions";

const initailData = {
  is_login: "no",
  user_id: "",
  device_id: "",
  selfInfo: {},
  accessToken: "",
  permissionList: [],
  dashboardCount: {},
  userList: [],
  userListIdWise: {},

  modelList: {
    list: [],
    pagination: {},
  },

  contactUsList: {
    list: [],
    pagination: {},
  },
  contactUsListall: [],
  supplierdetails: {},
  schemeList: {
    list: [],
    pagination: {},
  },
  documentList: {
    list: [],
    pagination: {},
  },
  documentListAll: [],
  faqListAll: [],
  schemeListAll: [],
  modellistall: [],
  viewProduct: {},
  loaderMain: false,
  ClearCaches_setww: "",
  categoryList: {
    list: [],
    pagination: {},
  },
  schemeProviderList: { list: [], pagination: {} },
  categoryAllList: [],
  schemeProviderAllList: [],
  ClearFormSet: {
    url: "",
    action: false,
  },
  settinglists: {
    list: [],
    pagination: {},
  },
  settingsObjectIdWise: {},
  languageList: [],
};

export const adminReducers = (state = initailData, action) => {
  switch (action.type) {
    case ActionTypes.IS_LOGIN:
      return {
        ...state,
        is_login: action.payload,
      };
    case ActionTypes.GET_USER_ID:
      return {
        ...state,
        user_id: action.payload,
      };
    case ActionTypes.GET_DEVICE_ID:
      return {
        ...state,
        device_id: action.payload,
      };
    case ActionTypes.SELF_DETAILS:
      return {
        ...state,
        selfInfo: action.payload,
      };
    case ActionTypes.CLEAR_FORM_SET_FUCT:
      return {
        ...state,
        ClearFormSet: {
          url: action?.payload?.url,
          action: action?.payload?.action,
        },
      };
    case ActionTypes.EDIT_SELF_DETAILS:
      return {
        ...state,
        selfInfo: action.payload,
      };
    case ActionTypes.GET_ACCESSTOKEN:
      return {
        ...state,
        accessToken: action.payload,
      };
    case ActionTypes.LOADER_MAIN_START:
      return {
        ...state,
        loaderMain: action.payload,
      };
    case ActionTypes.GET_USER_LIST:
      return {
        ...state,
        userList: action.payload,
      };
    case ActionTypes.GET_DASHBOARD_DETAILS:
      return {
        ...state,
        dashboardCount: action.payload,
      };
    case ActionTypes.GET_USER_LIST_ID_WISE:
      const ObjectUserListId = {};
      action.payload.map((elm) => {
        ObjectUserListId[elm?.id] = elm;
      });

      return {
        ...state,
        userListIdWise: ObjectUserListId,
      };
    case ActionTypes.ADD_USER_LIST:
      const OldUserlist = [...state.userList];
      OldUserlist.unshift(action.payload);
      return {
        ...state,
        userList: OldUserlist,
      };
    case ActionTypes.EDIT_USER_LIST:
      const OldUserlistx = [...state.userList];
      const UpdateUserList = OldUserlistx.map((elm) => {
        if (elm?.id === action.payload.id) {
          return {
            ...elm,
            first_name: action.payload.first_name,
            last_name: action.payload.last_name,
            email: action.payload.email,
            password: action.payload.password,
            phone_code: action.payload.phone_code,
            phone: action.payload.phone,
            address: action.payload.address,
            permissions: action.payload.permissions,
            is_admin: action.payload.is_admin,
            is_staff: action.payload.is_staff,
            is_active: action.payload.is_active,
          };
        } else return elm;
      });
      return {
        ...state,
        userList: UpdateUserList,
      };
    case ActionTypes.DELETE_USER:
      const OldUserListz = [...state.userList];
      const NewUpdatedUserList = [];
      OldUserListz &&
        OldUserListz.filter((elm) => {
          if (elm?.id !== action.payload.id) {
            return NewUpdatedUserList.push(elm);
          }
        });
      return {
        ...state,
        userList: NewUpdatedUserList,
      };

    case ActionTypes.GET_ALL_PRODUCT_LIST:
      return {
        ...state,
        schemeListAll:
          Object.keys(action.payload).length === 0 ||
          action.payload.data.data === undefined
            ? []
            : action.payload.data.data,
      };
    case ActionTypes.GET_ALL_DOCUMENT_LIST:
      console.log(action?.payload)
      return {
        ...state,
        documentListAll:
          Object.keys(action.payload).length === 0 ||
          action.payload.data.data === undefined
            ? []
            : action.payload.data.data,
      };
    case ActionTypes.GET_ALL_FAQ_LIST:
      return {
        ...state,
        faqListAll:
          Object.keys(action.payload).length === 0 ||
          action.payload.data.data === undefined
            ? []
            : action.payload.data.data,
      };

    case ActionTypes.GET_ALL_MODEL_LIST:
      return {
        ...state,
        modellistall:
          Object.keys(action.payload).length === 0
            ? []
            : action.payload?.data?.data,
      };

    case ActionTypes.GET_MODEL_LIST:
      return {
        ...state,
        modelList: {
          list:
            Object.keys(action.payload).length === 0
              ? []
              : action.payload.data.data,
          pagination:
            Object.keys(action.payload).length === 0
              ? []
              : action.payload.data.pagination,
        },
      };

    case ActionTypes.GET_PERMISSION_LIST:
      return {
        ...state,
        permissionList: action.payload,
      };
    case ActionTypes.GET_ALL_SUPPLIERS_LIST:
      return {
        ...state,
        contactUsListall:
          Object.keys(action.payload).length === 0
            ? []
            : action.payload?.data?.data,
      };
    case ActionTypes.GET_SUPPLIER_LIST:
      return {
        ...state,
        contactUsList: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };
    case ActionTypes.GET_CONTACTS_US_LIST:
      return {
        ...state,
        contactUsList: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };
    case ActionTypes.ADD_SUPPLIER_LIST:
      const OldSupplierlist = [...state.contactUsList.list];
      OldSupplierlist.unshift(action.payload.data);
      const OldSupplierlistAll = [...state.contactUsListall];
      OldSupplierlistAll.unshift(action.payload.data);
      return {
        ...state,
        contactUsList: {
          list: OldSupplierlist,
        },
        contactUsListall: OldSupplierlistAll,
      };
    case ActionTypes.UPDATE_SUPPLIER_LIST:
      const OldSupplierlistx = [...state.contactUsList.list];
      const UpdateSupplierList = OldSupplierlistx.map((elm) => {
        if (elm?.id === action.payload.id) {
          return {
            ...elm,
            title: action.payload.title,
            website: action.payload.website,
            is_active: action.payload.is_active,
            name: action.payload.name,
            email: action.payload.email,
            phone_code: action.payload.phone_code,
            phone: action.payload.phone,
            company: action.payload.company,
            address: action.payload.address,
            vat_registration_no: action.payload.vat_registration_no,
            registration_no: action.payload.registration_no,
            contacts: action.payload.contacts,
          };
        } else return elm;
      });
      return {
        ...state,
        contactUsList: {
          list: UpdateSupplierList,
        },
      };
    case ActionTypes.DELETE_SUPPLIER:
      const OldSupplierListz = [...state.contactUsList.list];
      const NewUpdatedSupplierList = [];
      OldSupplierListz &&
        OldSupplierListz?.filter((elm) => {
          if (elm?.id !== action.payload.id) {
            return NewUpdatedSupplierList.push(elm);
          }
        });
      return {
        ...state,
        contactUsList: {
          list: NewUpdatedSupplierList,
        },
      };
    case ActionTypes.GET_SUPPLIER_DETAILS:
      return {
        ...state,
        supplierdetails: action.payload,
      };
    case ActionTypes.GET_PRODUCT_LIST:
      return {
        ...state,
        schemeList: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };
    case ActionTypes.GET_DOCUMENT_LIST:
      return {
        ...state,
        documentList: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };
    case ActionTypes.GET_FAQ_LIST:
      return {
        ...state,
        faqList: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };
    case ActionTypes.ADD_PRODUCT_LIST:
      const OldProductlist = [...state.contactUsList.list];
      OldProductlist.unshift(action.payload);
      return {
        ...state,
        schemeList: {
          list: OldProductlist,
        },
      };
    case ActionTypes.ADD_DOCUMENT_LIST:
      const OldDocumentlist = [...state.contactUsList.list];
      OldDocumentlist.unshift(action.payload);
      return {
        ...state,
        documentList: {
          list: OldDocumentlist,
        },
      };
    case ActionTypes.ADD_FAQ_LIST:
      const OldFAQlist = [...state.contactUsList.list];
      OldFAQlist.unshift(action.payload);
      return {
        ...state,
        documentList: {
          list: OldFAQlist,
        },
      };
    case ActionTypes.UPDATE_PRODUCT_LIST:
      const OldProductlistx = [...state.schemeList.list];
      const UpdateProductList = OldProductlistx.map((elm) => {
        if (elm?.id === action.payload.id) {
          return {
            ...elm,
            name: action.payload.name,
          };
        } else return elm;
      });
      return {
        ...state,
        schemeList: {
          list: UpdateProductList,
        },
      };
    case ActionTypes.UPDATE_DOCUMENT_LIST:
      const oldDocs = [...state.schemeList.list];
      const UpdatedDocs = oldDocs.map((elm) => {
        if (elm?.id === action.payload.id) {
          return {
            ...elm,
            name: action.payload.name,
          };
        } else return elm;
      });
      return {
        ...state,
        documentList: {
          list: UpdatedDocs,
        },
      };
    case ActionTypes.UPDATE_FAQ_LIST:
      const oldFAQ = [...state.schemeList.list];
      const UpdatedFAQ = oldFAQ.map((elm) => {
        if (elm?.id === action.payload.id) {
          return {
            ...elm,
            name: action.payload.name,
          };
        } else return elm;
      });
      return {
        ...state,
        documentList: {
          list: UpdatedFAQ,
        },
      };
    case ActionTypes.DELETE_PRODUCT:
      const OldProductListz = [...state.schemeList.list];
      const NewUpdatedProductList = [];
      OldProductListz &&
        OldProductListz?.filter((elm) => {
          if (elm?.id !== action.payload.id) {
            return NewUpdatedProductList.push(elm);
          }
        });
      return {
        ...state,
        schemeList: {
          list: NewUpdatedProductList,
        },
      };
    case ActionTypes.DELETE_DOCUMENT:
      const oldDocsbefore = [...state.schemeList.list];
      const NewDocsafterdelete = [];
      oldDocsbefore &&
        oldDocsbefore?.filter((elm) => {
          if (elm?.id !== action.payload.id) {
            return NewDocsafterdelete.push(elm);
          }
        });
      return {
        ...state,
        documentList: {
          list: NewDocsafterdelete,
        },
      };
    case ActionTypes.DELETE_FAQ:
      const oldFAQBfore = [...state.schemeList.list];
      const NewFaqFiltereddelete = [];
      oldFAQBfore &&
        oldFAQBfore?.filter((elm) => {
          if (elm?.id !== action.payload.id) {
            return NewFaqFiltereddelete.push(elm);
          }
        });
      return {
        ...state,
        faqList: {
          list: NewFaqFiltereddelete,
        },
      };
    case ActionTypes.VIEW_PRODUCT_DETAILS:
      return {
        ...state,
        viewProduct: action.payload,
      };
    case ActionTypes.GET_CATEGORY_LIST:
      return {
        ...state,
        categoryList: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };
    case ActionTypes.GET_SCHEME_PROVIDER_LIST:
      return {
        ...state,
        schemeProviderList: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };
    case ActionTypes.GET_ALL_CATEGORY_LIST:
      return {
        ...state,
        categoryAllList:
          Object.keys(action.payload).length === 0 ||
          action.payload.data.data === undefined
            ? []
            : action.payload.data.data,
      };
    case ActionTypes.GET_ALL_LANGUAGE_LIST:
      return {
        ...state,
        languageList:
          Object.keys(action.payload).length === 0 ||
          action.payload.data === undefined
            ? []
            : action.payload.data,
      };
    case ActionTypes.GET_ALL_SCHEME_PROVIDER_LIST:
      return {
        ...state,
        schemeProviderAllList:
          Object.keys(action.payload).length === 0 ||
          action.payload.data.data === undefined
            ? []
            : action.payload.data.data,
      };
      
    case ActionTypes.ADD_CATEGORY_LIST:
      const OldCategorylist = [...state.categoryList.list];
      OldCategorylist.unshift(action.payload);
      return {
        ...state,
        categoryList: {
          list: OldCategorylist,
        },
      };
      case ActionTypes.ADD_SCHEME_PROVIDER_LIST:
        console.log("addSchemeProviderList",state, "\n print schemeProviderList",state?.schemeProviderList)
        const OldProviderList = [...state.schemeProviderList?.list];
        OldProviderList.unshift(action.payload);
        console.log("OldProviderList",OldProviderList)
        return {
          ...state,
          schemeProviderList: {
            list: OldProviderList,
          },
        };
    case ActionTypes.UPDATE_CATEGORY_LIST:
      const OldCategorylistx = [...state.categoryList.list];
      const UpdateCategoryList = OldCategorylistx.map((elm) => {
        if (elm?.id === action.payload.id) {
          return {
            ...elm,
            name: action.payload.name,
            is_active: action.payload.is_active,
          };
        } else return elm;
      });
      return {
        ...state,
        categoryList: {
          list: UpdateCategoryList,
        },
      };
    case ActionTypes.UPDATE_SCHEMEPROVIDER_LIST:
      const oldschemeproviders = [...state.categoryList.list];
      const updateschemeproviderlists = oldschemeproviders.map((elm) => {
        if (elm?.id === action.payload.id) {
          return {
            ...elm,
            name: action.payload.name,
            is_active: action.payload.is_active,
          };
        } else return elm;
      });
      return {
        ...state,
        schemeProviderList: {
          list: updateschemeproviderlists,
        },
      };

    case ActionTypes.DELETE_CATEGORY_LIST:
      const OldCategoryListz = [...state.schemeList.list];
      const NewUpdatedCategoryList = [];
      OldCategoryListz &&
        OldCategoryListz?.filter((elm) => {
          if (elm?.id !== action.payload.id) {
            return NewUpdatedCategoryList.push(elm);
          }
        });
      return {
        ...state,
        categoryList: {
          list: NewUpdatedCategoryList,
        },
      };
    case ActionTypes.DELETE_SCHEME_PROVIDER_LIST:
      const oldschemeproderdata = [...state.schemeList.list];
      const NewUpdatedSchemeProvidersList = [];
      oldschemeproderdata &&
        oldschemeproderdata?.filter((elm) => {
          if (elm?.id !== action.payload.id) {
            return NewUpdatedSchemeProvidersList.push(elm);
          }
        });
      return {
        ...state,
        schemeProviderList: {
          list: NewUpdatedSchemeProvidersList,
        },
      };
    case ActionTypes.GET_SETTINGS_LIST:
      const ObjectSettingsListId = {};
      action.payload?.data?.data.map((elm) => {
        ObjectSettingsListId[elm?.slug] = elm;
      });

      return {
        ...state,
        settinglists: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
        settingsObjectIdWise: ObjectSettingsListId,
      };
    case ActionTypes.ADD_SETTINGS_LIST:
      const OldSettinglist = [...state.settinglists.list];
      OldSettinglist.unshift(action.payload);
      return {
        ...state,
        settinglists: {
          list: OldSettinglist,
        },
      };
    case ActionTypes.UPDATE_CATEGORY_LIST:
      const OldSettinglistx = [...state.settinglists.list];
      const UpdateSettingList = OldSettinglistx.map((elm) => {
        if (elm?.id === action.payload.id) {
          return {
            ...elm,
            name: action.payload.name,
          };
        } else return elm;
      });
      return {
        ...state,
        settinglists: {
          list: UpdateSettingList,
        },
      };
    case ActionTypes.DELETE_SETTINGS_LIST:
      const OldsettinglistsListz = [...state.settinglists.list];
      const NewUpdatedsettinglistsList = [];
      OldsettinglistsListz &&
        OldsettinglistsListz?.filter((elm) => {
          if (elm?.id !== action.payload.id) {
            return NewUpdatedsettinglistsList.push(elm);
          }
        });
      return {
        ...state,
        settinglists: {
          list: NewUpdatedsettinglistsList,
        },
      };
    case ActionTypes.ADMIN_LOGOUT:
      return {
        ...initailData,
      };
    default:
      return state;
  }
};
