/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import BackButton from "../Common/BackButton";
import { SCHEME_MANAGEMENT_URL } from "../Shared/constant";
import AvatarGet from "../Common/AvatarGet";
import { Scrollbars } from "react-custom-scrollbars-2";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import CommonLoader from "../Common/CommonLoader";

const ViewScheme = () => {
  const params = useParams();
  const { websocket } = useContext(WebSocketContext);
  const {
    selfInfo,
    viewProduct,
    categoryList,
    schemeProviderAllList,
    userListIdWise,
    loaderMain,
    device_id,
    documentList
  } = useSelector((state) => state.adminReducers);


  useEffect(() => {
    let param = {
      transmit: "broadcast",
      url: "scheme_category_list",
      request: {
        filter: "all",
        limit: 5000,
        page_no: 1,
        search: "",
        type: "admin",
      },
    };
    wsSend_request(websocket, param);
  }, []);
  useEffect(() => {
    let param = {
      transmit: "broadcast",
      url: "document_list",
      request: {
        filter: "all",
        limit: 5000,
        page_no: 1,
        search: "",
        type: "admin",
      },
    };
    wsSend_request(websocket, param);
  }, []);
  useEffect(() => {
    let param = {
      transmit: "broadcast",
      url: "scheme_provider_list",
      request: {
        filter: "all",
        limit: 5000,
        page_no: 1,
        search: "",
        type: "admin",
      },
    };
    wsSend_request(websocket, param);
  }, []);
  useEffect(() => {
    let param = {
      transmit: "broadcast",
      url: "document_list",
      request: {
        filter: "all",
        limit: 5000,
        page_no: 1,
        search: "",
        type: "admin",
      },
    };
    wsSend_request(websocket, param);
  }, []);
  useEffect(() => {
    if (params?.roomId) {
      let param = {
        transmit: "broadcast",
        url: "get_scheme_detail",
        request: {
          scheme_id: params?.roomId,
          DeviceId: device_id,
        },
      };
      wsSend_request(websocket, param);
    }
  }, [params?.roomId]);
  const ProviderNameGet = (id) => {
    if (id) {
      const providerIds = id.split(",");

      const providerNames = providerIds.map((providerId) => {
        const provider = schemeProviderAllList.find(
          (elm) => providerId === elm?.id
        );
        return provider ? provider.name : null;
      });

      return providerNames.join(", ");
    }
  };

  // model name get
  const CategoryNameGet = (model_id) => {
    return categoryList?.list?.filter((elm) => model_id === elm?.id)[0]?.name;
  };
  function handleLinkClick(event) {
    const target = event.target;

    // Check if the clicked element is an anchor (a) element
    if (target.tagName === "A") {
      // Prevent the default behavior of opening the link
      event.preventDefault();

      // Get the href attribute from the clicked link
      const href = target.getAttribute("href");

      // Redirect the user to the specified URL
      window.open(href, "_blank"); // Opens the link in a new tab
    }
  }
  const DocumentNameGet = (id)=>{
    if (id) {
      const providerIds = id.split(",");

      const providerNames = providerIds.map((providerId) => {
        const provider = documentList?.list?.find(
          (elm) => providerId === elm?.id
        );
        return provider ? provider.name : null;
      });

      return providerNames.join(", ");
    }
  }
  return (
    <section className="dashboard_wrapper">
      <BackButton targeturl={SCHEME_MANAGEMENT_URL} title={"Back"} />
      <div className="view_details">
        <Scrollbars
          style={{ height: "calc(100vh - 153px)" }}
          className="ScrollbarsWrapper"
          renderView={(props) => <div {...props} className="view" />}
        >
          <div className="row" style={{ margin: "0 auto" }}>
            <div className="col-md-4">
              <div className="form-group">
                <label>Picture</label>
                <div className="product_avatar">
                  <AvatarGet type="product" url={viewProduct?.view_file_url} />
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Scheme Name</label>
                    <div className="text-show">{viewProduct?.name}</div>
                  </div>
                </div>
                {selfInfo?.is_admin && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Added By </label>
                      <div className="text-show">
                        {userListIdWise[viewProduct?.added_by]?.email}
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Start Date</label>
                    <div className="text-show">
                      {moment(viewProduct.start_date).format("LL")}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>End Date</label>
                    <div className="text-show">
                      {moment(viewProduct.end_date).format("LL")}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Language</label>
                    <div className="text-show">{viewProduct?.language}</div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Document's</label>
                    <div className="text-show">{DocumentNameGet(viewProduct?.documents) === null
                              ? "-"
                              : DocumentNameGet(viewProduct?.documents)}</div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Scheme Provider</label>
                    <div className="text-show">  {ProviderNameGet(viewProduct?.provider) === null
                              ? "-"
                              : ProviderNameGet(viewProduct?.provider)}</div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Scheme Category</label>
                    <div className="text-show">    {CategoryNameGet(viewProduct?.category) === null
                              ? "-"
                              : CategoryNameGet(viewProduct?.category)}</div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Eligibility</label>
                    <div className="text-show">
                      <div
                        className="text-show"
                        dangerouslySetInnerHTML={{
                          __html: viewProduct?.eligibility,
                        }}
                        onClick={handleLinkClick}
                      ></div>{" "}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Benefits</label>
                    <div className="text-show">
                      <div
                        className="text-show"
                        dangerouslySetInnerHTML={{
                          __html: viewProduct?.benefits,
                        }}
                        onClick={handleLinkClick}
                      ></div>{" "}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Description</label>
                    <div className="text-show">
                      <div
                        className="text-show"
                        dangerouslySetInnerHTML={{
                          __html: viewProduct?.description,
                        }}
                        onClick={handleLinkClick}
                      ></div>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Scrollbars>
      </div>
      {/* loader start here */}
      {loaderMain && <CommonLoader />}
    </section>
  );
};

export default ViewScheme;
