/* eslint-disable */
import React, { useState, useContext, useEffect, useRef } from "react";
import {
  DOCUMENT_MANAGEMENT_URL,
  SCHEME_MANAGEMENT_URL,
} from "../Shared/constant";
import BackButton from "../Common/BackButton";
import InputField from "../Common/InputField";
import CheckValid from "../Common/CheckValid";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import { OverlayTrigger, Tooltip, Spinner, Image } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useSelector, useDispatch } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import UploadFileAPI from "../../Api/UploadFileAPI";
import CommonModel from "../Common/CommonModel";
import AvatarGet from "../Common/AvatarGet";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CommonLoader from "../Common/CommonLoader";
import "select2/dist/css/select2.min.css";
import $ from "jquery";
import "select2";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromHTML,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import ViewDocument from "../Common/DocumentManagement/ViewDocument";
const AddEditScheme = () => {
  const Navigate = useNavigate();
  const Dispatch = useDispatch();
  const params = useParams();
  const Location = useLocation()?.pathname?.split("/")[1];
  const { websocket } = useContext(WebSocketContext);
  const [inputValue, setInputValue] = useState({
    language: "",
    scheme_name: "",
    avatar: "",
    start_date: "",
    end_date: "",
    category: "",
    eligibility: "",
    benefits: "",
    description: "",
    created_at: "",
    updated_at: "",
    lang_parent: "",
  });
  const {
    language,
    scheme_name,
    avatar,
    start_date,
    end_date,
    category,
    eligibility,
    benefits,
    description,
    created_at,
    updated_at,
    lang_parent,
  } = inputValue;
  const [Category, setCategory] = useState("");
  const [Document, setDocument] = useState("");
  const [languageParent, setlanguageParent] = useState("");

  const [endDateSelect, setEndDateSelect] = useState("");
  const [SelectedLanguage, setSelectedLanguage] = useState("");
  const {
    viewProduct,
    categoryAllList,
    documentList,
    schemeList,
    schemeProviderAllList,
    accessToken,
    device_id,
    loaderMain,
    languageList,
    ClearFormSet,
  } = useSelector((state) => state.adminReducers);
  const [DateSelect, setDateSelect] = useState();
  const [UploadFileLoader, setUploadFileLoader] = useState(false);
  const [AvatarImage, setAvatarImage] = useState("");
  const [AvatarImageId, setAvatarImageId] = useState("");
  const [showModal, setShowModal] = useState({
    open: false,
    title: "",
    modalType: "",
    Data: {},
  });
  const [selectedDocumentIds, setSelectedDocumentIds] = useState([]);
  console.log("selectedDocumentIds",selectedDocumentIds)
  const [selectedSchemeProviders, setSelectedSchemeProviders] = useState([]);

  // error field
  const [errorSchemeName, seterrorSchemeName] = useState("");
  const [errorEligibility, seterrorEligibility] = useState("");
  const [errorBenefits, seterrorBenefits] = useState("");
  const selectRef = useRef();


  const fetchData = () => {
    wsSend_request(websocket, {
      transmit: "broadcast",
      url: "scheme_category_list",
      request: {
        filter: "all",
        limit: 5000,
        page_no: 1,
        search: "",
        type: "admin",
      DeviceId: device_id,

      },
    });
  
    wsSend_request(websocket, {
      transmit: "broadcast",
      url: "scheme_list",
      request: {
        filter: "all",
        limit: 5000,
        page_no: 1,
        search: "",
        type: "admin",
      DeviceId: device_id,

      }
    });
  
    wsSend_request(websocket, {
      transmit: "broadcast",
      url: "document_list",
      request: {
        filter: "all",
        limit: 5000,
        page_no: 1,
        search: "",
        type: "admin",
      DeviceId: device_id,

      }
    });
  
    wsSend_request(websocket, {
      transmit: "broadcast",
      url: "scheme_provider_list",
      request: {
        filter: "all",
        limit: 5000,
        page_no: 1,
        search: "",
        type: "admin",
      DeviceId: device_id,

      }
    });
  };
  
  // Call the fetchData function
  
  useEffect(() => {
    fetchData();
  }, []);
  

  // get product details
  useEffect(() => {
    if (Location === "edit-scheme") {
      if (params?.roomId) {
        let param = {
          transmit: "broadcast",
          url: "get_scheme_detail",
          request: {
            scheme_id: params?.roomId,
            DeviceId: device_id,
          },
        };
        wsSend_request(websocket, param);
      }
    }
  }, [params?.roomId, Location]);
  useEffect(() => {
    if (Location === "edit-scheme") {
      if (viewProduct) {
        console.log("viewProduct", viewProduct);
        // Convert the HTML content to ContentState
        let descriptionContentState = ContentState.createFromText(""); // Default to an empty state
        if (viewProduct?.start_date) {
          const moment = require("moment-timezone"); // Import moment with timezone support
          const isoDate = new Date(viewProduct?.start_date);
          const formattedDate = moment(isoDate);
          setDateSelect(formattedDate?._d || formattedDate?._i || null);
        }
        if (viewProduct?.end_date) {
          const moment = require("moment-timezone"); // Import moment with timezone support
          const isoDate = new Date(viewProduct?.end_date);
          const formattedDate = moment(isoDate);
          setEndDateSelect(formattedDate?._d || formattedDate?._i || null);
        }

        if (viewProduct?.description) {
          const blocksFromHTML = convertFromHTML(viewProduct.description);
          if (blocksFromHTML && blocksFromHTML.contentBlocks) {
            descriptionContentState = ContentState.createFromBlockArray(
              blocksFromHTML.contentBlocks,
              blocksFromHTML.entityMap
            );
          }
        }

        // Create an EditorState with the ContentState
        let descriptionEditorState = EditorState.createWithContent(
          descriptionContentState
        );
        setEditorState(descriptionEditorState);

        if (viewProduct?.eligibility) {
          const blocksFromHTML = convertFromHTML(viewProduct.eligibility);
          if (blocksFromHTML && blocksFromHTML.contentBlocks) {
            descriptionContentState = ContentState.createFromBlockArray(
              blocksFromHTML.contentBlocks,
              blocksFromHTML.entityMap
            );
          }
        }

        // Create an EditorState with the ContentState
        descriptionEditorState = EditorState.createWithContent(
          descriptionContentState
        );
        setEligibilityEditorState(descriptionEditorState);

        if (viewProduct?.benefits) {
          const blocksFromHTML = convertFromHTML(viewProduct.benefits);
          if (blocksFromHTML && blocksFromHTML.contentBlocks) {
            descriptionContentState = ContentState.createFromBlockArray(
              blocksFromHTML.contentBlocks,
              blocksFromHTML.entityMap
            );
          }
        }

        // Create an EditorState with the ContentState
        descriptionEditorState = EditorState.createWithContent(
          descriptionContentState
        );
        setBenefitsEditorState(descriptionEditorState);
        setAvatarImage(
          viewProduct?.avatar === null ? null : viewProduct?.view_thumbnail_url
        );
        if (viewProduct?.avatar) {
          setAvatarImageId(viewProduct.avatar);
        }

        let foundObject = categoryAllList.find(
          (item) => item.id === viewProduct?.category
        );

        if (foundObject) {
          setCategory(foundObject?.id);
        } else {
          console.log("Object with id", "not found.");
        }
        if (viewProduct?.documents) {
          const documentIdsString = viewProduct?.documents;
          const documentIdsArray = documentIdsString.split(",");
          setSelectedDocumentIds(documentIdsArray);
        }
        if (viewProduct?.provider) {
          const providerIdsString = viewProduct?.provider;
          const providerIdsArray = providerIdsString.split(",");
          setSelectedSchemeProviders(providerIdsArray);
        }
        if(viewProduct?.lang_parent){
        const filteredSchemesList = schemeList?.list?.find(
          (item) => item.id === viewProduct?.lang_parent
        );
        if(filteredSchemesList){
        setlanguageParent(filteredSchemesList?.id)
        }
        }

        setSelectedLanguage(viewProduct?.language);
        setInputValue({
          ...inputValue,
          language: viewProduct?.language,
          scheme_name: viewProduct?.name,
          avatar: viewProduct?.avatar,
          start_date: viewProduct?.start_date,
          end_date: viewProduct?.end_date,
          category: viewProduct?.category,
          eligibility: viewProduct?.eligibility,
          benefits: viewProduct?.benefits,
          description: viewProduct?.description,
          created_at: viewProduct?.created_at,
          updated_at: viewProduct?.updated_at,
          lang_parent: viewProduct?.lang_parent,
          provider: ViewDocument?.provider,
        });
      }
    }
  }, [viewProduct]);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [eligibilityEditorState, setEligibilityEditorState] = useState(
    EditorState.createEmpty()
  );
  const [benefitsEditorState, setBenefitsEditorState] = useState(
    EditorState.createEmpty()
  );
  const onEligibilityEditorChange = (newEditorState) => {
    setEligibilityEditorState(newEditorState);
    const contentState = newEditorState.getCurrentContent();
    const contentAsRaw = convertToRaw(contentState);
    const htmlContent = draftToHtml(contentAsRaw);
    setInputValue({ ...inputValue, eligibility: htmlContent });
  };

  const onBenefitsEditorChange = (newEditorState) => {
    setBenefitsEditorState(newEditorState);
    const contentState = newEditorState.getCurrentContent();
    const contentAsRaw = convertToRaw(contentState);
    const htmlContent = draftToHtml(contentAsRaw);

    setInputValue({ ...inputValue, benefits: htmlContent });
  };

  const onEditorChange = (newEditorState) => {
    setEditorState(newEditorState);
    const contentState = editorState.getCurrentContent();
    const contentAsRaw = convertToRaw(contentState);
    const htmlContent = draftToHtml(contentAsRaw);

    setInputValue({ ...inputValue, description: htmlContent });
  };
  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // select category
  const SelectOnnchangeCategory = (e) => {
    if (e.target.value !== "") {
      setCategory(e.target.value);
    } else {
      setCategory("");
    }
  };

  // upload product image
  const UploadImage = async (e) => {
    setUploadFileLoader(true);
    const files = e.target.files[0];
    const formData = new FormData();
    formData.append("file", files, files.name);
    const fileresponse = await UploadFileAPI(accessToken, formData);
    if (fileresponse) {
      setUploadFileLoader(false);
      setAvatarImage(fileresponse?.view_thumbnail_url);
      setAvatarImageId(fileresponse?.id);
    }
  };

  // remove image uploaded
  const RemoveUploadImage = () => {
    setAvatarImage("");
    setAvatarImageId("");
  };

  // add new category
  const AddCategoryFnct = () => {
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Add Category",
      subtitle: "Add Category",
      description: ``,
      modalType: "form",
      button: "Yes",
    });
  };
  const AddProviderFnct = () => {
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Add Scheme Provider",
      subtitle: "Add Scheme Provider",
      description: ``,
      modalType: "form",
      button: "Yes",
    });
  };

  const CreateProduct = () => {
    if (Location === "edit-scheme") {
      if (inputValue?.scheme_name !== "") {
        let param;

        param = {
          transmit: "broadcast",
          url: "update_scheme",
          request: {
            avatar: AvatarImageId === "" ? null : AvatarImageId,
            language: SelectedLanguage,
            name: inputValue?.scheme_name,
            start_date: DateSelect
              ? moment(
                  DateSelect,
                  "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)"
                ).format("YYYY-MM-DDTHH:mm:ss[Z]")
              : null,
            end_date: endDateSelect
              ? moment(
                  endDateSelect,
                  "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)"
                ).format("YYYY-MM-DDTHH:mm:ss[Z]")
              : null,
            category: inputValue?.category,
            eligibility: inputValue?.eligibility,
            benefits: inputValue?.benefits,
            description: inputValue?.description,
            created_at: inputValue?.created_at,
            updated_at: inputValue?.updated_at,
            lang_parent: inputValue?.lang_parent,
            category: Category,
            provider: selectedSchemeProviders.join(","),
            lang_parent: languageParent ? languageParent : null,
            documents: selectedDocumentIds.join(","),
            scheme_id: params?.roomId,
          },
        };

        console.log("param", param);

        wsSend_request(websocket, param);
        Navigate(SCHEME_MANAGEMENT_URL);
      }
    } else {
      if (inputValue?.scheme_name !== "") {
        let paramRequest;

        paramRequest = {
          avatar: AvatarImageId === "" ? null : AvatarImageId,
          language: SelectedLanguage,
          name: inputValue?.scheme_name,
          start_date: endDateSelect
            ? moment(DateSelect, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)").format(
                "YYYY-MM-DDTHH:mm:ss[Z]"
              )
            : null,
          end_date: endDateSelect
            ? moment(
                endDateSelect,
                "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)"
              ).format("YYYY-MM-DDTHH:mm:ss[Z]")
            : null,
          category: inputValue?.category,
          eligibility: inputValue?.eligibility,
          benefits: inputValue?.benefits,
          description: inputValue?.description,
          created_at: inputValue?.created_at,
          updated_at: inputValue?.updated_at,
          lang_parent: languageParent ? languageParent : null,
          category: Category,
          provider: selectedSchemeProviders.join(","),
          lang_parent: languageParent,
          documents: selectedDocumentIds.join(","),
        };

        const paramfilter = Object.entries(paramRequest).filter((elm) => {
          if (elm[1] !== "") {
            return elm;
          }
        });
        let param = {
          transmit: "broadcast",
          url: "add_scheme",
          request: Object.fromEntries(paramfilter),
        };
        console.log("param", param);
        wsSend_request(websocket, param);
        Navigate(SCHEME_MANAGEMENT_URL);
      } else {
        CheckValid(inputValue?.benefits, {
          type: "benefits",
          error: seterrorBenefits,
        });
        CheckValid(inputValue?.scheme_name, {
          type: "scheme_name",
          error: seterrorSchemeName,
        });

        CheckValid(inputValue?.eligibility, {
          type: "eligibility",
          error: seterrorEligibility,
        });
      }
    }
  };

  useEffect(() => {
    if (ClearFormSet.url === "update_scheme" && ClearFormSet.action === true) {
      Navigate(SCHEME_MANAGEMENT_URL);
      ClearForm();
    } else if (
      ClearFormSet.url === "add_scheme" &&
      ClearFormSet.action === true
    ) {
      Navigate(SCHEME_MANAGEMENT_URL);
      ClearForm();
    }
  }, [ClearFormSet]);

  document.addEventListener("wheel", function (event) {
    if (
      document.activeElement.type === "number" &&
      document.activeElement.classList.contains("inputBoxs")
    ) {
      document.activeElement.blur();
    }
  });

  // clear form
  const ClearForm = () => {
    setInputValue({
      ...inputValue,
      language: "",
      scheme_name: "",
      avatar: "",
      start_date: "",
      end_date: "",
      category: "",
      eligibility: "",
      benefits: "",
      description: "",
      created_at: "",
      updated_at: "",
      lang_parent: "",
    });
    setAvatarImage("");
    setAvatarImageId("");
  };
  const filteredSchemesList = schemeList?.list?.filter(
    (item) => item?.is_active && item?.language !== SelectedLanguage
  );
  console.log("documentList",documentList)
  const filteredDocumentList = documentList?.list?.filter(
    (item) => item?.is_active && item?.language === SelectedLanguage
  );
  const filteredCategoryList = categoryAllList?.filter(
    (item) => item?.is_active && item?.language === SelectedLanguage
  );
  const filteredSchemeProviderList = schemeProviderAllList?.filter(
    (item) => item?.is_active && item?.language === SelectedLanguage
  );

  const selectSchemeProviderRef = useRef();

  useEffect(() => {
    const $select = $(selectSchemeProviderRef.current);
    $select.select2();

    // Event handler for changes in the selection
    $select.on("change", function (e) {
      const selectedValues = $(this).val() || [];
      setSelectedSchemeProviders(selectedValues);
    });

    return () => {
      $select.select2("destroy");
      $select.off("change");
    };
  }, []);
  useEffect(() => {
  const $select = $(selectRef.current);
  $select.select2();

  // Event handler for changes in the selection
  $select.on("change", function (e) {
    const selectedOptions = $(this).val() || [];
    setSelectedDocumentIds(selectedOptions);
  });

  // Set initially selected options
  const selectedDocumentIdsArray = selectedDocumentIds.map((document) => document.id);
  $select.select2("val", selectedDocumentIdsArray);

  return () => {
    $select.select2("destroy");
    $select.off("change");
  };
}, []);

  return (
    <React.Fragment>
      <div
        className="header_back header_back_with_btn"
        style={{
          justifyContent: Location !== "edit-scheme" ? "end" : "end",
        }}
      >
        <BackButton targeturl={SCHEME_MANAGEMENT_URL} title={"Back"} />
      </div>
      <section className="dashboard_wrapper_form">
        <div className="scrollform">
          <Scrollbars
            style={{ height: "calc(100vh - 150px)" }}
            className="ScrollbarsWrapper"
            renderView={(props) => <div {...props} className="view" />}
          >
            <div className="scrollformCustom">
              <div className="row" style={{ margin: "0 auto" }}>
                <div className="col-md-4">
                  <div className="form-group fileUploadingform">
                    <label>Scheme Avatar</label>
                    {AvatarImage === "" || AvatarImage === null ? (
                      <>
                        <input
                          type="file"
                          className="form-control"
                          onChange={(e) => UploadImage(e)}
                          disabled={UploadFileLoader}
                          accept=".png, .jpg, .jpeg"
                        />
                        {UploadFileLoader && (
                          <div className="filUploading">
                            <Spinner animation="border" role="status">
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </Spinner>
                            Please wait, your file is uploading.
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="uploadedImagewrps">
                        <AvatarGet type="product" url={AvatarImage} />
                        <div
                          className="closeIocn"
                          onClick={() => RemoveUploadImage()}
                        >
                          <i className="bi bi-x-circle-fill"></i>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      Select Language{" "}
                      <span className="text-danger fs-5 position-absolute pl-1">
                        *
                      </span>
                    </label>
                    <select
                      className="form-control"
                      value={SelectedLanguage}
                      required
                      onChange={(e) => setSelectedLanguage(e.target.value)}
                    >
                      <option value="all" selected>
                        select{" "}
                      </option>
                      {languageList?.map((elm, index) => {
                        return (
                          <option key={index} value={elm}>
                            {elm}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="col-md-4">
                  <InputField
                    type="text"
                    value={inputValue?.scheme_name}
                    className={
                      errorSchemeName !== ""
                        ? "form-control error-form"
                        : "form-control"
                    }
                    placeholder="Enter Scheme Name"
                    label="Scheme Name <span class='redmednstar'>*</span>"
                    name="scheme_name"
                    onChange={handleChange}
                    onKeyUp={(e) =>
                      CheckValid(e.target.value, {
                        type: "scheme_name",
                        error: seterrorSchemeName,
                      })
                    }
                    onKeyDown={EmptySpaceFieldValid}
                    errormessage={errorSchemeName}
                  />
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Start Date</label>
                    <div className="formInput">
                      <DatePicker
                        selected={DateSelect}
                        onChange={(date) => setDateSelect(date)}
                        dateFormat="dd/MM/yyyy"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="Enter Start Date"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>End Date</label>
                    <div className="formInput">
                      <DatePicker
                        selected={endDateSelect}
                        onChange={(date) => setEndDateSelect(date)}
                        dateFormat="dd/MM/yyyy"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="Enter End Date"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      Category{" "}
                      <span className="text-danger fs-5 position-absolute pl-1">
                        *
                      </span>
                    </label>
                    <div className="filedaddwraps">
                      <select
                        required
                        className="form-control"
                        value={Category}
                        onChange={(e) => SelectOnnchangeCategory(e)}
                      >
                        <option value="all" selected>
                          Select Category
                        </option>
                        {filteredCategoryList?.map((elm, index) => {
                          if (elm?.is_active === true) {
                            return (
                              <option value={elm?.id} key={index}>
                                {elm?.name}
                              </option>
                            );
                          }
                        })}
                      </select>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip
                            id="button-tooltip-2"
                            style={{ position: "fixed" }}
                          >
                            Add Category
                          </Tooltip>
                        }
                      >
                        <button
                          type="button"
                          className="btn addbtn"
                          onClick={(e) => AddCategoryFnct(e)}
                        >
                          <i className="bi bi-plus"></i>
                        </button>
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      {" "}
                      Select Document{" "}
                      <span className="text-danger fs-5 position-absolute pl-1">
                        *
                      </span>
                    </label>
                    <div className="filedaddwraps">
                      <select
                        required
                        ref={selectRef}
                        className="form-control"
                        multiple={true}
                        value={selectedDocumentIds}
                      >
                        {filteredDocumentList
                          .filter((elm) => elm.is_active === true)
                          .map((elm) => (
                            <option value={elm.id} key={elm.id}>
                              {elm.name}
                            </option>
                          ))}
                      </select>

                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip
                            id="button-tooltip-2"
                            style={{ position: "fixed" }}
                          >
                            Add Document
                          </Tooltip>
                        }
                      >
                        <button
                          type="button"
                          className="btn addbtn"
                          onClick={() => Navigate(DOCUMENT_MANAGEMENT_URL)}
                        >
                          <i className="bi bi-plus"></i>
                        </button>
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      {" "}
                      Select Scheme Provider{" "}
                      <span className="text-danger fs-5 position-absolute pl-1">
                        *
                      </span>
                    </label>
                    <div className="filedaddwraps">
                      <select
                        required
                        ref={selectSchemeProviderRef}
                        className="form-control activestatusselet"
                        multiple={true}
                        value={selectedSchemeProviders}
                        style={{ width: "90%" }}
                      >
                        {filteredSchemeProviderList.map((provider) => (
                          <option value={provider.id} key={provider.id}>
                            {provider.name}
                          </option>
                        ))}
                      </select>

                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip
                            id="button-tooltip-2"
                            style={{ position: "fixed" }}
                          >
                            Add Provider
                          </Tooltip>
                        }
                      >
                        <button
                          type="button"
                          className="btn addbtn"
                          onClick={(e) => AddProviderFnct(e)}
                        >
                          <i className="bi bi-plus"></i>
                        </button>
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <label className="fs-6"> Select Parent Language</label>

                  <select
                    className="form-control"
                    value={languageParent}
                    onChange={(e) => {
                      const selectedId = e.target.value;
                      setlanguageParent(selectedId);
                    }}
                  >
                    <option value="all">Select Parent Language</option>

                    {filteredSchemesList &&
                      filteredSchemesList?.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="toolbarClassName" className="mb-2 fs-6">
                    Eligibility{" "}
                    <span className="text-danger fs-5 position-absolute pl-1">
                      *
                    </span>
                  </label>
                  <Editor
                    editorState={eligibilityEditorState}
                    onEditorStateChange={onEligibilityEditorChange}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    wrapperStyle={{
                      width: 400,
                      border: "1px solid #ccc",
                      backgroundColor: "white",
                      padding: "10px",
                      borderRadius: "4px",
                    }}
                    editorStyle={{
                      minHeight: "150px",
                      border: "1px solid #ccc",
                      padding: "10px",
                      borderRadius: "4px",
                    }}
                    toolbar={{
                      options: [
                        "inline",
                        "blockType",
                        "fontSize",
                        "fontFamily",
                        "list",
                        "textAlign",
                        "colorPicker",
                        "link",
                        "emoji",
                        "remove",
                        "history",
                      ],
                      inline: {
                        inDropdown: false,
                        options: [
                          "bold",
                          "italic",
                          "underline",
                          "strikethrough",
                          "superscript",
                          "subscript",
                        ],
                      },
                      list: {
                        inDropdown: false,
                        options: ["unordered", "ordered"],
                      },
                      textAlign: {
                        inDropdown: true,
                      },
                      link: { inDropdown: false },
                      history: { inDropdown: false },
                    }}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="toolbarClassName" className="mb-2 fs-6">
                    Benefits{" "}
                    <span className="text-danger fs-5 position-absolute pl-1">
                      *
                    </span>
                  </label>
                  <Editor
                    editorState={benefitsEditorState}
                    onEditorStateChange={onBenefitsEditorChange}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    wrapperStyle={{
                      width: 400,
                      border: "1px solid #ccc",
                      backgroundColor: "white",
                      padding: "10px",
                      borderRadius: "4px",
                    }}
                    editorStyle={{
                      minHeight: "150px",
                      border: "1px solid #ccc",
                      padding: "10px",
                      borderRadius: "4px",
                    }}
                    toolbar={{
                      options: [
                        "inline",
                        "blockType",
                        "fontSize",
                        "fontFamily",
                        "list",
                        "textAlign",
                        "colorPicker",
                        "link",
                        "emoji",
                        "remove",
                        "history",
                      ],
                      inline: {
                        inDropdown: false,
                        options: [
                          "bold",
                          "italic",
                          "underline",
                          "strikethrough",
                          "superscript",
                          "subscript",
                        ],
                      },
                      list: {
                        inDropdown: false,
                        options: ["unordered", "ordered"],
                      },
                      textAlign: {
                        inDropdown: true,
                      },
                      link: { inDropdown: false },
                      history: { inDropdown: false },
                    }}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="toolbarClassName" className="mb-2 fs-6">
                    Scheme Description{" "}
                    <span className="text-danger fs-5 position-absolute pl-1">
                      *
                    </span>
                  </label>
                  <Editor
                    editorState={editorState}
                    onEditorStateChange={onEditorChange}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    wrapperStyle={{
                      width: 400,
                      border: "1px solid #ccc",
                      backgroundColor: "white",
                      padding: "10px",
                      borderRadius: "4px",
                    }}
                    editorStyle={{
                      minHeight: "150px",
                      border: "1px solid #ccc",
                      padding: "10px",
                      borderRadius: "4px",
                    }}
                    toolbar={{
                      options: [
                        "inline",
                        "blockType",
                        "fontSize",
                        "fontFamily",
                        "list",
                        "textAlign",
                        "colorPicker",
                        "link",
                        "emoji",
                        "remove",
                        "history",
                      ],
                      inline: {
                        inDropdown: false,
                        options: [
                          "bold",
                          "italic",
                          "underline",
                          "strikethrough",
                          "superscript",
                          "subscript",
                        ],
                      },
                      list: {
                        inDropdown: false,
                        options: ["unordered", "ordered"],
                      },
                      textAlign: {
                        inDropdown: true,
                      },
                      link: { inDropdown: false },
                      history: { inDropdown: false },
                    }}
                  />
                </div>
                <div className="col-md-12">
                  <div
                    className="form-group-btn"
                    style={{ textAlign: "right" }}
                  >
                    <button
                      onClick={() => CreateProduct()}
                      className="btn addbtncmn"
                    >
                      {Location === "edit-scheme" ? "Save" : "Create"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Scrollbars>
        </div>
        {/* loader start here */}
        {loaderMain && <CommonLoader />}
      </section>
      {showModal?.open && (
        <CommonModel
          fromUrl={"product"}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </React.Fragment>
  );
};

export default AddEditScheme;
