import React from "react";
import logo from "../../assets/img/schemebharat.png";
const PrivacyPolicy = () => {
  return (
    <div style={{ padding: "20px", height: "100%", overflowY: "auto" }}>
      {" "}
      <section>
        <div className="row">
          <div className="col-md-12">
          <img src={logo} alt="Logo" style={{ maxWidth: "5rem" }} />
            <h4>Scheme Bharat</h4>
          </div>
        </div>
        <h1>Privacy Policy</h1>
        <div className="mb-2">
          <div className="page" title="Page 2">
            <div className="section">
              <div className="layoutArea">
                <p>
                  Thank you for using our application. This Privacy Policy is
                  here to inform you about how we treat sensitive user and
                  device data, whether our app contains ads, how to access
                  restricted parts of our app, details about our app's target
                  audience and content, our usage of high-risk or sensitive
                  permissions, how we receive content ratings, and our privacy
                  and security practices.
                </p>
                <div className="column">
                  <strong>Introduction</strong>
                  <p>
                    We are committed to safeguarding the privacy of our
                    Application users. This Privacy Policy sets out how we will
                    treat your personal information.
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    <strong>Data Collection</strong>
                    <br />
                    We may collect, store, and use the following kinds of
                    personal data:
                    <br />
                    Information about your device and about your visits to and
                    use of this Application. Information that you provide to us
                    for the purpose of registering warranties.
                    <br />
                    Any other information that you choose to send to us.
                  </p>
                  <p>
                    <strong>Using Your Personal Data</strong>
                    <br />
                    Personal data submitted on this Application will be used for
                    the purposes specified in this Privacy Policy or in relevant
                    parts of the Application.
                    <br />
                    We may use your personal information to:
                    <br />
                    Administer and manage warranties.
                    <br />
                    Send you email notifications related to the Application.
                    <br />
                    Handle inquiries and complaints made by or about you
                    relating to the Application.
                  </p>
                  <p>
                    <strong>Data Protection</strong>
                    <br />
                    We will take reasonable technical and organisational
                    precautions to prevent the loss, misuse, or alteration of
                    your personal information.
                    <br />
                    All personal data submitted through this Application will be
                    processed in accordance with relevant data protection
                    regulations, including but not limited to the General Data
                    Protection Regulation (GDPR).
                  </p>
                  <p>
                    <strong>Data Retention</strong>
                    <br />
                    We will retain your personal data only for as long as
                    necessary to fulfil the purposes for which it was collected
                    or to comply with legal, regulatory, or internal policy
                    requirements.
                  </p>
                  <p>
                    <strong>Your Rights</strong>
                    <br />
                    You have the right to:
                    <br />
                    Access the personal data we hold about you.
                    <br />
                    Request correction of any inaccurate personal data we hold
                    about you.
                    <br />
                    Request deletion of your personal data.
                    <br />
                    Object to the processing of your personal data.
                    <br />
                    Request data portability.
                    <br />
                    To exercise any of these rights, please contact us using the
                    provided contact details.
                  </p>
                  <p>
                    <strong>
                      Treatment of Sensitive User and Device Data:
                    </strong>
                    <br />
                    We prioritise the protection of your sensitive data. Any
                    information collected by our application is used solely for
                    the purpose of providing you with the best possible user
                    experience and is not shared with third parties without your
                    explicit consent unless required by law.
                    <br />
                  </p>
                  <p>
                    <strong>Ads:</strong> <br />
                    Our application does not have ads.
                  </p>
                  <p>
                    <strong>Access to Restricted Parts of the App:</strong>{" "}
                    <br />
                    To access restricted parts of our app, users may need to
                    create an account.
                  </p>
                  <p>
                    <strong>Target Audience and Content:</strong> <br />
                    Our app is intended for [Specify your app's target audience,
                    e.g., general users, children, professionals, etc.]. The
                    content provided within the app is curated to cater to the
                    interests and needs of our target audience.
                  </p>
                  <p>
                    <strong>
                      Usage of High-Risk or Sensitive Permissions:{" "}
                    </strong>{" "}
                    <br />
                    Our application does not request high-risk or sensitive
                    permissions such as SMS/Call Log permissions for any
                    specific features.
                  </p>
                  <p>
                    <p>
                      <strong>Content Ratings: </strong>
                      <br />
                      We receive content ratings from official rating
                      authorities to ensure that our app is appropriately
                      categorized and accessible to the intended audience.
                    </p>
                    <p>
                      <strong>Privacy and Security Practices: </strong> <br />{" "}
                      We take privacy and security seriously and implement
                      industry-standard measures to safeguard your data. This
                      includes encryption of sensitive information, regular
                      security audits, and adherence to best practices for data
                      protection.
                    </p>
                    <p></p>
                    <strong>Third Parties</strong>
                    <br />
                    We will not sell, distribute, or lease your personal
                    information to third parties unless we have your permission
                    or are required by law to do so.
                    <br />
                    However, we may disclose your personal data to any member of
                    our group of companies (subsidiaries, our ultimate holding
                    company, and all its subsidiaries) as reasonably necessary
                    for the purposes set out in this policy.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="page" title="Page 3">
        <div className="section">
          <div className="layoutArea">
            <div className="column">
              <p>
                <strong>Cross-border Data Transfers</strong>
                <br />
                Personal data that you submit for publication through the
                Application may be available, via the internet, worldwide. We
                cannot prevent the use or misuse of such information by others.
              </p>
              <p>
                <strong>Updates to This Policy</strong>
                <br />
                We may update this privacy policy from time to time. Any changes
                will be posted on this page, and you are advised to check this
                page regularly.
              </p>
              <p>
                <strong>Contact</strong>
                <br />
                If you have any questions about this privacy policy or our
                treatment of your personal data, please contact us at our
                provided contact details.
              </p>
            </div>
            This Privacy Policy is subject to change, and any updates will be
            reflected on this page. By continuing to use our application, you
            acknowledge and agree to the terms outlined in this Privacy Policy.
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
