export default async function PostRequestCall(url, payload) {
    console.log("url, payload",url, payload)
  try {
    const response = await window.axios.post(url, payload, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    // Check if the request was successful (status code 2xx)
    if (response.status >= 200 && response.status < 300) {
      // Parse the JSON response
      return response.data;
    } else {
      throw new Error(`Request failed with status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error making POST request:", error.message);
    throw error; // Propagate the error to the caller
  }
}


