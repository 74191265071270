import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  editSelfDetails,
  getPermissionList,
  deleteSettingList,
  addCategoryList,
  addSchemeProviderList,
//   getSettingList,
  addSettingList,
  updateSettingList,
  ClearFormSetFutios,
  editUserList,
  getAllCategoryList,
  getSelfDetails,
  deleteSchemeProviderList,
  deleteCategoryList,
  updateSchemeProviderList,
  updateCategoryList,
  getAllDocumenttList,
  getAllProductList,
  UpdateProductList,
  UpdateDocumentList,
  deleteProduct,
  deleteDocument,
  LoaderStartMain,
  addProductList,
  addDocumentList,
  getSupplierDetails,
  getDocumentList,
  getProductList,
  ViewProjectDetails,
  getCategoryList,
  getSchemeProviderList,
  getAllSchemeProviderList,
  getAllFAQList,
  getFAQList,
  UpdateFAQList,
  addFAQList,
  deleteFAQ,
} from "../../redux/actions/adminActions";

export const ws_response = ({ evt, ws }) => {
  return async (dispatch, getState) => {
    const ws_onmessage = JSON.parse(evt.data);
    const { device_id, user_id } = getState()?.adminReducers;
    console.log("ws_onmessage?.response", ws_onmessage, evt);

    // Sw notification setup
    switch (ws_onmessage.url) {
      case "admin_profile_update":
        if (ws_onmessage?.response?.status === true) {
          dispatch(editSelfDetails(ws_onmessage?.response?.data));
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "admin_profile_update",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.success(ws_onmessage?.response?.msg);
          }
        } else {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "admin_profile_update",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.error(ws_onmessage?.response?.msg);
          }
        }
        break;
        case "change_password":
        if (ws_onmessage?.response?.status === true) {
          dispatch(editSelfDetails(ws_onmessage?.response?.data));
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "change_password",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.success(ws_onmessage?.response?.msg);
          }
        } else {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "change_password",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.error(ws_onmessage?.response?.msg);
          }
        }
        break;
      case "permission_list":
        dispatch(getPermissionList(ws_onmessage?.response));
        break;
      case "update_user":
        if (ws_onmessage?.response?.status === true) {
          dispatch(editUserList(ws_onmessage?.response?.data));
          if (ws_onmessage?.response?.data?.id === user_id) {
            dispatch(getSelfDetails(ws_onmessage?.response?.data));
          }
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "update_user",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.success(ws_onmessage?.response?.msg);
          }
        } else {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "update_user",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.error(ws_onmessage?.response?.msg);
          }
        }
        break;
    
      case "scheme_list":
        if (ws_onmessage?.request?.DeviceId === device_id) {
          dispatch(getAllProductList(ws_onmessage?.response));
          dispatch(getProductList(ws_onmessage?.response));
        }
        break;
      case "document_list":
        if (ws_onmessage?.request?.DeviceId === device_id) {
          dispatch(getAllDocumenttList(ws_onmessage?.response));
          dispatch(getDocumentList(ws_onmessage?.response));
        }
        break;
        case "faq_list":
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(getAllFAQList(ws_onmessage?.response));
            dispatch(getFAQList(ws_onmessage?.response));
          }
          break;
      case "add_scheme":
        if (ws_onmessage?.response?.status) {
          dispatch(addProductList(ws_onmessage?.response));
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "add_scheme",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.success(ws_onmessage?.response?.msg);
          }
        } else {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "add_scheme",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.error(ws_onmessage?.response?.msg);
          }
        }
        break;
        case "add_document":
          if (ws_onmessage?.response?.status) {
            dispatch(addDocumentList(ws_onmessage?.response));
            if (ws_onmessage?.request?.DeviceId === device_id) {
              dispatch(
                ClearFormSetFutios({
                  url: "add_document",
                  action: ws_onmessage?.response?.status,
                })
              );
              toast.success(ws_onmessage?.response?.msg);
            }
          } else {
            if (ws_onmessage?.request?.DeviceId === device_id) {
              dispatch(
                ClearFormSetFutios({
                  url: "add_document",
                  action: ws_onmessage?.response?.status,
                })
              );
              toast.error(ws_onmessage?.response?.msg);
            }
          }
          break;
          case "add_faq":
          if (ws_onmessage?.response?.status) {
            dispatch(addFAQList(ws_onmessage?.response));
            if (ws_onmessage?.request?.DeviceId === device_id) {
              dispatch(
                ClearFormSetFutios({
                  url: "add_faq",
                  action: ws_onmessage?.response?.status,
                })
              );
              toast.success(ws_onmessage?.response?.msg);
            }
          } else {
            if (ws_onmessage?.request?.DeviceId === device_id) {
              dispatch(
                ClearFormSetFutios({
                  url: "add_faq",
                  action: ws_onmessage?.response?.status,
                })
              );
              toast.error(ws_onmessage?.response?.msg);
            }
          }
          break;
      case "update_scheme":
        if (ws_onmessage?.response?.status) {
          dispatch(UpdateProductList(ws_onmessage?.response?.data));
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "update_scheme",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.success(ws_onmessage?.response?.msg || "Scheme Updated Successfully");
            console.log("ws_onmessage?.response?.msg",ws_onmessage?.response?.msg)
          }
        } else {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "update_scheme",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.error(ws_onmessage?.response?.msg);
          }
        }
        break;
        case "update_document":
          if (ws_onmessage?.response?.status) {
            dispatch(UpdateDocumentList(ws_onmessage?.response?.data));
            if (ws_onmessage?.request?.DeviceId === device_id) {
              dispatch(
                ClearFormSetFutios({
                  url: "update_document",
                  action: ws_onmessage?.response?.status,
                })
              );
              toast.success(ws_onmessage?.response?.msg);
            }
          } else {
            if (ws_onmessage?.request?.DeviceId === device_id) {
              dispatch(
                ClearFormSetFutios({
                  url: "update_document",
                  action: ws_onmessage?.response?.status,
                })
              );
              toast.error(ws_onmessage?.response?.msg);
            }
          }
          break;
         
          case "update_faq":
            if (ws_onmessage?.response?.status) {
              dispatch(UpdateFAQList(ws_onmessage?.response?.data));
              if (ws_onmessage?.request?.DeviceId === device_id) {
                dispatch(
                  ClearFormSetFutios({
                    url: "update_faq",
                    action: ws_onmessage?.response?.status,
                  })
                );
                toast.success(ws_onmessage?.response?.msg);
              }
            } else {
              if (ws_onmessage?.request?.DeviceId === device_id) {
                dispatch(
                  ClearFormSetFutios({
                    url: "update_faq",
                    action: ws_onmessage?.response?.status,
                  })
                );
                toast.error(ws_onmessage?.response?.msg);
              }
            }
            break;
      case "get_scheme_detail":
        if (ws_onmessage?.response?.status) {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(LoaderStartMain(true));
            dispatch(ViewProjectDetails(ws_onmessage?.response?.data));
            setTimeout(() => {
              dispatch(LoaderStartMain(false));
            }, 3000);
          }
        } else {
          dispatch(getSupplierDetails({}));
        }
        break;
        case "get_document_detail":
        if (ws_onmessage?.response?.status) {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(LoaderStartMain(true));
            dispatch(ViewProjectDetails(ws_onmessage?.response?.data));
            setTimeout(() => {
              dispatch(LoaderStartMain(false));
            }, 3000);
          }
        } else {
          dispatch(getSupplierDetails({}));
        }
        break;
        case "faq_detail":
          if (ws_onmessage?.response?.status) {
            if (ws_onmessage?.request?.DeviceId === device_id) {
              dispatch(LoaderStartMain(true));
              dispatch(ViewProjectDetails(ws_onmessage?.response?.data));
              setTimeout(() => {
                dispatch(LoaderStartMain(false));
              }, 3000);
            }
          } else {
            dispatch(getSupplierDetails({}));
          }
          break;
      case "delete_scheme":
        if (ws_onmessage?.response?.status) {
          dispatch(deleteProduct(ws_onmessage?.response?.data));
          if (ws_onmessage?.request?.DeviceId === device_id) {
            toast.success(ws_onmessage?.response?.msg);
          }
        } else {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            toast.error(ws_onmessage?.response?.msg);
          }
        }
        break;  
        case "delete_document":
        if (ws_onmessage?.response?.status) {
          dispatch(deleteDocument(ws_onmessage?.response?.data));
          if (ws_onmessage?.request?.DeviceId === device_id) {
            toast.success(ws_onmessage?.response?.msg || "Document deleted successfully");
          }
        } else {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            toast.error(ws_onmessage?.response?.msg);
          }
        }
        break; 
        case "delete_faq":
          if (ws_onmessage?.response?.status) {
            dispatch(deleteFAQ(ws_onmessage?.response?.data));
            if (ws_onmessage?.request?.DeviceId === device_id) {
              toast.success(ws_onmessage?.response?.msg || "FAQ deleted successfully");
            }
          } else {
            if (ws_onmessage?.request?.DeviceId === device_id) {
              toast.error(ws_onmessage?.response?.msg);
            }
          }
          break; 
      case "scheme_category_list":
        dispatch(getAllCategoryList(ws_onmessage?.response));
        dispatch(getCategoryList(ws_onmessage?.response));
        break;
      case "scheme_provider_list":
        dispatch(getAllSchemeProviderList(ws_onmessage?.response));
        dispatch(getSchemeProviderList(ws_onmessage?.response));
        break;
      case "add_scheme_category":
        if (ws_onmessage?.response?.status === true) {
          dispatch(addCategoryList(ws_onmessage?.response?.data));
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "add_scheme_category",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.success(ws_onmessage?.response?.msg);
          }
        } else {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "add_scheme_category",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.error(ws_onmessage?.response?.msg);
          }
        }
        break;
        case "add_scheme_provider":
          if (ws_onmessage?.response?.status === true) {
            dispatch(addSchemeProviderList(ws_onmessage?.response?.data));
            if (ws_onmessage?.request?.DeviceId === device_id) {
              dispatch(
                ClearFormSetFutios({
                  url: "add_scheme_provider",
                  action: ws_onmessage?.response?.status,
                })
              );
              toast.success(ws_onmessage?.response?.msg);
            }
          } else {
            if (ws_onmessage?.request?.DeviceId === device_id) {
              dispatch(
                ClearFormSetFutios({
                  url: "add_scheme_provider",
                  action: ws_onmessage?.response?.status,
                })
              );
              toast.error(ws_onmessage?.response?.msg);
            }
          }
          break;
      case "update_scheme_category":
        if (ws_onmessage?.response?.status === true) {
          dispatch(updateCategoryList(ws_onmessage?.response?.data));
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "update_scheme_category",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.success(ws_onmessage?.response?.msg);
          }
        } else {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "update_scheme_category",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.error(ws_onmessage?.response?.msg);
          }
        }
        break;
      case "update_scheme_provider":
        if (ws_onmessage?.response?.status === true) {
          dispatch(updateSchemeProviderList(ws_onmessage?.response?.data));
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "update_scheme_provider",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.success(ws_onmessage?.response?.msg);
          }
        } else {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "update_scheme_provider",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.error(ws_onmessage?.response?.msg);
          }
        }
        break;
      case "delete_scheme_category":
        if (ws_onmessage?.response?.status === true) {
          dispatch(deleteCategoryList(ws_onmessage?.response?.data));
          if (ws_onmessage?.request?.DeviceId === device_id) {
            toast.success(ws_onmessage?.response?.msg);
          }
        } else {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            toast.error(ws_onmessage?.response?.msg);
          }
        }
        break;
      case "delete_scheme_provider":
        if (ws_onmessage?.response?.status === true) {
          dispatch(deleteSchemeProviderList(ws_onmessage?.response?.data));
          if (ws_onmessage?.request?.DeviceId === device_id) {
            toast.success(ws_onmessage?.response?.msg);
          }
        } else {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            toast.error(ws_onmessage?.response?.msg);
          }
        }
        break;
    //   case "scheme_provider_list":
    //     if (ws_onmessage?.response?.status === true) {
    //       dispatch(getSettingList(ws_onmessage?.response));
    //     }
    //     break;
      case "add_scheme_setting":
        if (ws_onmessage?.response?.status) {
          dispatch(addSettingList(ws_onmessage?.response?.data));
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "add_scheme_setting",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.success(ws_onmessage?.response?.msg);
          }
        } else {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "add_scheme_setting",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.error(ws_onmessage?.response?.msg);
          }
        }
        break;
      case "update_scheme_setting":
        if (ws_onmessage?.response?.status === true) {
          dispatch(updateSettingList(ws_onmessage?.response?.data));
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "update_scheme_setting",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.success(ws_onmessage?.response?.msg);
          }
        } else {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            dispatch(
              ClearFormSetFutios({
                url: "update_scheme_setting",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.error(ws_onmessage?.response?.msg);
          }
        }
        break;
      case "delete_scheme_setting":
        if (ws_onmessage?.response?.status === true) {
          dispatch(deleteSettingList(ws_onmessage?.response?.data));
          if (ws_onmessage?.request?.DeviceId === device_id) {
            toast.success(ws_onmessage?.response?.msg);
          }
        } else {
          if (ws_onmessage?.request?.DeviceId === device_id) {
            toast.error(ws_onmessage?.response?.msg);
          }
        }
        break;
      default:
        return;
    }
  };
};
