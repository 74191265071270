/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { DOCUMENT_MANAGEMENT_URL } from "../../Shared/constant";
import BackButton from "../../Common/BackButton";
import InputField from "../../Common/InputField";
import CheckValid from "../../Common/CheckValid";
import EmptySpaceFieldValid from "../../Common/EmptySpaceFieldValid";
import { Spinner } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useSelector, useDispatch } from "react-redux";
import wsSend_request from "../../../Api/ws/ws_request";
import { WebSocketContext } from "../../../App";
import UploadFileAPI from "../../../Api/UploadFileAPI";
import CommonModel from "../../Common/CommonModel";
import AvatarGet from "../../Common/AvatarGet";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import CommonLoader from "../../Common/CommonLoader";
import "react-toastify/dist/ReactToastify.css";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
const AddEditDocument = () => {
  const Navigate = useNavigate();
  const params = useParams();
  const Location = useLocation()?.pathname?.split("/")[1];
  const { websocket } = useContext(WebSocketContext);
  const [inputValue, setInputValue] = useState({
    language: "",
    name: "",
    avatar: "",
    description: "",
    lang_parent: "",
    document_id: "",
  });
  const {
    language,
    name,
    avatar,
    description,
    lang_parent,
    document_id,
  } = inputValue;

  const [SelectedLanguage, setSelectedLanguage] = useState("");
  const [languageParent, setlanguageParent] = useState("");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const {
    viewProduct,
    documentList,
    accessToken,
    device_id,
    loaderMain,
    ClearFormSet,
    languageList,
  } = useSelector((state) => state.adminReducers);
  const [UploadFileLoader, setUploadFileLoader] = useState(false);
  const [AvatarImage, setAvatarImage] = useState("");
  const [AvatarImageId, setAvatarImageId] = useState("");
  const [showModal, setShowModal] = useState({
    open: false,
    title: "",
    modalType: "",
    Data: {},
  });

  // error field
  const [errorDocumentName, seterrorDocumentName] = useState("");
  const [errorEligibility, seterrorEligibility] = useState("");
  const [errorLanguage, seterrorLanguage] = useState("");
  const [errorDesciption, setErrorDesciption] = useState("");

  useEffect(() => {
    let param = {
      transmit: "broadcast",
      url: "document_list",
      request: {
        filter: "all",
        limit: 5000,
        page_no: 1,
        search: "",
        type: "admin",
      DeviceId: device_id,

      },
    };
    wsSend_request(websocket, param);
  }, []);
  // get product details
  useEffect(() => {
    if (Location === "edit-document") {
      if (params?.roomId) {
        let param = {
          transmit: "broadcast",
          url: "get_document_detail",
          request: {
            document_id: params?.roomId,
            DeviceId: device_id,
          },
        };
        wsSend_request(websocket, param);
      }
    }
  }, [params?.roomId, Location]);

  useEffect(() => {
    if (Location === "edit-document") {
      if (viewProduct) {
        setAvatarImage(
          viewProduct?.avatar === null ? "" : viewProduct?.view_thumbnail_url
        );
        // Convert the HTML content to ContentState
        let descriptionContentState = ContentState.createFromText(""); // Default to an empty state

        if (viewProduct?.description) {
          const blocksFromHTML = convertFromHTML(viewProduct.description);
          if (blocksFromHTML && blocksFromHTML.contentBlocks) {
            descriptionContentState = ContentState.createFromBlockArray(
              blocksFromHTML.contentBlocks,
              blocksFromHTML.entityMap
            );
          }
        }
        if(viewProduct?.lang_parent){
          const filteredSchemesList = documentList?.list?.find(
            (item) => item.id === viewProduct?.lang_parent
            );
            if(filteredSchemesList){
            setlanguageParent(filteredSchemesList?.id)
            }
            }
        if (viewProduct?.avatar) {
          setAvatarImageId(viewProduct.avatar);
        }
        // Create an EditorState with the ContentState
        const descriptionEditorState = EditorState.createWithContent(
          descriptionContentState
        );

        setEditorState(descriptionEditorState);
        // setEditorState(viewProduct?.description)
        setSelectedLanguage(viewProduct?.language);
        setInputValue({
          ...inputValue,
          avatar: viewProduct?.avatar,
          language: viewProduct?.language,
          name: viewProduct?.name,
          description: viewProduct?.description,
          lang_parent: viewProduct?.lang_parent,
          document_id: viewProduct?.document_id || params?.roomId,
        });
      }
    }
  }, [viewProduct]);
  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const onEditorChange = (newEditorState) => {
    setEditorState(newEditorState);
    const contentState = editorState.getCurrentContent();
    const contentAsRaw = convertToRaw(contentState);
    const htmlContent = draftToHtml(contentAsRaw);

    setInputValue({ ...inputValue, description: htmlContent });
  };

  // upload product image
  const UploadImage = async (e) => {
    setUploadFileLoader(true);
    const files = e.target.files[0];
    const formData = new FormData();
    formData.append("file", files, files.name);
    const fileresponse = await UploadFileAPI(accessToken, formData);
    if (fileresponse) {
      setUploadFileLoader(false);
      setAvatarImage(fileresponse?.view_thumbnail_url);
      setAvatarImageId(fileresponse?.id);
    }
  };

  // remove image uploaded
  const RemoveUploadImage = () => {
    setAvatarImage("");
    setAvatarImageId("");
  };
  const CreateDocument = () => {
    if (Location === "edit-document") {
      if (
        inputValue?.name !== "" ||
        SelectedLanguage !== "" ||
        inputValue?.description !== ""
      ) {
        let param;

        param = {
          transmit: "broadcast",
          url: "update_document",
          request: {
            avatar: AvatarImageId === "" ? null : AvatarImageId,
            language: SelectedLanguage,
            name: inputValue?.name,
            description: inputValue?.description,
            lang_parent: inputValue?.lang_parent,
            document_id: inputValue?.document_id || params?.roomId,
            lang_parent: languageParent,
          },
        };
        wsSend_request(websocket, param);
        Navigate(DOCUMENT_MANAGEMENT_URL);
      } else {
        CheckValid(SelectedLanguage, {
          type: "language",
          error: seterrorLanguage,
        });
        CheckValid(inputValue?.name, {
          type: "name",
          error: seterrorDocumentName,
        });
        CheckValid(inputValue?.description, {
          type: "description",
          error: setErrorDesciption,
        });
      }
    } else {
      if (
        inputValue?.name !== "" ||
        SelectedLanguage !== "" ||
        inputValue?.description !== ""
      ) {
        let paramRequest;

        paramRequest = {
          avatar: AvatarImageId === "" ? null : AvatarImageId,
          language: SelectedLanguage,
          name: inputValue?.name,
          avatar: AvatarImageId,
          description: inputValue?.description,
          lang_parent: inputValue?.lang_parent,
          document_id: inputValue?.document_id || params?.roomId,
          lang_parent: languageParent,
        };

        const paramfilter = Object.entries(paramRequest).filter((elm) => {
          if (elm[1] !== "") {
            return elm;
          }
        });
        let param = {
          transmit: "broadcast",
          url: "add_document",
          request: Object.fromEntries(paramfilter),
        };
        wsSend_request(websocket, param);
        Navigate(DOCUMENT_MANAGEMENT_URL);
      } else {
        CheckValid(SelectedLanguage, {
          type: "language",
          error: seterrorLanguage,
        });
        CheckValid(inputValue?.name, {
          type: "name",
          error: seterrorDocumentName,
        });
      }
    }
  };

  useEffect(() => {
    if (
      ClearFormSet.url === "update_document" &&
      ClearFormSet.action === true
    ) {
      Navigate(DOCUMENT_MANAGEMENT_URL);
      ClearForm();
    } else if (
      ClearFormSet.url === "add_document" &&
      ClearFormSet.action === true
    ) {
      Navigate(DOCUMENT_MANAGEMENT_URL);
      ClearForm();
    }
  }, [ClearFormSet]);

  // clear form
  const ClearForm = () => {
    setInputValue({
      ...inputValue,
      language: "",
      name: "",
      avatar: "",
      description: "",
      lang_parent: "",
    });
    setAvatarImage("");
    setAvatarImageId("");
  };
  const filteredDocumentList = documentList?.list?.filter(
    (item) => item?.is_active && item?.language !== SelectedLanguage
  );
  const handleAddLink = () => {
    const url = prompt("Enter the URL for the link:");
    if (url) {
      const contentState = editorState.getCurrentContent();
      const contentStateWithLink = contentState.createEntity(
        "LINK",
        "MUTABLE",
        { url }
      );
      const entityKey = contentStateWithLink.getLastCreatedEntityKey();
      const newEditorState = EditorState.set(editorState, {
        currentContent: contentStateWithLink,
      });
      setEditorState(
        EditorState.forceSelection(newEditorState, editorState.getSelection())
      );
    }
  };
  return (
    <React.Fragment>
      <div
        className="header_back header_back_with_btn"
        style={{
          justifyContent: Location !== "edit-product" ? "end" : "space-between",
        }}
      >
        <BackButton targeturl={DOCUMENT_MANAGEMENT_URL} title={"Back"} />
      </div>
      <section className="dashboard_wrapper_form">
        <div className="scrollform">
          <Scrollbars
            style={{ height: "calc(100vh - 150px)" }}
            className="ScrollbarsWrapper"
            renderView={(props) => <div {...props} className="view" />}
          >
            <div className="scrollformCustom">
              <div className="row" style={{ margin: "0 auto" }}>
                <div className="col-md-4">
                  <div className="form-group fileUploadingform">
                    <label>Document Avatar</label>
                    {AvatarImage === "" || AvatarImage === null ? (
                      <>
                        <input
                          type="file"
                          className="form-control"
                          onChange={(e) => UploadImage(e)}
                          disabled={UploadFileLoader}
                          accept=".png, .jpg, .jpeg"
                        />
                        {UploadFileLoader && (
                          <div className="filUploading">
                            <Spinner animation="border" role="status">
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </Spinner>
                            Please wait, your file is uploading.
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="uploadedImagewrps">
                        <AvatarGet type="product" url={AvatarImage} />
                        <div
                          className="closeIocn"
                          onClick={() => RemoveUploadImage()}
                        >
                          <i className="bi bi-x-circle-fill"></i>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      Select Language{" "}
                      <span className="text-danger fs-5 position-absolute pl-1">
                        *
                      </span>
                    </label>
                    <select
                      className="form-control"
                      value={SelectedLanguage}
                      onChange={(e) => setSelectedLanguage(e.target.value)}
                    >
                      <option value="all" selected>
                        select{" "}
                      </option>
                      {languageList?.map((elm, index) => {
                        return (
                          <option key={index} value={elm}>
                            {elm}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="col-md-4">
                  <InputField
                    type="text"
                    value={inputValue?.name}
                    className={
                      errorDocumentName !== ""
                        ? "form-control error-form"
                        : "form-control"
                    }
                    placeholder="Enter Document Name"
                    label="Document Name <span class='redmednstar'>*</span>"
                    name="name"
                    onChange={handleChange}
                    onKeyUp={(e) =>
                      CheckValid(e.target.value, {
                        type: "name",
                        error: seterrorDocumentName,
                      })
                    }
                    onKeyDown={EmptySpaceFieldValid}
                    errormessage={errorDocumentName}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="toolbarClassName" className="mb-2 fs-6">
                    Document Description{" "}
                    <span className="text-danger fs-5 position-absolute pl-1">
                      *
                    </span>
                  </label>
                  <Editor
                    editorState={editorState}
                    onEditorStateChange={onEditorChange}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    wrapperStyle={{
                      width: 400,
                      border: "1px solid #ccc",
                      backgroundColor: "white",
                      padding: "10px",
                      borderRadius: "4px",
                    }}
                    editorStyle={{
                      minHeight: "150px",
                      border: "1px solid #ccc",
                      padding: "10px",
                      borderRadius: "4px",
                    }}
                    toolbar={{
                      options: [
                        "inline",
                        "blockType",
                        "fontSize",
                        "fontFamily",
                        "list",
                        "textAlign",
                        "colorPicker",
                        "link",
                        "emoji",
                        "remove",
                        "history",
                      ],
                      inline: {
                        inDropdown: false,
                        options: [
                          "bold",
                          "italic",
                          "underline",
                          "strikethrough",
                          "superscript",
                          "subscript",
                        ],
                      },
                      list: {
                        inDropdown: false,
                        options: ["unordered", "ordered"],
                      },
                      textAlign: {
                        inDropdown: true,
                      },
                      link: { inDropdown: false, onClick: handleAddLink },
                      history: { inDropdown: false },
                    }}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="select-lang-parent" className="fs-6">
                    Select Parent Language
                  </label>
                  <select
                    className="form-control"
                    id="select-lang_parent"
                    value={languageParent}
                    onChange={(e) => {
                      const selectedId = e.target.value;
                      setlanguageParent(selectedId);
                    }}
                  >
                    <option value="all">Select Parent Language</option>

                    {filteredDocumentList &&
                      filteredDocumentList?.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>

                <div className="col-md-12">
                  <div
                    className="form-group-btn"
                    style={{ textAlign: "right" }}
                  >
                    <button
                      onClick={() => CreateDocument()}
                      className="btn addbtncmn"
                    >
                      {Location === "edit-document" ? "Save" : "Create"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Scrollbars>
        </div>
        {/* loader start here */}
        {loaderMain && <CommonLoader />}
      </section>
      {showModal?.open && (
        <CommonModel
          fromUrl={"product"}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </React.Fragment>
  );
};

export default AddEditDocument;
