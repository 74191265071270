import React, { useState } from "react";
import InputField from "../Common/InputField";
import CheckValid from "../Common/CheckValid";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import { Image } from "react-bootstrap";
import ForgotPasswordImage from "../../assets/img/forgot_password.png";
import { Link, useNavigate } from "react-router-dom";
import { ADMIN_DELETE_USER_API_URL, LOGIN_URL } from "../Shared/constant";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PostRequestCall from "../../Api/PostRequestCall";
import CountrySelectInput from "../Common/CountrySelectInput/CountryMobileInput";
const DeleteAccount = () => {
  const [inputValue, setInputValue] = useState({ Email: "" });
  const { DOB } = inputValue;
  const Navigate = useNavigate();
  // error message
  const [errorPhone, setErrorPhone] = useState("");
  const [errorDOB, setErrorDOB] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState({
    phone_code: "1",
    phone: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // login function
  const deleteUserfunct = async () => {
    const payload = {
      dob: inputValue?.DOB,
      phone_code: PhoneNumber?.phone_code,
      phone: PhoneNumber?.phone,
    };
    if (
      payload?.phone !== "" &&
      payload?.phone_code !== "" &&
      payload?.dob !== ""
    ) {
      const response = await PostRequestCall(
        ADMIN_DELETE_USER_API_URL,
        payload
      );
      if (response?.msg) {
        toast.success(response.msg);
        setTimeout(() => Navigate(LOGIN_URL), 200);
        clearForm();
      } else {
        clearForm();
        toast.error(response.error);
      }
    } else {
      CheckValid(PhoneNumber?.phone, {
        type: "phone",
        error: setErrorPhone,
      });
    }
  };

  // clear form
  const clearForm = () => {
    setInputValue({ ...inputValue, Email: "" });
  };

  return (
    <section className="login_wrapper">
      <div className="login_wrap_slider">
        <Image src={ForgotPasswordImage} alt="login image" />
        <h4>Delete Account ?</h4>
        <p>Enter your detail's to delete your account</p>
      </div>
      <div className="login_form_wraps">
        <div className="loginTEXTS">
          {/* <h1>SchemeBharat</h1> */}
          <h4>Delete Account ?</h4>
        </div>
        <div className="col-md-12">
          <CountrySelectInput
            errorPhone={errorPhone}
            phone={PhoneNumber}
            setPhone={setPhoneNumber}
            CheckValid={CheckValid}
            setErrorPhone={setErrorPhone}
          />
        </div>
        <InputField
          type="date"
          value={DOB}
          className={
            errorDOB !== "" ? "form-control error-form" : "form-control"
          }
          placeholder="Enter DOB"
          label="Date of birth"
          name="DOB"
          onChange={handleChange}
          onKeyUp={(e) =>
            CheckValid(e.target.value, { type: "DOB", error: setErrorDOB })
          }
          onKeyDown={EmptySpaceFieldValid}
          errormessage={errorDOB}
        />
        <div className="form-group-btn">
          <button className="btn btn-danger" onClick={() => deleteUserfunct()}>
            Delete Account
          </button>
          <Link to={LOGIN_URL} className="btn forgot_password_link">
            <i className="bi bi-arrow-left"></i> Back
          </Link>
        </div>
      </div>
    </section>
  );
};

export default DeleteAccount;
