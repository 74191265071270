/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import DeleteUserAPI from "../../Api/DeleteUserAPI";
import CommonLogout from "./CommonLogout";
import { DeleteUser, editUserList } from "../../redux/actions/adminActions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UpdateUserAPI from "../../Api/UpdateUserAPI";
import BtnLoader from "./BtnLoader";
import InputField from "./InputField";
import EmptySpaceFieldValid from "./EmptySpaceFieldValid";
import CheckValid from "./CheckValid";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import { englishStates, hindiStates } from "../Shared/constant";

const CommonModel = (props) => {
  const { websocket } = useContext(WebSocketContext);
  const [loader, setLoader] = useState(false);
  const [inputValue, setInputValue] = useState({
    ModelName: "",
    CategoryName: "",
    providerName: "",
  });
  const { ModelName, CategoryName, providerName } = inputValue;
  const Dispatch = useDispatch();
  const { showModal, setShowModal, setCurrentPage, setUserSearch, fromUrl } =
    props;
  const {
    accessToken,
    device_id,
    categoryList,
    languageList,
    schemeProviderAllList,
  } = useSelector((state) => state.adminReducers);
  // error message
  const [errorModelName, setErrorModelName] = useState("");
  const [providerNameSelect, setproviderNameSelect] = useState("");
  const [errorCategoryName, setErrorCategoryName] = useState("");
  const [errorproviderName, setErrorproviderName] = useState("");
  const [languageForCategory, setlanguageForCategory] = useState(
    showModal?.subtitle?.includes("Add") ? undefined : showModal?.Data?.language
  );
console.log("showModal?.Data?.language",showModal?.Data?.lang_parent)
  const [languageParent, setlanguageParent] = useState("");
  useEffect(() => {
    if (showModal?.subtitle === "Edit Category") {
      const filteredParticularDataList = categoryList?.list?.find(
        (item) => item.id === showModal?.Data?.lang_parent
      );
  
      if (filteredParticularDataList) {
        setlanguageParent(filteredParticularDataList.name);
      } else {
        setlanguageParent(""); // Handle the case where no match is found
      }
    } else if (showModal?.subtitle === "Edit Scheme Provider") {
      const filteredParticularDataList = schemeProviderAllList?.find(
        (item) => item.id === showModal?.Data?.lang_parent
      );
  
      if (filteredParticularDataList) {
        setlanguageParent(filteredParticularDataList.id);
      } else {
        setlanguageParent(""); // Handle the case where no match is found
      }
    }
  }, [showModal, categoryList, schemeProviderAllList]);
  
  const filteredCategoryList = categoryList?.list?.filter(
    (item) => item.language !== languageForCategory
  );
  const filteredSchemeProviderList = schemeProviderAllList?.filter(
    (item) => item.language !== languageForCategory
  );
  const selectedOption = filteredCategoryList.find(
    (item) => item.name === languageParent
  );
  let selectedId = "";
  if (selectedOption) {
    selectedId = selectedOption.id;
    // console.log(`Selected ID: ${selectedId}`);
  }
  // console.log("filteredCategoryList",filteredCategoryList)
  // close modal
  const handleClose = () => {
    setShowModal({ ...showModal, open: false });
  };

  // Textfield common onchange function
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
const setInitialDataForProvider = (name) => {
  setproviderNameSelect(name);

}
  useEffect(() => {
    if (showModal?.subtitle === "Edit Category") {
      setInputValue({ ...inputValue, CategoryName: showModal?.Data?.name });
    } else if (showModal?.subtitle === "Edit Scheme Provider") {
      setInitialDataForProvider(showModal?.Data?.name)
      console.log("showModal",showModal)
      setInputValue({ ...inputValue, providerName: showModal?.Data?.name });
    }
  }, [showModal]);

  const CommonFunction = async () => {
    if (showModal?.subtitle === "Delete User") {
      setLoader(true);
      const response = await DeleteUserAPI(accessToken, showModal?.Data?.id);
      if (response?.status === 200) {
        setTimeout(() => {
          setLoader(false);
          toast.success(response.msg);
          Dispatch(DeleteUser(response.data));
          setUserSearch("");
          handleClose();
        }, 100);
      } else if (response?.detail === "Token expired.") {
        setLoader(false);
        CommonLogout(accessToken, Dispatch);
        toast.error(response?.error || response?.data?.error);
      } else {
        setLoader(false);
        toast.error(response?.error || response?.data?.error);
      }
    } else if (showModal?.subtitle === "active User") {
      // setLoader(true);
      // const jsonData = JSON.stringify({
      //     user_id: showModal?.Data?.id,
      //     is_active: showModal?.Data?.is_active ? 0 : 1,
      // });
      // const response = await UpdateUserAPI(jsonData, accessToken);
      // if(response?.status === 200){
      //     setTimeout(() => {
      //         setLoader(false);
      //         toast.success(response.msg);
      //         Dispatch(editUserList(response.data));
      //         handleClose();
      //     }, 100);
      // } else if(response?.detail === "Token expired.") {
      //     setLoader(false);
      //     CommonLogout(accessToken, Dispatch);
      //     toast.error(response?.error || response?.data?.error);
      // } else {
      //     setLoader(false);
      //     toast.error(response?.error || response?.data?.error);
      // };
      let param = {
        transmit: "broadcast",
        url: "update_user",
        request: {
          staff_id: showModal?.Data?.id,
          user_id: showModal?.Data?.id,
          is_active: showModal?.Data?.is_active ? 0 : 1,
          DeviceId: device_id,

          first_name: showModal?.Data?.first_name,
          last_name: showModal?.Data?.last_name,
          phone_code: showModal?.Data?.phone_code,
          phone: showModal?.Data?.phone,
          email: showModal?.Data?.email,
          address: showModal?.Data?.address,
          permissions: showModal?.Data?.permissions,
        },
      };
      wsSend_request(websocket, param);
      handleClose();
    } else if (showModal?.subtitle === "admin User") {
      // setLoader(true);
      // const jsonData = JSON.stringify({
      //     user_id: showModal?.Data?.id,
      //     is_admin: showModal?.Data?.is_admin ? 0 : 1,
      // });
      // const response = await UpdateUserAPI(jsonData, accessToken);
      // if(response?.status === 200){
      //     setTimeout(() => {
      //         setLoader(false);
      //         toast.success(response.msg);
      //         Dispatch(editUserList(response.data));
      //         handleClose();
      //     }, 100);
      // } else if(response?.detail === "Token expired.") {
      //     setLoader(false);
      //     CommonLogout(accessToken, Dispatch);
      //     toast.error(response?.error || response?.data?.error);
      // } else {
      //     setLoader(false);
      //     toast.error(response?.error || response?.data?.error);
      // };
      let param = {
        transmit: "broadcast",
        url: "update_user",
        request: {
          staff_id: showModal?.Data?.id,
          is_admin: showModal?.Data?.is_admin ? 0 : 1,
          DeviceId: device_id,

          first_name: showModal?.Data?.first_name,
          last_name: showModal?.Data?.last_name,
          phone_code: showModal?.Data?.phone_code,
          phone: showModal?.Data?.phone,
          email: showModal?.Data?.email,
          address: showModal?.Data?.address,
          permissions: showModal?.Data?.permissions,
        },
      };
      wsSend_request(websocket, param);
      handleClose();
    } else if (showModal?.subtitle === "staff User") {
      // setLoader(true);
      // const jsonData = JSON.stringify({
      //     user_id: showModal?.Data?.id,
      //     is_staff: showModal?.Data?.is_staff ? 0 : 1,
      // });
      // const response = await UpdateUserAPI(jsonData, accessToken);
      // if(response?.status === 200){
      //     setTimeout(() => {
      //         setLoader(false);
      //         toast.success(response.msg);
      //         Dispatch(editUserList(response.data));
      //         handleClose();
      //     }, 100);
      // } else if(response?.detail === "Token expired.") {
      //     setLoader(false);
      //     CommonLogout(accessToken, Dispatch);
      //     toast.error(response?.error || response?.data?.error);
      // } else {
      //     setLoader(false);
      //     toast.error(response?.error || response?.data?.error);
      // };
      let param = {
        transmit: "broadcast",
        url: "update_user",
        request: {
          staff_id: showModal?.Data?.id,
          is_staff: showModal?.Data?.is_staff ? 0 : 1,
          DeviceId: device_id,

          first_name: showModal?.Data?.first_name,
          last_name: showModal?.Data?.last_name,
          phone_code: showModal?.Data?.phone_code,
          phone: showModal?.Data?.phone,
          email: showModal?.Data?.email,
          address: showModal?.Data?.address,
          permissions: showModal?.Data?.permissions,
        },
      };
      wsSend_request(websocket, param);
      handleClose();
    } else if (showModal?.subtitle === "Active Document") {
      let param = {
        transmit: "broadcast",
        url: "update_document",
        request: {
          document_id: showModal?.Data?.id,
          is_active: showModal?.Data?.is_active ? "0" : "1",
          DeviceId: device_id,
          name: showModal?.Data?.name,
          description: showModal?.Data?.description,
          lang_parent: showModal?.Data?.lang_parent,
        },
      };
      // console.log("document_list upadte", param);
      wsSend_request(websocket, param);
      CommonListUpdate("document_list");
      handleClose();
    } else if (showModal?.subtitle === "Active Product") {
      let param = {
        transmit: "broadcast",
        url: "update_scheme",
        request: {
          product_id: showModal?.Data?.id,
          is_active: showModal?.Data?.is_active ? "0" : "1",
          pi_no: showModal?.Data?.pi_no,
          avatar:
            showModal?.Data?.avatar === "" ? null : showModal?.Data?.avatar,
          name: showModal?.Data?.name,
          date: showModal?.Data?.date,
          DeviceId: device_id,
        },
      };
      wsSend_request(websocket, param);
      CommonListUpdate("scheme_list");
      handleClose();
    } else if (showModal?.subtitle === "Delete Scheme") {
      let param = {
        transmit: "broadcast",
        url: "delete_scheme",
        request: {
          product_id: showModal?.Data?.id,
          DeviceId: device_id,
        },
      };
      wsSend_request(websocket, param);
      setUserSearch("");
      CommonListUpdate("scheme_list");
      handleClose();
    } else if (showModal?.subtitle === "Delete Document") {
      let param = {
        transmit: "broadcast",
        url: "delete_document",
        request: {
          document_id: showModal?.Data?.id,
        },
      };
      // console.log("param delete doc", param, showModal?.Data?.id);
      wsSend_request(websocket, param);
      CommonListUpdate("document_list");
      handleClose();
    } else if (showModal?.subtitle === "Export Products") {
      const ResultFile =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
        showModal?.Data;
      var link = document.createElement("a");
      link.href = ResultFile;
      link.download = "sample.xlsx";
      link.click();
      setTimeout(() => {
        handleClose();
      }, 1000);
    } else if (showModal?.subtitle === "Products Update") {
      const ResultFile =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
        showModal?.Data;
      var link = document.createElement("a");
      link.href = ResultFile;
      link.download = "sample.xlsx";
      link.click();
      setTimeout(() => {
        handleClose();
      }, 1000);
    } else if (showModal?.subtitle === "Add Category") {
      if (CategoryName !== "") {
        let param = {
          transmit: "broadcast",
          url: "add_scheme_category",
          request: {
            name: CategoryName,
            language: languageForCategory,
            DeviceId: device_id,
            lang_parent: selectedId || languageParent,
          },
        };
        // console.log("Add category", param);
        wsSend_request(websocket, param);
        CommonListUpdate("scheme_category_list");
        handleClose();
      } else {
        CheckValid(CategoryName, {
          type: "Category Name",
          error: setErrorCategoryName,
        });
      }
    } else if (showModal?.subtitle === "Edit Category") {
      if (CategoryName !== "") {
        let param = {
          transmit: "broadcast",
          url: "update_scheme_category",
          request: {
            category_id: showModal?.Data?.id,
            name: CategoryName,
            DeviceId: device_id,
            language: languageForCategory || showModal?.Data?.language,
            lang_parent:
              selectedId || languageParent || showModal?.Data?.parent_language,
            is_active: showModal?.Data?.is_active ? "1" : "0",
            type: "admin",
          },
        };
        // console.log("update category", param);
        wsSend_request(websocket, param);
        CommonListUpdate("scheme_category_list");
        handleClose();
      } else {
        CheckValid(CategoryName, {
          type: "Category Name",
          error: setErrorCategoryName,
        });
      }
    } else if (showModal?.subtitle === "Active Category") {
      let param = {
        transmit: "broadcast",
        url: "update_scheme_category",
        request: {
          category_id: showModal?.Data?.id,
          is_active: showModal?.Data?.is_active ? "0" : "1",
          DeviceId: device_id,
          language: languageForCategory,
          lang_parent: selectedId || languageParent,
          name: showModal?.Data?.name,
          type: "admin",
        },
      };
      // console.log("update category", param);
      wsSend_request(websocket, param);
      CommonListUpdate("scheme_category_list");
      handleClose();
    } else if (showModal?.subtitle === "active Scheme Provider") {
      let param = {
        transmit: "broadcast",
        url: "update_scheme_provider",
        request: {
          provider_id: showModal?.Data?.id,
          is_active: showModal?.Data?.is_active ? "0" : "1",
          DeviceId: device_id,
          language: languageForCategory || showModal?.Data?.language,
          lang_parent:
            selectedId || languageParent || showModal?.Data?.lang_parent,
          name: showModal?.Data?.name,
          type: "admin",
        },
      };
      // console.log("update scheme_provider_list", param);
      wsSend_request(websocket, param);
      CommonListUpdate("scheme_provider_list");
      handleClose();
    } else if (showModal?.subtitle === "Delete Category") {
      let param = {
        transmit: "broadcast",
        url: "delete_scheme_category",
        request: {
          category_id: showModal?.Data?.id,
          DeviceId: device_id,
        },
      };
      wsSend_request(websocket, param);
      setUserSearch("");
      CommonListUpdate("scheme_category_list");
      handleClose();
    } else if (showModal?.subtitle === "Add Scheme Provider") {
      if (providerNameSelect !== "") {
        let param = {
          transmit: "broadcast",
          url: "add_scheme_provider",
          request: {
            name: providerNameSelect,
            DeviceId: device_id,
            language: languageForCategory,
            lang_parent: selectedId || languageParent,
          },
        };
        wsSend_request(websocket, param);
        CommonListUpdate("scheme_provider_list");
        handleClose();
      } else {
        CheckValid(providerNameSelect, {
          type: "Setting Name",
          error: setErrorproviderName,
        });
      }
    } else if (showModal?.subtitle === "Edit Scheme Provider") {
      if (providerNameSelect !== "") {
        let param = {
          transmit: "broadcast",
          url: "update_scheme_provider",
          request: {
            provider_id: showModal?.Data?.id,
            name: providerNameSelect,
            DeviceId: device_id,
            language: languageForCategory || showModal?.Data?.language,
            lang_parent:
              selectedId || languageParent || showModal?.Data?.parent_language,
            is_active: showModal?.Data?.is_active ? "1" : "0",
          },
        };
        // console.log("update category", param);
        wsSend_request(websocket, param);
        CommonListUpdate("scheme_provider_list");
        handleClose();
      } else {
        CheckValid(providerNameSelect, {
          type: "Provider Name",
          error: setErrorproviderName,
        });
      }
    } else if (showModal?.subtitle === "Delete Scheme Provider") {
      let param = {
        transmit: "broadcast",
        url: "delete_scheme_provider",
        request: {
          provider_id: showModal?.Data?.id,
          DeviceId: device_id,
        },
      };
      wsSend_request(websocket, param);
      setUserSearch("");
      CommonListUpdate("scheme_provider_list");
      handleClose();
    } else if (showModal?.subtitle === "Edit Field") {
      let param = {
        transmit: "broadcast",
        url: "update_scheme_setting",
        request: {
          setting_id: showModal?.Data?.id,
          DeviceId: device_id,
          name: providerNameSelect,
        },
      };
      wsSend_request(websocket, param);
      setUserSearch("");
      CommonListUpdate("scheme_provider_list");
      handleClose();
    } else if (showModal?.subtitle === "Logout") {
      CommonLogout(accessToken, Dispatch);
    }
  };

  const FailProductFunction = () => {
    const ResultFile =
      "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
      showModal?.Data?.failed;
    var link = document.createElement("a");
    link.href = ResultFile;
    link.download = "sample.xlsx";
    link.click();
    // setTimeout(() => {
    //     handleClose();
    // }, 1000);
  };

  const UpdatedProductFunction = () => {
    const ResultFile =
      "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
      showModal?.Data?.updated;
    var link = document.createElement("a");
    link.href = ResultFile;
    link.download = "sample.xlsx";
    link.click();
    // setTimeout(() => {
    //     handleClose();
    // }, 1000);
  };

  // When editing API calling, at that time list API calling.
  const CommonListUpdate = (url) => {
    let param = {
      transmit: "broadcast",
      url: url,
      request: {
        filter: "all",
        limit: 10,
        page_no: 1,
        search: "",
        type: "admin",
      },
    };
    wsSend_request(websocket, param);
    if (fromUrl !== "product") {
      setCurrentPage(1);
    }
  };

  return (
    <Modal
      className={
        showModal?.title === "Create Supplier"
          ? "commonModal addSupplierModel"
          : "commonModal"
      }
      show={showModal?.open}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title
          style={{ width: showModal?.title === "Are you sure?" ? "100%" : "" }}
        >
          {showModal?.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showModal?.modalType === "form" ? (
          <Form.Group className="mb-3">
            {showModal?.title === "Create Name" ||
            showModal?.title === "Edit Name" ? (
              <input type="text" />
            ) : showModal?.title === "Add Category" ||
              showModal?.title === "Edit Category" ? (
              <>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="">
                      Select Language{" "}
                      <span className="text-danger fs-5 position-absolute pl-1">
                        *
                      </span>
                    </label>
                    <select
                      className="form-control"
                      value={languageForCategory} // Add the state variable you want to bind to the select element
                      onChange={(e) => setlanguageForCategory(e.target.value)}
                      required
                    >
                      <option value="all" selected>
                        Select Language
                      </option>
                      {languageList &&
                        languageList.map((item, index) => (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <InputField
                  type="text"
                  value={CategoryName}
                  className={
                    errorCategoryName !== ""
                      ? "form-control error-form"
                      : "form-control"
                  }
                  placeholder="Enter Category Name"
                  label="Category Name <span class='redmednstar'>*</span>"
                  name="CategoryName"
                  onChange={handleChange}
                  onKeyUp={(e) =>
                    CheckValid(e.target.value, {
                      type: "Category Name",
                      error: setErrorCategoryName,
                    })
                  }
                  onKeyDown={EmptySpaceFieldValid}
                  errormessage={errorCategoryName}
                />

                <div className="col-md-12">
                  <div className="form-group">
                    <label>Select Parent Language</label>
                    <select
                      className="form-control"
                      value={languageParent}
                      onChange={(e) => {
                        setlanguageParent(e.target.value);
                      }}
                    >
                      <option value="all" selected>
                        Select Parent Language
                      </option>

                      {filteredCategoryList &&
                        filteredCategoryList.map((item, index) => (
                          <option key={index} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </>
            ) : showModal?.title === "Add Scheme Provider" ||
              showModal?.title === "Edit Scheme Provider" ? (
              <>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="">
                      Select Language{" "}
                      <span className="text-danger fs-5 position-absolute pl-1">
                        *
                      </span>
                    </label>
                    <select
                      className="form-control"
                      value={languageForCategory} // Add the state variable you want to bind to the select element
                      onChange={(e) => setlanguageForCategory(e.target.value)}
                      required
                    >
                      <option value="all" selected>
                        Select Language
                      </option>

                      {languageList &&
                        languageList.map((item, index) => (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                {/* <InputField
                  type="text"
                  value={inputValue?.providerName}
                  className={
                    errorproviderName !== ""
                      ? "form-control error-form"
                      : "form-control"
                  }
                  placeholder="Enter Scheme Provider Name"
                  label="Scheme Provider Name <span class='redmednstar'>*</span>"
                  name="providerName"
                  onChange={handleChange}
                  onKeyUp={(e) =>
                    CheckValid(e.target.value, {
                      type: "Provider Name",
                      error: setErrorproviderName,
                    })
                  }
                  onKeyDown={EmptySpaceFieldValid}
                  errormessage={errorproviderName}
                /> */}
<div className="col-md-12">
  <div className="form-group">
    <label>Select Scheme Provider
    <span className="text-danger fs-5 position-absolute pl-1">
                        *
                      </span>
    </label>
    {console.log("providerNameSelect",providerNameSelect)}
    <select
  className="form-control"
  value={providerNameSelect}
  onChange={(e) => {
    setproviderNameSelect(e.target.value);
  }}
  required
>
  {showModal?.subtitle === "Edit Scheme Provider" ? (<option value={providerNameSelect}>{providerNameSelect}</option>) : (
  <option value="all">Select Provider</option>)}
  {languageForCategory === "Hindi"
    ? hindiStates.map((state, index) => (
        <option key={index}>{state}</option>
      ))
    : englishStates.map((state, index) => (
        <option key={index}>{state}</option>
      ))}
</select>

  </div>
</div>


                <div className="col-md-12">
                  <div className="form-group">
                    <label>Select Parent Language</label>
                    <select
                      className="form-control"
                      value={languageParent}
                      onChange={(e) => {
                        setlanguageParent(e.target.value);
                      }}
                      required
                    >
                      <option value="all" selected>
                        Select Parent Language
                      </option>

                      {filteredSchemeProviderList &&
                        filteredSchemeProviderList.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </>
            ) : showModal?.title === "Create Model" ||
              showModal?.title === "Edit Model" ? (
              <>
                <InputField
                  type="text"
                  value={ModelName}
                  className={
                    errorModelName !== ""
                      ? "form-control error-form"
                      : "form-control"
                  }
                  placeholder="Enter Model Name"
                  label="Model Name <span class='redmednstar'>*</span>"
                  name="ModelName"
                  onChange={handleChange}
                  onKeyUp={(e) =>
                    CheckValid(e.target.value, {
                      type: "Model Name",
                      error: setErrorModelName,
                    })
                  }
                  onKeyDown={EmptySpaceFieldValid}
                  errormessage={errorModelName}
                />
              </>
            ) : (
              <></>
            )}
          </Form.Group>
        ) : (
          <>
            <div className="notformsection">
              {showModal?.subtitle === "Logout" && (
                <i className="bi bi-box-arrow-right logoutIconbtn"></i>
              )}
              <p
                dangerouslySetInnerHTML={{ __html: showModal?.description }}
              ></p>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        {showModal?.subtitle === "Products Update and Export" ? (
          <React.Fragment>
            <Button
              variant="primary"
              className="addbtncmn"
              style={{ padding: "7px 15px" }}
              onClick={FailProductFunction}
            >
              {loader && <BtnLoader />}
              {showModal?.button} Failed Product
            </Button>
            <Button
              variant="primary"
              className="addbtncmn"
              style={{ padding: "7px 15px" }}
              onClick={UpdatedProductFunction}
            >
              {loader && <BtnLoader />}
              {showModal?.button} Updated Product
            </Button>
          </React.Fragment>
        ) : (
          <Button
            variant="primary"
            className="addbtncmn"
            onClick={CommonFunction}
          >
            {loader && <BtnLoader />}
            {showModal?.button}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CommonModel;
