/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import DataTableFilter from "../Common/DataTableFilter";
import { Scrollbars } from "react-custom-scrollbars-2";
import moment from "moment";
import { useSelector } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import CommonPagination from "../Common/CommonPagination";
import CommonModel from "../Common/CommonModel";
import CommonToggle from "../Common/CommonToggle";

const SchemeProviderManagement = () => {
  const { websocket } = useContext(WebSocketContext);
  const { selfInfo, schemeProviderAllList,schemeProviderList } = useSelector(
    (state) => state.adminReducers
  );
  const [userSearch, setUserSearch] = useState("");
  const [userLimit, setUserLimit] = useState("");
  const [selectedLanguage, setselectedLanguage] = useState("");
  const [userType, setUserType] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState({
    open: false,
    title: "",
    modalType: "",
    Data: {},
  });
  // allow permission for user
  const AllowPermissGet = (data_, type, booleancheck) => {
    if (type === "is_active") {
      setShowModal({
        ...showModal,
        open: !showModal?.open,
        title: "Are you sure?",
        subtitle: `${type.split("_")[1]} Scheme Provider`,
        description: `Do you really want to ${
          booleancheck ? "deactivate" : "active"
        } to <h5>${data_?.name}</h5>`,
        modalType: "not form",
        button: "Yes",
        Data: data_,
      });
    } else {
      setShowModal({
        ...showModal,
        open: !showModal?.open,
        title: "Are you sure?",
        subtitle: `${type.split("_")[1]} Scheme Provider`,
        description: `Do you really want to ${booleancheck ? "remove " : ""} ${
          type.split("_")[1]
        } to <h5>${data_?.first_name}</h5>`,
        modalType: "not form",
        button: "Yes",
        Data: data_,
      });
    }
  };
  // page change
  const currentFunction = (page) => {
    setCurrentPage(page);
  };

  // clear search box
  const ClearSearchFiled = () => {
    setUserSearch("");
  };
  // select user type
  const SelectUserTypeFnct = (e) => {
    setUserType(e.target.value);
    setCurrentPage(1);
  };
  // get supplier list
  useEffect(() => {
    let param = {
      transmit: "broadcast",
      url: "scheme_provider_list",
      request: {
        limit: userLimit || 5,
        page_no: currentPage,
        search: userSearch.trim(),
        filter: userType,
        language: selectedLanguage || "all",
        type: "admin",
      },
    };
    wsSend_request(websocket, param);
  }, [userSearch, userLimit, currentPage, selectedLanguage, userType]);

  // add new category
  const AddCategoryFnct = () => {
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Add Scheme Provider",
      subtitle: "Add Scheme Provider",
      description: ``,
      modalType: "form",
      button: "Yes",
    });
  };
  // select user language
  const SelectUserLangTypeFnct = (e) => {
    setselectedLanguage(e.target.value);
    setCurrentPage(1);
  };
  // edit category
  const EditCategory = (data_) => {
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Edit Scheme Provider",
      subtitle: "Edit Scheme Provider",
      description: ``,
      modalType: "form",
      button: "Save",
      Data: data_,
    });
  };

  // delete category
  const DeleteCategoryFnct = (data_) => {
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Delete Scheme Provider",
      subtitle: "Delete Scheme Provider",
      description: `Do you really want to delete <h5>${data_?.name}</h5>`,
      modalType: "not form",
      button: "Yes",
      Data: data_,
    });
  };

  return (
    <section className="dashboard_wrapper">
      <DataTableFilter
        languageType={selectedLanguage}
        setselectedLanguage={setselectedLanguage}
        SelectUserLangTypeFnct={SelectUserLangTypeFnct}
        userType={userType}
        setUserType={setUserType}
        SelectUserTypeFnct={SelectUserTypeFnct}
        filterType={"Provider"}
        tooltip="name"
        userSearch={userSearch}
        setUserSearch={setUserSearch}
        userLimit={userLimit}
        setUserLimit={setUserLimit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        ClearSearchFiled={ClearSearchFiled}
        buttonvisible={
          selfInfo?.is_admin ||
          selfInfo?.permissions?.includes("add-product-setting")
        }
        ButtonSet={{
          buttontitle: "Add Scheme Provider",
          function: AddCategoryFnct,
        }}
      />
      <div
        className="tableContent"
        style={{
          height:
            window.screen.width > 540
              ? "calc(100vh - 155px)"
              : "calc(100vh - 143px)",
        }}
      >
        <Scrollbars
          style={{
            height:
            parseInt(schemeProviderList?.pagination?.total_records) > 5
                ? window.screen.width < 767
                  ? "calc(100% - 10px)"
                  : "calc(100% - 38px)"
                : "calc(100% - 0px)",
          }}
          className="ScrollbarsWrapper"
          renderView={(props) => <div {...props} className="view" />}
        >
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Language</th>
                  <th>Created At</th>
                  <th>Active</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {schemeProviderAllList?.length > 0 &&
                  schemeProviderAllList?.map((user, index) => {
                    return (
                      <tr key={index}>
                        <td>{user?.name}</td>
                        <td>{user?.language}</td>
                        <td>{moment(user.created_at).format("LL")}</td>
                        <td>
                          {(selfInfo?.is_admin ||
                            selfInfo?.permissions?.includes(
                              "update-scheme"
                            )) && (
                            <CommonToggle
                              valueToggle={user?.is_active}
                              setValueToggle={() =>
                                AllowPermissGet(
                                  user,
                                  "is_active",
                                  user?.is_active
                                )
                              }
                              name={""}
                            />
                          )}
                        </td>
                        <td className="respoactionSupplier">
                          {(selfInfo?.is_admin ||
                            selfInfo?.permissions?.includes(
                              "update-product-setting"
                            )) && (
                            <button
                              type="button"
                              className="btn actiontblebtn"
                              onClick={() => EditCategory(user)}
                            >
                              <i className="bi bi-pencil-square"></i>
                            </button>
                          )}
                          {(selfInfo?.is_admin ||
                            selfInfo?.permissions?.includes(
                              "delete-product-setting"
                            )) && (
                            <button
                              type="button"
                              className="btn actiontblebtn"
                              onClick={() => DeleteCategoryFnct(user)}
                            >
                              <i
                                className="bi bi-trash-fill"
                                style={{ color: "red" }}
                              ></i>
                            </button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                {schemeProviderAllList?.length === 0 && (
                  <tr>
                    <td style={{ textAlign: "center" }} colSpan={8}>
                      Scheme Provider Not Found !
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Scrollbars>
        {/* pagination */}
        {console.log("schemeProviderAllList",parseInt(schemeProviderList?.pagination?.total_records))}
        {parseInt(schemeProviderList?.pagination?.total_records) > 5 && (
          <CommonPagination
            currentPage={currentPage}
            paginationLength={schemeProviderList?.pagination}
            currentFunction={currentFunction}
          />
        )}
      </div>
      {showModal?.open && (
        <CommonModel
          setUserSearch={setUserSearch}
          setCurrentPage={setCurrentPage}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </section>
  );
};

export default SchemeProviderManagement;
