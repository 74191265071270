/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { FAQ_MANAGEMENT_URL } from "../../Shared/constant";
import BackButton from "../../Common/BackButton";
import InputField from "../../Common/InputField";
import CheckValid from "../../Common/CheckValid";
import EmptySpaceFieldValid from "../../Common/EmptySpaceFieldValid";
import { Spinner } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useSelector, useDispatch } from "react-redux";
import wsSend_request from "../../../Api/ws/ws_request";
import { WebSocketContext } from "../../../App";
import CommonModel from "../../Common/CommonModel";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import CommonLoader from "../../Common/CommonLoader";
import "react-toastify/dist/ReactToastify.css";
import { Editor } from "react-draft-wysiwyg";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
const AddEditFAQ = () => {
  const Navigate = useNavigate();
  const params = useParams();
  const Location = useLocation()?.pathname?.split("/")[1];
  const { websocket } = useContext(WebSocketContext);
  const [inputValue, setInputValue] = useState({
    language: "",
    question: "",
    answer: "",
    faq_id: "",
    lang_parent: "",
  });
  const { language, question, answer, lang_parent } = inputValue;
  const [ErrorQuestion, setErrorQuestion] = useState("");
  const [ErrorAnswer, setErrorAnswer] = useState("");

  const [SelectedLanguage, setSelectedLanguage] = useState("");
  const [languageParent, setlanguageParent] = useState("");
  console.log("languageParent", languageParent);
  const [editorState, setQueEditorState] = useState(EditorState.createEmpty());
  const [questionEditorState, setAnsEditorState] = useState(
    EditorState.createEmpty()
  );
  const {
    viewProduct,
    faqList,
    device_id,
    loaderMain,
    ClearFormSet,
    languageList,
  } = useSelector((state) => state.adminReducers);
  const [showModal, setShowModal] = useState({
    open: false,
    title: "",
    modalType: "",
    Data: {},
  });
  const [faqQuestion, setFaqQuestion] = useState("");
  const [faqAnswer, setFaqAnswer] = useState("");
  useEffect(() => {
    let param = {
      transmit: "broadcast",
      url: "faq_list",
      request: {
        filter: "all",
        limit: 5000,
        page_no: 1,
        search: "",
        type: "admin",
        DeviceId: device_id,
      },
    };
    wsSend_request(websocket, param);
  }, []);
  // get product details
  useEffect(() => {
    if (Location === "edit-faq") {
      if (params?.roomId) {
        let param = {
          transmit: "broadcast",
          url: "faq_detail",
          request: {
            faq_id: params?.roomId,
            DeviceId: device_id,
          },
        };
        wsSend_request(websocket, param);
      }
    }
  }, [params?.roomId, Location]);
  const filteredFAQList = faqList?.list?.filter(
    (item) => item.language !== SelectedLanguage
  );
  useEffect(() => {
    if (Location === "edit-faq") {
      if (viewProduct?.question) {
        const defaultquestionContent = viewProduct?.question;
        const questionContentState = ContentState.createFromBlockArray(
          convertFromHTML(defaultquestionContent)
        );
        const questionEditorState =
          EditorState.createWithContent(questionContentState);
        setQueEditorState(questionEditorState);
      }
      if (viewProduct?.answer) {
        const defaultanswerContent = viewProduct?.answer;
        const answerContentState = ContentState.createFromBlockArray(
          convertFromHTML(defaultanswerContent)
        );
        const answerEditorState =
          EditorState.createWithContent(answerContentState);
        setAnsEditorState(answerEditorState);
      }
      if (viewProduct?.language) {
        setSelectedLanguage(viewProduct?.language);
      }
      if (viewProduct?.lang_parent) {
        const filteredDocumentList = faqList?.list?.find(
          (item) => item?.id === viewProduct?.lang_parent
        );
        console.log("filteredDocumentList", filteredDocumentList);
        if (filteredDocumentList) {
          setlanguageParent(filteredDocumentList?.id);
        }
      }
      if (inputValue?.question) {
        setInputValue({
          ...inputValue,
          language: viewProduct?.language,
          answer: viewProduct?.answer,
          question: viewProduct?.question,
          lang_parent: viewProduct?.lang_parent,
          faq_id: viewProduct?.faq_id || params?.roomId,
        });
      }
    }
  }, [viewProduct]);
  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const onAnswerEditorChange = (newEditorState) => {
    setAnsEditorState(newEditorState);
    const contentState = newEditorState.getCurrentContent();
    const contentAsRaw = convertToRaw(contentState);
    const htmlContent = draftToHtml(contentAsRaw);
    setFaqAnswer(htmlContent);
    setInputValue((prevInputValue) => ({
      ...prevInputValue,
      answer: htmlContent,
    }));
  };

  const onEditorChange = (newEditorState) => {
    setQueEditorState(newEditorState);
    const contentState = newEditorState.getCurrentContent();
    const contentAsRaw = convertToRaw(contentState);
    const htmlContent = draftToHtml(contentAsRaw);
    setFaqQuestion(htmlContent);

    setInputValue((prevInputValue) => ({
      ...prevInputValue,
      question: htmlContent,
    }));
  };

  const CreateDocument = () => {
    if (Location === "edit-faq") {
      if (inputValue?.question !== "" || inputValue?.answer !== "") {
        let param;

        param = {
          transmit: "broadcast",
          url: "update_faq",
          request: {
            language: SelectedLanguage,
            answer: inputValue?.answer || faqAnswer,
            question: inputValue?.question || faqQuestion,
            faq_id: inputValue?.faq_id || params?.roomId,
            lang_parent: languageParent,
          },
        };
        console.log("param", param);
        wsSend_request(websocket, param);
      }
    } else {
      if (inputValue?.question !== "" || inputValue?.answer !== "") {
        let paramRequest;

        paramRequest = {
          language: SelectedLanguage,
          answer: faqAnswer,
          question: faqQuestion,
          lang_parent: languageParent,
        };

        const paramfilter = Object.entries(paramRequest).filter((elm) => {
          if (elm[1] !== "") {
            return elm;
          }
        });
        let param = {
          transmit: "broadcast",
          url: "add_faq",
          request: Object.fromEntries(paramfilter),
        };
        wsSend_request(websocket, param);
      } else {
        CheckValid(inputValue?.question || faqQuestion, {
          type: "question",
          error: setErrorQuestion,
        });
        CheckValid(inputValue?.answer || faqQuestion, {
          type: "answer",
          error: setErrorAnswer,
        });
      }
    }
    Navigate(FAQ_MANAGEMENT_URL);
  };

  useEffect(() => {
    if (ClearFormSet.url === "update_faq" && ClearFormSet.action === true) {
      Navigate(FAQ_MANAGEMENT_URL);
      ClearForm();
    } else if (ClearFormSet.url === "add_faq" && ClearFormSet.action === true) {
      Navigate(FAQ_MANAGEMENT_URL);
      ClearForm();
    }
  }, [ClearFormSet]);

  // clear form
  const ClearForm = () => {
    setInputValue({
      ...inputValue,
      language: "",
      question: "",
      answer: "",
      lang_parent: "",
    });
  };

  return (
    <React.Fragment>
      <div
        className="header_back header_back_with_btn"
        style={{
          justifyContent: Location !== "edit-product" ? "end" : "space-between",
        }}
      >
        <BackButton targeturl={FAQ_MANAGEMENT_URL} title={"Back"} />
      </div>
      <section className="dashboard_wrapper_form">
        <div className="scrollform">
          <Scrollbars
            style={{ height: "calc(100vh - 150px)" }}
            className="ScrollbarsWrapper"
            renderView={(props) => <div {...props} className="view" />}
          >
            <div className="scrollformCustom">
              <div className="row" style={{ margin: "0 auto" }}>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Select Language{" "}
                      <span className="text-danger fs-5 position-absolute pl-1">
                        *
                      </span>
                    </label>
                    <select
                      className="form-control"
                      value={SelectedLanguage}
                      required
                      onChange={(e) => setSelectedLanguage(e.target.value)}
                    >
                      <option value="all" selected>
                        select{" "}
                      </option>
                      {languageList?.map((elm, index) => {
                        return (
                          <option key={index} value={elm}>
                            {elm}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="col-md-6">
                  <label htmlFor="lang_parent" className="fs-6">
                    Select Parent Language
                  </label>
                  <select
                    name="lang_parent"
                    className="form-control"
                    value={languageParent}
                    onChange={(e) => {
                      const selectedId = e.target.value;
                      setlanguageParent(selectedId);
                    }}
                  >
                    {console.log("languageParent", languageParent)}
                    <option value="all">Select Parent Language</option>

                    {filteredFAQList &&
                      filteredFAQList.map((item, index) => (
                        <option key={index} value={item.id}>
                          <div
                            dangerouslySetInnerHTML={{ __html: item?.question }}
                          />
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-md-6">
                  <label htmlFor="toolbarClassName" className="mb-2">
                    Question
                  </label>
                  <Editor
                    editorState={editorState}
                    onEditorStateChange={onEditorChange}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    wrapperStyle={{
                      width: 400,
                      border: "1px solid #ccc",
                      backgroundColor: "white",
                      padding: "10px",
                      borderRadius: "4px",
                    }}
                    editorStyle={{
                      minHeight: "150px",
                      border: "1px solid #ccc",
                      padding: "10px",
                      borderRadius: "4px",
                    }}
                    toolbar={{
                      options: [
                        "inline",
                        "blockType",
                        "fontSize",
                        "fontFamily",
                        "list",
                        "textAlign",
                        "colorPicker",
                        "link",
                        "emoji",
                        "remove",
                        "history",
                      ],
                      inline: {
                        inDropdown: false,
                        options: [
                          "bold",
                          "italic",
                          "underline",
                          "strikethrough",
                          "superscript",
                          "subscript",
                        ],
                      },
                      list: {
                        inDropdown: false,
                        options: ["unordered", "ordered"],
                      },
                      textAlign: {
                        inDropdown: true,
                      },
                      link: { inDropdown: false },
                      history: { inDropdown: false },
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="toolbarClassName" className="mb-2">
                    Answer
                  </label>
                  <Editor
                    editorState={questionEditorState}
                    onEditorStateChange={onAnswerEditorChange}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    wrapperStyle={{
                      width: 400,
                      border: "1px solid #ccc",
                      backgroundColor: "white",
                      padding: "10px",
                      borderRadius: "4px",
                    }}
                    editorStyle={{
                      minHeight: "150px",
                      border: "1px solid #ccc",
                      padding: "10px",
                      borderRadius: "4px",
                    }}
                    toolbar={{
                      options: [
                        "inline",
                        "blockType",
                        "fontSize",
                        "fontFamily",
                        "list",
                        "textAlign",
                        "colorPicker",
                        "link",
                        "emoji",
                        "remove",
                        "history",
                      ],
                      inline: {
                        inDropdown: false,
                        options: [
                          "bold",
                          "italic",
                          "underline",
                          "strikethrough",
                          "superscript",
                          "subscript",
                        ],
                      },
                      list: {
                        inDropdown: false,
                        options: ["unordered", "ordered"],
                      },
                      textAlign: {
                        inDropdown: true,
                      },
                      link: { inDropdown: false },
                      history: { inDropdown: false },
                    }}
                  />
                </div>

                <div className="col-md-12">
                  <div
                    className="form-group-btn"
                    style={{ textAlign: "right" }}
                  >
                    <button
                      onClick={() => CreateDocument()}
                      className="btn addbtncmn"
                    >
                      {Location === "edit-product" ? "Save" : "Create"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Scrollbars>
        </div>
        {/* loader start here */}
        {loaderMain && <CommonLoader />}
      </section>
      {showModal?.open && (
        <CommonModel
          fromUrl={"product"}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </React.Fragment>
  );
};

export default AddEditFAQ;
