/* eslint-disable */
import React from "react";
import { Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { Image } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars-2";
import {
  ADD_SCHEME_MANAGEMENT_URL,
  ADD_USER_URL,
  EDIT_SCHEME_MANAGEMENT_URL,
  EDIT_CONTACT_MANAGEMENT_URL,
  VIEW_SCHEME_MANAGEMENT_URL,
  VIEW_CONTACT_MANAGEMENT_URL,
  DASHBOARD_URL,
  SCHEME_MANAGEMENT_URL,
  CONTACT_MANAGEMENT_URL,
  ADD_CONTACT_MANAGEMENT_URL,
  USER_MANAGEMENT_URL,
  
  EDIT_USER_URL,
  CATEGORY_MANAGEMENT_URL,
  SCHEME_PROVIDER_MANAGEMENT_URL,
  DOCUMENT_MANAGEMENT_URL,
  EDIT_DOCUMENT_MANAGEMENT_URL,
  VIEW_DOCUMENT_MANAGEMENT_URL,
  ADD_DOCUMENT_MANAGEMENT_URL,
  FAQ_MANAGEMENT_URL,
  ADD_FAQ_MANAGEMENT_URL,
  VIEW_FAQ_MANAGEMENT_URL,
  EDIT_FAQ_MANAGEMENT_URL,
  NOTI_MANAGEMENT_URL,
  ADD_NOTI_MANAGEMENT_URL,
  VIEW_NOTI_MANAGEMENT_URL,
  EDIT_NOTI_MANAGEMENT_URL,
} from "../Shared/constant";
import { useSelector } from "react-redux";

const Sidebar = (props) => {
  const { setShowSidebar } = props;
  const RouteUrl = useLocation()?.pathname;
  const RouteUrlEditView = "/" + RouteUrl.split("/")[1];
  const { selfInfo } = useSelector((state) => state.adminReducers);

  return (
    <div className="sidebar">
      {/* <div className="navbarHeader">
            <h1>Logo</h1>
        </div> */}
      {window.screen.width < 901 && (
        <button
          className="close_sidebarbtn"
          onClick={() => setShowSidebar(false)}
        >
          <i className="bi bi-x-lg"></i>{" "}
        </button>
      )}
      <Scrollbars
        style={{ height: "calc(100vh)" }}
        renderView={(props) => <div {...props} className="view" />}
        className="ScrollbarsSidebar"
      >
        {window.screen.width < 901 ? (
          <Nav defaultActiveKey={DASHBOARD_URL} className="flex-column">
            {(selfInfo?.is_admin ||
              selfInfo?.permissions?.includes("dashboard")) && (
              <Link
                onClick={() => setShowSidebar(false)}
                to={DASHBOARD_URL}
                className={
                  RouteUrl === DASHBOARD_URL || RouteUrl === "/"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i className="bi bi-speedometer"></i>
                <span>Dashboard</span>
              </Link>
            )}
            {(selfInfo?.is_admin ||
              selfInfo?.permissions?.includes("user-list") ||
              selfInfo?.permissions?.includes("add-user") ||
              selfInfo?.permissions?.includes("update-user") ||
              selfInfo?.permissions?.includes("delete-user")) && (
              <>
                {selfInfo?.is_admin && (
                  <Link
                    onClick={() => setShowSidebar(false)}
                    to={USER_MANAGEMENT_URL}
                    className={
                      RouteUrl === USER_MANAGEMENT_URL ||
                      RouteUrl === ADD_USER_URL ||
                      RouteUrlEditView === EDIT_USER_URL ||
                      RouteUrl === "/"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="bi bi-person"></i>
                    <span>User Management</span>
                  </Link>
                )}
              </>
            )}
            {(selfInfo?.is_admin ||
              selfInfo?.permissions?.includes("document-list") ||
              selfInfo?.permissions?.includes("add-document") ||
              selfInfo?.permissions?.includes("update-document") ||
              selfInfo?.permissions?.includes("delete-document")) && (
              <Link
                onClick={() => setShowSidebar(false)}
                to={DOCUMENT_MANAGEMENT_URL}
                className={
                  RouteUrl === DOCUMENT_MANAGEMENT_URL ||
                  RouteUrl === ADD_DOCUMENT_MANAGEMENT_URL ||
                  RouteUrlEditView === VIEW_DOCUMENT_MANAGEMENT_URL ||
                  RouteUrlEditView === EDIT_DOCUMENT_MANAGEMENT_URL ||
                  RouteUrl === "/"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i className="bi bi-file-earmark-break"></i>
                <span>Document Management</span>
              </Link>
            )}

            {(selfInfo?.is_admin ||
              selfInfo?.permissions?.includes("add-category") ||
              selfInfo?.permissions?.includes("update-category") ||
              selfInfo?.permissions?.includes("delete-category")) && (
              <Link
                onClick={() => setShowSidebar(false)}
                to={CATEGORY_MANAGEMENT_URL}
                className={
                  RouteUrl === CATEGORY_MANAGEMENT_URL || RouteUrl === "/"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i className="bi bi-journals"></i>
                <span>Category Management</span>
              </Link>
            )}

            {(selfInfo?.is_admin ||
              selfInfo?.permissions?.includes("scheme-provider-list") ||
              selfInfo?.permissions?.includes("add-scheme-provider") ||
              selfInfo?.permissions?.includes("update-scheme-provider") ||
              selfInfo?.permissions?.includes("delete-scheme-provider")) && (
              <Link
                onClick={() => setShowSidebar(false)}
                to={SCHEME_PROVIDER_MANAGEMENT_URL}
                className={
                  RouteUrl === SCHEME_PROVIDER_MANAGEMENT_URL
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i className="bi bi-gear"></i>
                <span>Scheme Provider Management</span>
              </Link>
            )}
            {(selfInfo?.is_admin ||
              selfInfo?.permissions?.includes("scheme-list") ||
              selfInfo?.permissions?.includes("add-scheme") ||
              selfInfo?.permissions?.includes("update-scheme") ||
              selfInfo?.permissions?.includes("delete-scheme")) && (
              <Link
                onClick={() => setShowSidebar(false)}
                to={SCHEME_MANAGEMENT_URL}
                className={
                  RouteUrl === SCHEME_MANAGEMENT_URL ||
                  RouteUrl === ADD_SCHEME_MANAGEMENT_URL ||
                  RouteUrlEditView === VIEW_SCHEME_MANAGEMENT_URL ||
                  RouteUrlEditView === EDIT_SCHEME_MANAGEMENT_URL ||
                  RouteUrl === "/"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i className="bi bi-archive"></i>
                <span>Scheme Management</span>
              </Link>
            )}
            {(selfInfo?.is_admin ||
              selfInfo?.permissions?.includes("add-contact") ||
              selfInfo?.permissions?.includes("update-contact") ||
              selfInfo?.permissions?.includes("delete-contact") ||
              selfInfo?.permissions?.includes("contact-list")) && (
              <Link
                onClick={() => setShowSidebar(false)}
                to={CONTACT_MANAGEMENT_URL}
                className={
                  RouteUrl === CONTACT_MANAGEMENT_URL ||
                  RouteUrl === ADD_CONTACT_MANAGEMENT_URL ||
                  RouteUrlEditView === VIEW_CONTACT_MANAGEMENT_URL ||
                  RouteUrlEditView === EDIT_CONTACT_MANAGEMENT_URL ||
                  RouteUrl === "/"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i className="bi bi-person-rolodex"></i>
                <span>Contact Us</span>
              </Link>
            )}
            {(selfInfo?.is_admin ||
              selfInfo?.permissions?.includes("noti-list") ||
              selfInfo?.permissions?.includes("add-noti") ||
              selfInfo?.permissions?.includes("update-noti") ||
              selfInfo?.permissions?.includes("delete-noti")) && (
              <Link
                onClick={() => setShowSidebar(false)}
                to={NOTI_MANAGEMENT_URL}
                className={
                  RouteUrl === NOTI_MANAGEMENT_URL ||
                  RouteUrl === ADD_NOTI_MANAGEMENT_URL ||
                  RouteUrlEditView === VIEW_NOTI_MANAGEMENT_URL ||
                  RouteUrlEditView === EDIT_NOTI_MANAGEMENT_URL ||
                  RouteUrl === "/"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i className="bi bi-bell"></i>
                <span>Notification Management</span>
              </Link>
            )}
            {(selfInfo?.is_admin ||
              selfInfo?.permissions?.includes("faq-list") ||
              selfInfo?.permissions?.includes("add-faq") ||
              selfInfo?.permissions?.includes("update-faq") ||
              selfInfo?.permissions?.includes("delete-faq")) && (
              <Link
                onClick={() => setShowSidebar(false)}
                to={FAQ_MANAGEMENT_URL}
                className={
                  RouteUrl === FAQ_MANAGEMENT_URL ||
                  RouteUrl === ADD_FAQ_MANAGEMENT_URL ||
                  RouteUrlEditView === VIEW_FAQ_MANAGEMENT_URL ||
                  RouteUrlEditView === EDIT_FAQ_MANAGEMENT_URL ||
                  RouteUrl === "/"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i className="bi bi-patch-question"></i>
                <span>FAQ Management</span>
              </Link>
            )}
          </Nav>
        ) : (
          <Nav defaultActiveKey={DASHBOARD_URL} className="flex-column">
            {(selfInfo?.is_admin ||
              selfInfo?.permissions?.includes("dashboard")) && (
              <Link
                to={DASHBOARD_URL}
                className={
                  RouteUrl === DASHBOARD_URL || RouteUrl === "/"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i className="bi bi-speedometer"></i>
                <span>Dashboard</span>
              </Link>
            )}
            {(selfInfo?.is_admin ||
              selfInfo?.permissions?.includes("user-list") ||
              selfInfo?.permissions?.includes("add-user") ||
              selfInfo?.permissions?.includes("update-user") ||
              selfInfo?.permissions?.includes("delete-user")) && (
              <>
                {selfInfo?.is_admin && (
                  <Link
                    to={USER_MANAGEMENT_URL}
                    className={
                      RouteUrl === USER_MANAGEMENT_URL ||
                      RouteUrl === ADD_USER_URL ||
                      RouteUrlEditView === EDIT_USER_URL ||
                      RouteUrl === "/"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="bi bi-person"></i>
                    <span>User Management</span>
                  </Link>
                )}
              </>
            )}
            {(selfInfo?.is_admin ||
              selfInfo?.permissions?.includes("document-list") ||
              selfInfo?.permissions?.includes("add-document") ||
              selfInfo?.permissions?.includes("update-document") ||
              selfInfo?.permissions?.includes("delete-document")) && (
              <Link
                to={DOCUMENT_MANAGEMENT_URL}
                className={
                  RouteUrl === DOCUMENT_MANAGEMENT_URL ||
                  RouteUrl === ADD_DOCUMENT_MANAGEMENT_URL ||
                  RouteUrlEditView === VIEW_DOCUMENT_MANAGEMENT_URL ||
                  RouteUrlEditView === EDIT_DOCUMENT_MANAGEMENT_URL ||
                  RouteUrl === "/"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i className="bi bi-file-earmark-break"></i>
                <span>Document Management</span>
              </Link>
            )}

            {(selfInfo?.is_admin ||
              selfInfo?.permissions?.includes("add-category") ||
              selfInfo?.permissions?.includes("update-category") ||
              selfInfo?.permissions?.includes("delete-category")) && (
              <Link
                to={CATEGORY_MANAGEMENT_URL}
                className={
                  RouteUrl === CATEGORY_MANAGEMENT_URL || RouteUrl === "/"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i className="bi bi-journals"></i>
                <span>Category Management</span>
              </Link>
            )}

            {(selfInfo?.is_admin ||
              selfInfo?.permissions?.includes("scheme-provider-list") ||
              selfInfo?.permissions?.includes("add-scheme-provider") ||
              selfInfo?.permissions?.includes("update-scheme-provider") ||
              selfInfo?.permissions?.includes("delete-scheme-provider")) && (
              <Link
                to={SCHEME_PROVIDER_MANAGEMENT_URL}
                className={
                  RouteUrl === SCHEME_PROVIDER_MANAGEMENT_URL
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i className="bi bi-gear"></i>
                <span>Scheme Provider Management</span>
              </Link>
            )}
            {(selfInfo?.is_admin ||
              selfInfo?.permissions?.includes("scheme-list") ||
              selfInfo?.permissions?.includes("add-scheme") ||
              selfInfo?.permissions?.includes("update-scheme") ||
              selfInfo?.permissions?.includes("delete-scheme")) && (
              <Link
                to={SCHEME_MANAGEMENT_URL}
                className={
                  RouteUrl === SCHEME_MANAGEMENT_URL ||
                  RouteUrl === ADD_SCHEME_MANAGEMENT_URL ||
                  RouteUrlEditView === VIEW_SCHEME_MANAGEMENT_URL ||
                  RouteUrlEditView === EDIT_SCHEME_MANAGEMENT_URL ||
                  RouteUrl === "/"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i className="bi bi-archive"></i>
                <span>Scheme Management</span>
              </Link>
            )}
            {(selfInfo?.is_admin ||
              selfInfo?.permissions?.includes("add-contact") ||
              selfInfo?.permissions?.includes("update-contact") ||
              selfInfo?.permissions?.includes("delete-contact") ||
              selfInfo?.permissions?.includes("contact-list")) && (
              <Link
                to={CONTACT_MANAGEMENT_URL}
                className={
                  RouteUrl === CONTACT_MANAGEMENT_URL ||
                  RouteUrl === ADD_CONTACT_MANAGEMENT_URL ||
                  RouteUrlEditView === VIEW_CONTACT_MANAGEMENT_URL ||
                  RouteUrlEditView === EDIT_CONTACT_MANAGEMENT_URL ||
                  RouteUrl === "/"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i className="bi bi-person-rolodex"></i>
                <span>Contact Us</span>
              </Link>
            )}
            {(selfInfo?.is_admin ||
              selfInfo?.permissions?.includes("noti-list") ||
              selfInfo?.permissions?.includes("add-noti") ||
              selfInfo?.permissions?.includes("update-noti") ||
              selfInfo?.permissions?.includes("delete-noti")) && (
              <Link
                to={NOTI_MANAGEMENT_URL}
                className={
                  RouteUrl === NOTI_MANAGEMENT_URL ||
                  RouteUrl === ADD_NOTI_MANAGEMENT_URL ||
                  RouteUrlEditView === VIEW_NOTI_MANAGEMENT_URL ||
                  RouteUrlEditView === EDIT_NOTI_MANAGEMENT_URL ||
                  RouteUrl === "/"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i className="bi bi-bell"></i>
                <span>Notification Management</span>
              </Link>
            )}
            {(selfInfo?.is_admin ||
              selfInfo?.permissions?.includes("faq-list") ||
              selfInfo?.permissions?.includes("add-faq") ||
              selfInfo?.permissions?.includes("update-faq") ||
              selfInfo?.permissions?.includes("delete-faq")) && (
              <Link
                to={FAQ_MANAGEMENT_URL}
                className={
                  RouteUrl === FAQ_MANAGEMENT_URL ||
                  RouteUrl === ADD_FAQ_MANAGEMENT_URL ||
                  RouteUrlEditView === VIEW_FAQ_MANAGEMENT_URL ||
                  RouteUrlEditView === EDIT_FAQ_MANAGEMENT_URL ||
                  RouteUrl === "/"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i className="bi bi-patch-question"></i>
                <span>FAQ Management</span>
              </Link>
            )}
          </Nav>
        )}
      </Scrollbars>
    </div>
  );
};

export default Sidebar;
