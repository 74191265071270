/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import DataTableFilter from "../Common/DataTableFilter";
import { Scrollbars } from "react-custom-scrollbars-2";
import CommonToggle from "../Common/CommonToggle";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  ADD_CONTACT_MANAGEMENT_URL,
  VIEW_CONTACT_MANAGEMENT_URL,
  EDIT_CONTACT_MANAGEMENT_URL,
  CONTACT_US_LIST,
} from "../Shared/constant";
import { useDispatch, useSelector } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import CommonPagination from "../Common/CommonPagination";
import CommonModel from "../Common/CommonModel";
import {
  getSupplierAllList,
  getSupplierList,
} from "../../redux/actions/adminActions";

const ContactUs = () => {
  const dispatch = useDispatch();
  const { websocket } = useContext(WebSocketContext);
  const { selfInfo, contactUsList, accessToken } = useSelector(
    (state) => state.adminReducers
  );
  const Navigate = useNavigate();
  const [userSearch, setUserSearch] = useState("");
  const [userType, setUserType] = useState("");
  const [userLimit, setUserLimit] = useState("");
  const [selectedLanguage, setselectedLanguage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState({
    open: false,
    title: "",
    modalType: "",
    Data: {},
  });

  // page change
  const currentFunction = (page) => {
    setCurrentPage(page);
  };

  // clear search box
  const ClearSearchFiled = () => {
    setUserSearch("");
  };

  // select user type
  const SelectUserTypeFnct = (e) => {
    setUserType(e.target.value);
    setCurrentPage(1);
  };
  // select user language
  const SelectUserLangTypeFnct = (e) => {
    setselectedLanguage(e.target.value);
    setCurrentPage(1);
  };

  const GetCONTACTSAPI = async (accessToken) => {
    try {
      const response = await window.axios.get(
        `${CONTACT_US_LIST}/${selectedLanguage || "all"}/${userLimit || "10"}/${
          currentPage || "1"
        }/${userSearch || 0}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      const data = {
        ...response,
        status: 200,
      };
      return data;
    } catch (error) {
      const errorData = {
        ...error.response?.data,
        status: error.response?.status,
      };
      return errorData;
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      const contactResponse = await GetCONTACTSAPI(accessToken);
      if (contactResponse.status === 200) {
        dispatch(getSupplierAllList(contactResponse));
        dispatch(getSupplierList(contactResponse));
      } else {
        // Handle error case here
        // You might want to dispatch an action for error handling
      }
    };

    fetchData();
  }, [
    userSearch,
    userType,
    userLimit,
    currentPage,
    accessToken,
    selectedLanguage,
  ]);
  return (
    <section className="dashboard_wrapper">
      <DataTableFilter
        filterType={"Contact"}
        tooltip="user name or phone"
        userSearch={userSearch}
        setUserSearch={setUserSearch}
        // userType={userType}
        // setUserType={setUserType}
        languageType={selectedLanguage}
        setselectedLanguage={setselectedLanguage}
        SelectUserLangTypeFnct={SelectUserLangTypeFnct}
        userLimit={userLimit}
        setUserLimit={setUserLimit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        ClearSearchFiled={ClearSearchFiled}
        SelectUserTypeFnct={SelectUserTypeFnct}
      />
      <div
        className="tableContent"
        style={{
          height:
            window.screen.width > 540
              ? "calc(100vh - 155px)"
              : "calc(100vh - 143px)",
        }}
      >
        <Scrollbars
          style={{
            height:
            parseInt(contactUsList?.pagination?.total_records) > 5
                ? window.screen.width < 767
                  ? "calc(100% - 10px)"
                  : "calc(100% - 38px)"
                : "calc(100% - 0px)",
          }}
          className="ScrollbarsWrapper"
          renderView={(props) => <div {...props} className="view" />}
        >
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Language</th>
                  <th>Message</th>
                  <th>Phone</th>
                  <th>Created At</th>
                </tr>
              </thead>
              <tbody>
                {contactUsList &&
                  contactUsList?.list?.map((user, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {user?.user_name === null ? "-" : user?.user_name}
                        </td>
                        <td>
                          {user?.language === "" || user?.language === null
                            ? "-"
                            : user?.language}
                        </td>
                        <td
                          style={{
                            maxWidth: "200px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {user?.message === "" || user?.message === null
                            ? "-"
                            : user?.message}
                        </td>

                        <td>
                          {user?.phone === null || user?.phone === ""
                            ? "-"
                            : "+" + user?.phone_code + "-" + user?.phone}
                        </td>
                        <td>{moment(user.created_at).format("LL")}</td>
                      </tr>
                    );
                  })}
                {contactUsList && contactUsList?.list.length === 0 && (
                  <tr>
                    <td style={{ textAlign: "center" }} colSpan={8}>
                      Contacts Not Found !
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Scrollbars>
        {/* pagination */}
        {parseInt(contactUsList?.pagination?.total_records) > 5 && (
          <CommonPagination
            currentPage={currentPage}
            paginationLength={contactUsList?.pagination}
            currentFunction={currentFunction}
          />
        )}
      </div>
      {showModal?.open && (
        <CommonModel
          setUserSearch={setUserSearch}
          setCurrentPage={setCurrentPage}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </section>
  );
};

export default ContactUs;
