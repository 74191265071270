/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import {
  DASHBOARD_URL,
  
  CONTACT_MANAGEMENT_URL,
  SCHEME_MANAGEMENT_URL,
  USER_MANAGEMENT_URL,
  Locations,
} from "../Shared/constant";
import BackButton from "../Common/BackButton";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import CheckValid from "../Common/CheckValid";
import InputField from "../Common/InputField";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useNavigate } from "react-router";

const Profile = () => {
  const Navigate = useNavigate();
  const { selfInfo, device_id } = useSelector((state) => state.adminReducers);
  const [inputValue, setInputValue] = useState({
    Fname: "",
    Lname: "",
    Old_password: "",
    Password: "",
    age: "",
    gender: "",
    state: "",
  });
  const { Fname, Lname, Old_password, Password, age, gender, state } =
    inputValue;
  const [showpassword, setshowpassword] = useState(false);
  const [showOldpassword, setshowOldpassword] = useState(false);
  const [ChangePasswordState, setChangePasswordState] = useState(false);
  const { websocket } = useContext(WebSocketContext);

  // error message
  const [errorFname, setErrorFname] = useState("");
  const [errorAge, setErrorAge] = useState("");
  const [errorState, setErrorState] = useState("");
  const [errorGender, setErrorGender] = useState("");
  const [errorLname, setErrorLname] = useState("");

  const [errorOldPassword, setErrorOldPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState("");

  const PasswordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/; // eslint-disable-line

  useEffect(() => {
    if (ChangePasswordState === true) {
      const MainWrapsId = document.getElementById("chnagepasswordblog");
      MainWrapsId.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [ChangePasswordState]);

  useEffect(() => {
    if (selfInfo) {
      setInputValue({
        ...inputValue,
        Fname: selfInfo?.first_name,
        Lname: selfInfo?.last_name,
        age: selfInfo?.age,
        gender: selfInfo?.gender,
        state: selfInfo?.state,
      });
    }
  }, [selfInfo]);

  // Textfield common onchange function
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // show password
  const ShowHidePassword = () => {
    setshowpassword(!showpassword);
  };

  // show password
  const ShowOldHidePassword = () => {
    setshowOldpassword(!showOldpassword);
  };

  const RoutUrlSet = () => {
    if (selfInfo?.is_admin || selfInfo?.permissions?.includes("dashboard")) {
      return DASHBOARD_URL;
    } else {
      if (selfInfo?.permissions !== null) {
        if (
          selfInfo?.permissions?.split(",")[0] === "user-list" ||
          selfInfo?.permissions?.split(",")[0] === "add-user" ||
          selfInfo?.permissions?.split(",")[0] === "update-user" ||
          selfInfo?.permissions?.split(",")[0] === "delete-user"
        ) {
          return USER_MANAGEMENT_URL;
        } else if (
          selfInfo?.permissions?.split(",")[0] === "product-list" ||
          selfInfo?.permissions?.split(",")[0] === "add-product" ||
          selfInfo?.permissions?.split(",")[0] === "update-product" ||
          selfInfo?.permissions?.split(",")[0] === "delete-product"
        ) {
          return SCHEME_MANAGEMENT_URL;
        } else if (
          selfInfo?.permissions?.split(",")[0] === "add-supplier" ||
          selfInfo?.permissions?.split(",")[0] === "update-supplier" ||
          selfInfo?.permissions?.split(",")[0] === "delete-supplier" ||
          selfInfo?.permissions?.split(",")[0] === "supplier-list"
        ) {
          return CONTACT_MANAGEMENT_URL;
        } 
      }
    }
  };

  // edit profile
  const EditProfile = () => {
    if (
      inputValue?.Fname !== "" ||
      inputValue?.Lname !== "" ) {
      let param = {
        transmit: "broadcast",
        url: "admin_profile_update",
        request: {
          first_name: inputValue?.Fname,
          last_name: inputValue?.Lname,
          DeviceId: device_id,
          age: inputValue?.age,
          state: inputValue?.state,
          gender: inputValue?.gender,
          phone:selfInfo?.phone || null,
          phone_code:selfInfo?.phone_code || null
        },
      };
        wsSend_request(websocket, param);
      setInputValue({
        ...inputValue,
        Fname: "",
        Lname: "",
      });
      setTimeout(() => Navigate(RoutUrlSet()), 200);
    } else {
      CheckValid(inputValue?.Fname, { type: "Fname", error: setErrorFname });
      CheckValid(inputValue?.Lname, { type: "Lname", error: setErrorLname });
      CheckValid(inputValue?.age, { type: "age", error: setErrorLname });
      CheckValid(inputValue?.gender, { type: "gender", error: setErrorLname });
      CheckValid(inputValue?.state, { type: "state", error: setErrorLname });
    }
  };

  // change password api
  const EditProfilePassword = () => {
    if (inputValue?.Old_password !== "" && inputValue?.Password !== "") {
      if (
        PasswordRegex.test(inputValue?.Old_password) === true &&
        PasswordRegex.test(inputValue?.Password) === true
      ) {
        if (inputValue?.Old_password !== inputValue?.Password) {
          let param = {
            transmit: "broadcast",
            url: "change_password",
            request: {
              old_password: inputValue?.Old_password,
              new_password: inputValue?.Password,
              DeviceId: device_id,
              // first_name: inputValue?.Fname,
              // last_name: inputValue?.Lname,
              // DeviceId: device_id,
              // age: inputValue?.age,
              // state: inputValue?.state,
              // gender: inputValue?.gender,
              // phone:selfInfo?.phone,
              // phone_code:selfInfo?.phone_code
            },
          };
          wsSend_request(websocket, param);
          setInputValue({
            ...inputValue,
            Old_password: "",
            Password: "",
          });
          setTimeout(() => Navigate(DASHBOARD_URL), 200);
        } else {
          if (inputValue?.Old_password === inputValue?.Password) {
            setErrorPassword("Old Password and new password cannot be same.");
          } else {
            setErrorPassword("");
          }
        }
      } else {
        CheckValid(inputValue?.Old_password, {
          type: "oldpassword",
          error: setErrorOldPassword,
        });
        CheckValid(inputValue?.Password, {
          type: "newpassword",
          error: setErrorPassword,
        });
      }
    } else {
      CheckValid(inputValue?.Old_password, {
        type: "oldpassword",
        error: setErrorOldPassword,
      });
      CheckValid(inputValue?.Password, {
        type: "newpassword",
        error: setErrorPassword,
      });
    }
  };
  const locationsArray = Object.entries(Locations);
  return (
    <section className="dashboard_wrapper">
      <BackButton targeturl={RoutUrlSet()} title={"Back"} />
      <Scrollbars
        style={{ height: "calc(100% - 53px)" }}
        className="ScrollbarsWrapper"
        renderView={(props) => <div {...props} className="view" />}
      >
        <div className="form-sections">
          <div className="row">
            <div className="col-md-6">
              <InputField
                type="text"
                value={Fname}
                className={
                  errorFname !== "" ? "form-control error-form" : "form-control"
                }
                placeholder="Enter First Name"
                label="First Name"
                name="Fname"
                onChange={handleChange}
                onKeyUp={(e) =>
                  CheckValid(e.target.value, {
                    type: "Fname",
                    error: setErrorFname,
                  })
                }
                onKeyDown={EmptySpaceFieldValid}
                errormessage={errorFname}
              />
            </div>
            <div className="col-md-6">
              <InputField
                type="text"
                value={Lname}
                className={
                  errorLname !== "" ? "form-control error-form" : "form-control"
                }
                placeholder="Enter Last Name"
                label="Last Name"
                name="Lname"
                onChange={handleChange}
                onKeyUp={(e) =>
                  CheckValid(e.target.value, {
                    type: "Lname",
                    error: setErrorLname,
                  })
                }
                onKeyDown={EmptySpaceFieldValid}
                errormessage={errorLname}
              />
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label>Select Gender</label>
                <select
                  className="form-control activestatusselet"
                  name="gender"
                  value={gender}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label>Select State</label>
                <select
                  className="form-control activestatusselet"
                  name="state"
                  value={state}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                >
                  <option value="">Select a state</option>
                  {locationsArray.map(
                    ([stateName, stateAbbreviation], index) => (
                      <option value={stateName} key={index}>
                        {stateName}
                      </option>
                    )
                  )}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <InputField
                type="number"
                value={age}
                className={
                  errorAge !== "" ? "form-control error-form" : "form-control"
                }
                placeholder="Enter Age"
                label="Age"
                name="age"
                onChange={handleChange}
                onKeyUp={(e) =>
                  CheckValid(e.target.value, {
                    type: "age",
                    error: setErrorAge,
                  })
                }
                onKeyDown={EmptySpaceFieldValid}
                errormessage={errorAge}
              />
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Email</label>
                <div className="inputText">
                  {selfInfo?.email === null ? "-" : selfInfo?.email}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Mobile No</label>
                <div className="inputText">
                  +{selfInfo?.phone_code === null ? "1" : selfInfo?.phone_code}{" "}
                  &nbsp; {selfInfo?.phone === null ? "-" : selfInfo?.phone}
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group-btn" style={{ textAlign: "right" }}>
                <button
                  type="button"
                  className="btn addbtncmn"
                  onClick={() => EditProfile()}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="form-sections">
          <h4 className="headecenter">Change Password</h4>
          <div className="row">
            <div className="col-md-6">
              <InputField
                type={showOldpassword ? "text" : "password"}
                value={Old_password}
                className={
                  errorOldPassword !== ""
                    ? "form-control error-form"
                    : "form-control"
                }
                placeholder="Enter Old password"
                label="Enter Old Password"
                name="Old_password"
                onChange={handleChange}
                onKeyUp={(e) =>
                  CheckValid(e.target.value, {
                    type: "oldpassword",
                    error: setErrorOldPassword,
                  })
                }
                onKeyDown={EmptySpaceFieldValid}
                errormessage={errorOldPassword}
                onClick={ShowOldHidePassword}
                showOldpassword={showOldpassword}
              />
            </div>
            <div className="col-md-6">
              <InputField
                type={showpassword ? "text" : "password"}
                value={Password}
                className={
                  errorPassword !== ""
                    ? "form-control error-form"
                    : "form-control"
                }
                placeholder="Enter New password"
                label="Enter New Password"
                name="Password"
                onChange={handleChange}
                onKeyUp={(e) =>
                  CheckValid(e.target.value, {
                    type: "newpassword",
                    error: setErrorPassword,
                  })
                }
                onKeyDown={EmptySpaceFieldValid}
                errormessage={errorPassword}
                onClick={ShowHidePassword}
                showpassword={showpassword}
              />
            </div>
            <div className="col-md-12">
              <div className="form-group-btn" style={{ textAlign: "right" }}>
                <button
                  type="button"
                  className="btn addbtncmn"
                  onClick={() => EditProfilePassword()}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </Scrollbars>
    </section>
  );
};

export default Profile;
