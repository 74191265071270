/* eslint-disable */
import React, { useState } from "react";
import EmptySpaceFieldValid from "./EmptySpaceFieldValid";
import ExportIcon from "../../assets/img/export_icon.png";
import { useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const DataTableFilter = (props) => {
  const { languageList } = useSelector((state) => state.adminReducers);

  const {
    filterType,
    buttonvisible,
    userSearch,
    tooltip,
    ButtonSet,
    setUserSearch,
    userType,
    languageType,
    userLimit,
    setUserLimit,
    ClearSearchFiled,
    SelectUserTypeFnct,
    SelectUserLangTypeFnct,
  } = props;
  const [ResponsiveSearchBox, setResponsiveSearchBox] = useState(false);

  return (
    <div className="card-header-New mt-3">
      
      {window.screen.width > 700 ? (
        <React.Fragment>
          {userSearch !== undefined && (
            <>
           
            <div className="searchBoxwithbtn">
            <div className="text-right">
             <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip
                      id="button-tooltip-2"
                      style={{ position: "fixed" }}
                    >
                     {tooltip}
                    </Tooltip>
                  }
                >
                  <i className="bi bi-info-circle-fill position-absolute" style={{top:"-1.6rem"}}></i>
                </OverlayTrigger>
                </div>
              <input
                type="text"
                className="form-control"
                value={userSearch}
                onKeyDown={(e) => EmptySpaceFieldValid(e)}
                onChange={(e) => setUserSearch(e.target.value)}
                placeholder={`Search ${filterType} ...`}
              />
              {userSearch === "" ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-search"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                  <path d="M21 21l-6 -6"></path>
                </svg>
              ) : (
                <i
                  className="bi bi-x-circle-fill searchclear"
                  onClick={() => ClearSearchFiled()}
                ></i>
              )}
            </div>
            </>

          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="responsiveSearchBox">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setResponsiveSearchBox(!ResponsiveSearchBox)}
              className="icon icon-tabler icon-tabler-search icon-tabler-search-respo"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
              <path d="M21 21l-6 -6"></path>
            </svg>
            {ResponsiveSearchBox && (
              <div className="responsiSearcFiled">
                <div className="searchBoxwithbtn">
                  <input
                    type="text"
                    className="form-control"
                    value={userSearch}
                    onKeyDown={(e) => EmptySpaceFieldValid(e)}
                    onChange={(e) => setUserSearch(e.target.value)}
                    placeholder={`Search ${filterType} ...`}
                  />
                  {userSearch === "" ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-search"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                      <path d="M21 21l-6 -6"></path>
                    </svg>
                  ) : (
                    <i
                      className="bi bi-x-circle-fill searchclear"
                      onClick={() => ClearSearchFiled()}
                    ></i>
                  )}
                </div>
              </div>
            )}
          </div>
        </React.Fragment>
      )}
<div className="row card-header-right w-auto ">
  <div className="d-flex">
    {languageType !== undefined && (
      <select
        className="form-control activestatusselet w-auto "
        value={languageType}
        onChange={(e) => SelectUserLangTypeFnct(e)}
      >
        <option value="All" selected>
          Select Language
        </option>
        <option value="all">All</option>
        {languageList &&
          languageList.map((item, index) => (
            <option key={index} value={item}>
              {item}
            </option>
          ))}
      </select>
    )}
    {userType !== undefined && (
      <select
        className="form-control activestatusselet w-auto"
        value={userType}
        onChange={(e) => SelectUserTypeFnct(e)}
      >
        <option value="All">Select Status</option>
        <option value="all">All</option>
        <option value="active">Active</option>
        <option value="inactive">Inactive</option>
      </select>
    )}

    <select
      className="form-control userLimitselect w-auto"
      value={userLimit || 5}
      onChange={(e) => setUserLimit(parseInt(e.target.value))}
    >
      <option value="5" selected>Item Per Page</option>
      <option value="10">10</option>
      <option value="20">20</option>
      <option value="30">30</option>
      <option value="40">40</option>
      <option value="50">50</option>
      <option value="60">60</option>
      <option value="70">70</option>
      <option value="80">80</option>
      <option value="90">90</option>
      <option value="100">100</option>
    </select>

    {buttonvisible && (
      <button className="btn head_cmn_btn add-button-data-table" onClick={ButtonSet?.function}>
        <i className="bi bi-plus-circle"></i>
        {ButtonSet?.buttontitle}
      </button>
    )}
  </div>
</div>

    </div>
  );
};

export default DataTableFilter;
