import {
  parsePhoneNumber,
  getRegionCodeForCountryCode,
} from "awesome-phonenumber";

// empty space validation fucntion
const CheckValid = (value, states) => {
  const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line
  const PasswordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/; // eslint-disable-line

  // email validation
  if (states?.type === "email") {
    if (value !== "") {
      if (EmailRegex.test(value) === true) {
        return states?.error("");
      } else {
        if (EmailRegex.test(value) === false) {
          return states?.error("Please enter valid email address");
        } else {
          return states?.error("");
        }
      }
    } else {
      if (value === "") {
        return states?.error("Email address cannot be empty");
      } else if (EmailRegex.test(value) === false) {
        return states?.error("Please enter valid email address");
      } else {
        return states?.error("");
      }
    }
  }

  // password validation
  if (states?.type === "password") {
    if (value !== "") {
      if (PasswordRegex.test(value) === true) {
        return states?.error("");
      } else {
        if (PasswordRegex.test(value) === false) {
          return states?.error(
            "Passwords must contain at least one number, one uppercase and one lowercase letter, and at least 8 or more characters."
          );
        } else {
          return states?.error("");
        }
      }
    } else {
      if (value === "") {
        return states?.error("Password cannot be empty");
      } else if (PasswordRegex.test(value) === false) {
        return states?.error(
          "Passwords must contain at least one number, one uppercase and one lowercase letter, and at least 8 or more characters."
        );
      } else {
        return states?.error("");
      }
    }
  }

  // old password validation
  if (states?.type === "oldpassword") {
    if (value !== "") {
      if (PasswordRegex.test(value) === true) {
        return states?.error("");
      } else {
        if (PasswordRegex.test(value) === false) {
          return states?.error(
            "Passwords must contain at least one number, one uppercase and one lowercase letter, and at least 8 or more characters."
          );
        } else {
          return states?.error("");
        }
      }
    } else {
      if (value === "") {
        return states?.error("Old Password cannot be empty");
      } else if (PasswordRegex.test(value) === false) {
        return states?.error(
          "Passwords must contain at least one number, one uppercase and one lowercase letter, and at least 8 or more characters."
        );
      } else {
        return states?.error("");
      }
    }
  }

  // new password validation
  if (states?.type === "newpassword") {
    if (value !== "") {
      if (PasswordRegex.test(value) === true) {
        return states?.error("");
      } else {
        if (PasswordRegex.test(value) === false) {
          return states?.error(
            "Passwords must contain at least one number, one uppercase and one lowercase letter, and at least 8 or more characters."
          );
        } else {
          return states?.error("");
        }
      }
    } else {
      if (value === "") {
        return states?.error("New Password cannot be empty");
      } else if (PasswordRegex.test(value) === false) {
        return states?.error(
          "Passwords must contain at least one number, one uppercase and one lowercase letter, and at least 8 or more characters."
        );
      } else {
        return states?.error("");
      }
    }
  }
    //  description validation
    if (states?.type === "description") {
      if (value !== "") {
        return states?.error("");
      } else {
        return states?.error("Description cannot be empty");
      }
    }
       //  language validation
       if (states?.type === "language") {
        if (value !== "") {
          return states?.error("");
        } else {
          return states?.error("Language cannot be empty");
        }
      }
  //  name validation
  if (states?.type === "name") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("First name cannot be empty");
    }
  }
  // first name validation
  if (states?.type === "Fname") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("First name cannot be empty");
    }
  }
    // Question validation
    if (states?.type === "question") {
      if (value !== "") {
        return states?.error("");
      } else {
        return states?.error("Question cannot be empty");
      }
    }
     // Answer validation
     if (states?.type === "answer") {
      if (value !== "") {
        return states?.error("");
      } else {
        return states?.error("Answer cannot be empty");
      }
    }
    // age validation
    if (states?.type === "age") {
      if (value !== "") {
        return states?.error("");
      } else {
        return states?.error("age cannot be empty");
      }
    }
     // state validation
     if (states?.type === "state") {
      if (value !== "") {
        return states?.error("");
      } else {
        return states?.error("state cannot be empty");
      }
    }
     // gender validation
     if (states?.type === "gender") {
      if (value !== "") {
        return states?.error("");
      } else {
        return states?.error("gender cannot be empty");
      }
    }

  // last name validation
  if (states?.type === "Lname") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Last name cannot be empty");
    }
  }
    // Language validation
    if (states?.type === "language") {
      if (value !== "") {
        return states?.error("");
      } else {
        return states?.error("Language cannot be empty");
      }
    }



  // title validation
  if (states?.type === "Title") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Title cannot be empty");
    }
  }


  // Address validation
  if (states?.type === "Address") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Address cannot be empty");
    }
  }

  // phone validation
  if (states?.type === "phone") {
    const CountryStates = getRegionCodeForCountryCode(value?.phone_code);
    const CheckMobileNumber = parsePhoneNumber(value?.phone?.toString(), {
      regionCode: CountryStates,
    });
    if (value?.phone !== "") {
      if (CheckMobileNumber?.valid === true) {
        return states?.error("");
      } else {
        return states?.error(`Please enter valid region mobile number`);
      }
    } else {
      if (value?.phone === "") {
        return states?.error("Mobile no cannot be empty");
      } else if (CheckMobileNumber?.valid === false) {
        return states?.error("please enter valid region mobile number");
      } else {
        return states?.error("");
      }
    }
  }

  // Product Name validation
  if (states?.type === "scheme_name") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Scheme name cannot be empty");
    }
  }

  // eligibility validation
  if (states?.type === "eligibility") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("eligibility cannot be empty");
    }
  }
  // benefits validation
  if (states?.type === "benefits") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("benefits cannot be empty");
    }
  }
  // description validation
  if (states?.type === "description_en" || states?.type === "description_hn") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("description cannot be empty");
    }
  }
  // Product Name validation
  if (states?.type === "Model") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Model cannot be empty");
    }
  }



  // Unit const price validation
  if (states?.type === "Description") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Description cannot be empty");
    }
  }



  // permission validation
  if (states?.type === "permission") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Please select permisssion");
    }
  }



  // model name validation
  if (states?.type === "Model Name") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Model name cannot be empty");
    }
  }

  // Company name validation
  if (states?.type === "CompanyName") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Company name cannot be empty");
    }
  }

  // VATRegistrationNo name validation
  if (states?.type === "VATRegistrationNo") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("VAT registration no cannot be empty");
    }
  }

  // RegistrationNo name validation
  if (states?.type === "RegistrationNo") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Registration no cannot be empty");
    }
  }

  // Amount validation
  if (states?.type === "Amount") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Amount cannot be empty");
    }
  }

  // InCoterm validation
  if (states?.type === "InCoterm") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("In-Coterm cannot be empty");
    }
  }

  // Quantity validation
  if (states?.type === "Quantity") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Quantity cannot be empty");
    }
  }

  // ItemNumber validation
  if (states?.type === "ItemNumber") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Item number cannot be empty");
    }
  }

  // PI number validation
  if (states?.type === "PInumber") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("PI-number cannot be empty");
    }
  }

  // HSCode validation
  if (states?.type === "HSCode") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("HS-code cannot be empty");
    }
  }

  // PcCode validation
  if (states?.type === "PcCode") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("PcCode cannot be empty");
    }
  }

  // Category Name validation
  if (states?.type === "Category Name") {
    if (value !== "") {

      return states?.error("");
    } else {

      return states?.error("Category Name cannot be empty");
    }
  }
  // Scheme Provider Name validation
  if (states?.type === "Scheme Provider Name") {
    if (value !== "") {

      return states?.error("");
    } else {

      return states?.error("Scheme Provider Name cannot be empty");
    }
  }

  // Provider name validation
  if (states?.type === "Provider Name") {
    if (value !== "") {
      return states?.error("");
    } else {
      return states?.error("Provider name cannot be empty");
    }
  }
};

export default CheckValid;
