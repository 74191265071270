import axios from "axios";

export default function PostRequestCallAPI(url, payload, accessToken) {
  console.log("postrequestcall",url, payload, accessToken)
  const headers = {
    'Authorization': `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };

  return axios.post(url, payload, { headers })
    .then(response => {
      if (response?.status === 200) {
        return response?.data;
      } else {
        throw new Error(`Request failed with status: ${response?.status}`);
      }
    })
    .catch(error => {
      console.error('Request error:', error);
      // Log the response data for debugging purposes
      if (error.response) {
        console.error('Response data:', error?.response?.data);
      }
      throw error;
    });
}
