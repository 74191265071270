/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import CommonToggle from "../Common/CommonToggle";
import { useNavigate } from "react-router-dom";
import {
  ADD_SCHEME_MANAGEMENT_URL,
  VIEW_SCHEME_MANAGEMENT_URL,
  EDIT_SCHEME_MANAGEMENT_URL,
} from "../Shared/constant";
import AvatarGet from "../Common/AvatarGet";
import { useSelector, useDispatch } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import CommonPagination from "../Common/CommonPagination";
import { ClearFormSetFutios } from "../../redux/actions/adminActions";
import CommonewModel from "../Common/CommonewModel";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";

const SchemeMangement = () => {
  const { websocket } = useContext(WebSocketContext);
  const {
    selfInfo,
    schemeList,
    languageList,
    device_id,
    categoryList,
    categoryAllList,
    schemeProviderAllList,
  } = useSelector((state) => state.adminReducers);
  const Navigate = useNavigate();
  const Dispatch = useDispatch();
  const [userSearch, setUserSearch] = useState("");
  const [userType, setUserType] = useState("");
  const [userLimit, setUserLimit] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showModalNew, setShowModalNew] = useState({
    open: false,
    title: "",
    modalType: "",
    Data: {},
  });

  const [GetFilterCategoryIds, setGetFilterCategoryIds] = useState("");
  const [GetFilterSelectedLanguage, setGetFilterSelectedLanguage] =
    useState("");
  const [GetFilterProvider, setGetFilterProvider] = useState("");
  const filteredProviderID = schemeProviderAllList.find(
    (elm) => GetFilterProvider === elm?.name
  );
  const ProviderNameGet = (id) => {
    if (id) {
      const providerIds = id.split(",");

      const providerNames = providerIds.map((providerId) => {
        const provider = schemeProviderAllList.find(
          (elm) => providerId === elm?.id
        );
        return provider ? provider.name : null;
      });

      return providerNames.join(", ");
    }
  };

  // model name get
  const CategoryNameGet = (model_id) => {
    return categoryList?.list?.filter((elm) => model_id === elm?.id)[0]?.name;
  };
  const getProviderID = schemeProviderAllList.filter(
    (ele) => GetFilterProvider === ele?.name
  );
  // page change
  const currentFunction = (page) => {
    setCurrentPage(page);
  };

  // clear search box
  const ClearSearchFiled = () => {
    setUserSearch("");
  };

  // select user type
  const SelectUserTypeFnct = (e) => {
    setUserType(e.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    Dispatch(
      ClearFormSetFutios({
        url: "",
        action: false,
      })
    );
  }, []);

  useEffect(() => {
    let param = {
      transmit: "broadcast",
      url: "scheme_category_list",
      request: {
        filter: "all",
        limit: 5000,
        page_no: 1,
        search: "",
        type: "admin",
      DeviceId: device_id,

      },
      
    };
    wsSend_request(websocket, param);
  }, []);
  useEffect(() => {
    let param = {
      transmit: "broadcast",
      url: "scheme_provider_list",
      request: {
        filter: "all",
        limit: 5000,
        page_no: 1,
        search: "",
        type: "admin",
      DeviceId: device_id,

      },
      
    };
    wsSend_request(websocket, param);
  }, []);

  useEffect(() => {
    let paramRequest = {
      filter: userType || "all",
      language: GetFilterSelectedLanguage,
      category_id: GetFilterCategoryIds || null,
      limit: userLimit || 5,
      page_no: currentPage || 1,
      search: userSearch.trim(),
      DeviceId: device_id,
      provider_id: filteredProviderID?.id || null,
      type: "admin",
    };
    const paramfilter = Object.entries(paramRequest).filter((elm) => {
      if (elm[1] !== "") {
        return elm;
      }
    });
    let param = {
      transmit: "broadcast",
      url: "scheme_list",
      request: Object.fromEntries(paramfilter),
    };
    wsSend_request(websocket, param);
  }, [
    userSearch,
    userType,
    userLimit,
    currentPage,
    GetFilterSelectedLanguage,
    GetFilterCategoryIds,
    GetFilterProvider,
  ]);

  const ResetFnct = () => {
    setUserSearch("");
    setUserType("");
    setUserLimit("");
    setCurrentPage(1);
    setGetFilterCategoryIds("");
    setGetFilterSelectedLanguage("");
    setGetFilterProvider("");
  };

  // Delete Scheme
  const DeleteProductFnct = (data_) => {
    setShowModalNew({
      ...showModalNew,
      open: !showModalNew?.open,
      title: "Delete Scheme",
      subtitle: "Delete Scheme",
      description: `Do you really want to delete <h5>${data_?.name}</h5>`,
      modalType: "not form",
      button: "Yes",
      Data: data_,
    });
  };

  const ProductActive = (data_, type, booleancheck) => {
    setShowModalNew({
      ...showModalNew,
      open: !showModalNew?.open,
      title: "Are you sure?",
      subtitle: "Active Scheme",
      description: `Do you really want to ${booleancheck ? "remove " : ""} ${
        type.split("_")[1]
      } to <h5>${data_?.name}</h5>`,
      modalType: "not form",
      button: "Yes",
      Data: data_,
    });
  };
  // add new vendor
  const AddVendorFnct = () => {
    Navigate(ADD_SCHEME_MANAGEMENT_URL);
  };
  return (
    <section className="dashboard_wrapper">
      <div className="filter_sectio">
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <label>
                Search
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip
                      id="button-tooltip-2"
                      style={{ position: "fixed" }}
                    >
                      name
                    </Tooltip>
                  }
                >
                  <i className="bi bi-info-circle-fill"></i>
                </OverlayTrigger>
              </label>
              <React.Fragment>
                {userSearch !== undefined && (
                  <div className="searchBoxwithbtn" style={{ width: "100%" }}>
                    <input
                      type="text"
                      className="form-control"
                      value={userSearch}
                      onChange={(e) => setUserSearch(e.target.value)}
                      placeholder={`Search Scheme...`}
                    />
                    {userSearch === "" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-search"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                        <path d="M21 21l-6 -6"></path>
                      </svg>
                    ) : (
                      <i
                        className="bi bi-x-circle-fill searchclear"
                        onClick={() => ClearSearchFiled()}
                      ></i>
                    )}
                  </div>
                )}
              </React.Fragment>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>Language</label>
              <select
                className="form-control activestatusselet"
                value={GetFilterSelectedLanguage}
                onChange={(e) => setGetFilterSelectedLanguage(e.target.value)}
                style={{ width: "100%" }}
              >
                <option value="All" selected>
                  Select Language
                </option>
                <option value="all">All</option>
                {languageList.map((elm, index) => {
                  return (
                    <option value={elm} key={index}>
                      {elm}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>Category</label>
              <select
                className="form-control activestatusselet"
                value={GetFilterCategoryIds}
                onChange={(e) => setGetFilterCategoryIds(e.target.value)}
                style={{ width: "100%" }}
              >
                <option value="all">Select Category</option>
                {categoryAllList.map((elm, index) => {
                  return (
                    <option value={elm.id} key={index}>
                      {elm.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              <label>Provider</label>
              <select
                className="form-control activestatusselet"
                value={GetFilterProvider}
                onChange={(e) => setGetFilterProvider(e.target.value)}
                style={{ width: "100%" }}
              >
                <option value="all">Select Scheme Provider</option>
                {schemeProviderAllList.map((elm, index) => {
                  return (
                    <option value={elm?.name} key={index}>
                      {elm?.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div className="col-md-2">
            <div className="form-group">
              <label>Status</label>
              <select
                className="form-control activestatusselet"
                style={{ width: "100%" }}
                value={userType}
                onChange={(e) => SelectUserTypeFnct(e)}
              >
                <option value="All" selected>
                  Select Status
                </option>

                <option value="all">All</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </div>
          </div>
          <div className="col-md-1">
            <div className="form-group">
              <label>Limit</label>
              <select
                className="form-control userLimitselect"
                style={{ width: "100%" }}
                value={userLimit}
                onChange={(e) => setUserLimit(parseInt(e.target.value))}
              >
                <option value="5">Item Per Page</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
                <option value="60">60</option>
                <option value="70">70</option>
                <option value="80">80</option>
                <option value="90">90</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>
          <div
            className="col-md-2"
            style={{ width: "auto", paddingRight: "0px" }}
          >
            <div className="form-group">
              <label>&nbsp;</label>
              <button
                className="btn head_reset_btn"
                style={{ marginLeft: "0px" }}
                onClick={() => ResetFnct()}
              >
                <i className="bi bi-x-circle"></i>
                Reset
              </button>
            </div>
          </div>
          <div className="col-md-2" style={{ width: "auto" }}>
            <div className="form-group">
              <label>&nbsp;</label>
              <button
                className="btn head_cmn_btn"
                style={{ marginLeft: "0px" }}
                onClick={() => AddVendorFnct()}
              >
                <i className="bi bi-plus-circle"></i>
                Add Scheme
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="tableContent"
        style={{
          height:
            window.screen.width > 540
              ? "calc(100vh - 255px)"
              : "calc(100vh - 143px)",
        }}
      >
        <Scrollbars
          style={{
            height:
            parseInt(schemeList?.pagination?.total_records) > 5
                ? window.screen.width < 767
                  ? "calc(100% - 10px)"
                  : "calc(100% - 38px)"
                : "calc(100% - 0px)",
          }}
          className="ScrollbarsWrapper"
          renderView={(props) => <div {...props} className="view" />}
        >
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Scheme Name</th>
                  <th>Language</th>
                  <th>Picture </th>
                  <th>Scheme Category</th>
                  <th>Scheme Provider</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Active</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {schemeList &&
                  schemeList?.list?.map((user, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td>{user?.name}</td>
                          <td>{user?.language}</td>

                          <td>
                            <AvatarGet
                              type="product"
                              url={user?.view_thumbnail_url}
                            />
                          </td>
                          <td>
                            {CategoryNameGet(user?.category) === null
                              ? "-"
                              : CategoryNameGet(user?.category)}
                          </td>
                          <td>
                            {ProviderNameGet(user?.provider) === null
                              ? "-"
                              : ProviderNameGet(user?.provider)}
                          </td>
                          <td>
                            {user?.start_date
                              ? moment(user?.start_date).format("LL")
                              : "-"}
                          </td>
                          <td>
                            {user?.end_date
                              ? moment(user?.end_date).format("LL")
                              : "-"}
                          </td>

                          <td>
                            {(selfInfo?.is_admin ||
                              selfInfo?.permissions?.includes(
                                "update-product"
                              )) && (
                              <CommonToggle
                                valueToggle={user?.is_active}
                                setValueToggle={() =>
                                  ProductActive(
                                    user,
                                    "is_active",
                                    user?.is_active
                                  )
                                }
                                name={""}
                              />
                            )}
                          </td>
                          <td className="respoactionproduct">
                            <button
                              type="button"
                              className="btn actiontblebtn"
                              onClick={() =>
                                Navigate(
                                  `${VIEW_SCHEME_MANAGEMENT_URL}/${user?.id}`
                                )
                              }
                            >
                              <i className="bi bi-eye"></i>
                            </button>
                            {(selfInfo?.is_admin ||
                              selfInfo?.permissions?.includes(
                                "update-product"
                              )) && (
                              <button
                                type="button"
                                className="btn actiontblebtn"
                                onClick={() =>
                                  Navigate(
                                    `${EDIT_SCHEME_MANAGEMENT_URL}/${user?.id}`
                                  )
                                }
                              >
                                <i className="bi bi-pencil-square"></i>
                              </button>
                            )}
                            {(selfInfo?.is_admin ||
                              selfInfo?.permissions?.includes(
                                "delete-product"
                              )) && (
                              <button
                                type="button"
                                className="btn actiontblebtn"
                                onClick={() => DeleteProductFnct(user)}
                              >
                                <i
                                  className="bi bi-trash-fill"
                                  style={{ color: "red" }}
                                ></i>
                              </button>
                            )}
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                {schemeList && schemeList?.list?.length === 0 && (
                  <tr>
                    <td style={{ textAlign: "center" }} colSpan={10}>
                      Schemes Not Found !
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Scrollbars>
        {/* pagination */}
        {parseInt(schemeList?.pagination?.total_records) > 5 && (
          <CommonPagination
            currentPage={currentPage}
            paginationLength={schemeList?.pagination}
            currentFunction={currentFunction}
          />
        )}
      </div>
      {showModalNew?.open && (
        <CommonewModel
          setCurrentPage={setCurrentPage}
          setShowModalNew={setShowModalNew}
          showModalNew={showModalNew}
        />
      )}
    </section>
  );
};

export default SchemeMangement;
