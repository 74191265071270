import React  from "react";

const InputField = ({ value, label, className, name, placeholder, type, onChange, onKeyDown, onKeyUp, errormessage, onClick, showpassword, showOldpassword }) => (
    <div className={errormessage !== "" ? "form-group error-form-group" : "form-group"}>
        {label && <label htmlFor="input-field" dangerouslySetInnerHTML={{__html: label}}></label>}
        <div className="formInput">
            {name === "Password"&&(<input type="password" style={{ display: "none" }}/>)}
            {(name === "Address" || name === "message" || name === "Description" || name === "DescriptionFrench" || name === "DescriptionEnglish") ? (<textarea 
                type={type}
                value={value}
                name={name}
                className={className}
                placeholder={placeholder}
                onChange={onChange}
                onKeyDown={onKeyDown}
                onKeyUp={onKeyUp}
            />) : (<input
                type={type}
                value={value}
                name={name}
                className={className}
                placeholder={placeholder}
                onChange={onChange}
                onKeyDown={onKeyDown}
                onKeyUp={onKeyUp}
                autoComplete="off"
            />)}
            {name === "Password" && (<button className="eyeshowbtn" onClick={onClick}>{showpassword ? <i className="bi bi-eye"></i> : <i className="bi bi-eye-slash"></i>}</button>)}
            {name === "Old_password" && (<button className="eyeshowbtn" onClick={onClick}>{showOldpassword ? <i className="bi bi-eye"></i> : <i className="bi bi-eye-slash"></i>}</button>)}
        </div>
        {errormessage !== "" && (<div className="errormendatorr"><i className="bi bi-x-circle-fill"></i>{errormessage}</div>)}
    </div>
);

export default InputField;