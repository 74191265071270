/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";

const CommonewModel = (props) => {
  const { websocket } = useContext(WebSocketContext);
  const { showModalNew, setShowModalNew, setCurrentPage } = props;
  const { device_id } = useSelector((state) => state.adminReducers);

  // close modal
  const handleClose = () => {
    setShowModalNew({ ...showModalNew, open: false });
  };

  const CommonFunction = async () => {
    if (showModalNew?.subtitle === "Active Scheme") {
      let param = {
        transmit: "broadcast",
        url: "update_scheme",
        request: {
          scheme_id: showModalNew?.Data?.id,
          is_active: showModalNew?.Data?.is_active ? 0 : 1,
          avatar:
            showModalNew?.Data?.avatar === ""
              ? null
              : showModalNew?.Data?.avatar,
          name: showModalNew?.Data?.name,
          language: showModalNew?.Data?.language,
          category: showModalNew?.Data?.category,
          provider: showModalNew?.Data?.provider,
          documents: showModalNew?.Data?.documents,
          start_date: showModalNew?.Data?.start_date,
          end_date: showModalNew?.Data?.end_date,
          eligibility: showModalNew?.Data?.eligibility,
          description: showModalNew?.Data?.description,
          benefits: showModalNew?.Data?.benefits,
          lang_parent: showModalNew?.Data?.lang_parent,

          DeviceId: device_id,
        },
      };
      // console.log("update_scheme param",param)
      wsSend_request(websocket, param);
      CommonListUpdate("scheme_list");
      handleClose();
    }else if (showModalNew?.subtitle === "Active Document") {
      let param = {
        transmit: "broadcast",
        url: "update_document",
        request: {
          document_id: showModalNew?.Data?.id,
          is_active: showModalNew?.Data?.is_active ? "0" : "1",
          DeviceId: device_id,
          name: showModalNew?.Data?.name,
          description: showModalNew?.Data?.description,
          lang_parent: showModalNew?.Data?.lang_parent,
        },
      };
      // console.log("document_list upadte",param)
      wsSend_request(websocket, param);
      CommonListUpdate("document_list");
      handleClose();
    } 
    else if (showModalNew?.subtitle === "Active FAQ") {
      let param = {
        transmit: "broadcast",
        url: "update_faq",
        request: {
          faq_id: showModalNew?.Data?.id,
          is_active: showModalNew?.Data?.is_active ? "0" : "1",
          DeviceId: device_id,
          question: showModalNew?.Data?.question,
          answer: showModalNew?.Data?.answer,
          lang_parent: showModalNew?.Data?.lang_parent,
        },
      };
      // console.log("document_list upadte",param)
      wsSend_request(websocket, param);
      CommonListUpdate("faq_list");
      handleClose();
    } 
    else if (showModalNew?.subtitle === "Delete Scheme") {
      let param = {
        transmit: "broadcast",
        url: "delete_scheme",
        request: {
          scheme_id: showModalNew?.Data?.id,
          DeviceId: device_id,
        },
      };
      wsSend_request(websocket, param);
      CommonListUpdate("scheme_list");
      handleClose();
    } else if (showModalNew?.subtitle === "Delete Document") {
      let param = {
        transmit: "broadcast",
        url: "delete_document",
        request: {
          document_id: showModalNew?.Data?.id,
        },
      };
      // console.log("param delete doc", param, showModalNew?.Data?.id);
      wsSend_request(websocket, param);
      CommonListUpdate("document_list");
      handleClose();
    }else if (showModalNew?.subtitle === "Delete FAQ") {
      let param = {
        transmit: "broadcast",
        url: "delete_faq",
        request: {
          faq_id: showModalNew?.Data?.id,
        },
      };
      // console.log("param delete doc", param, showModalNew?.Data?.id);
      wsSend_request(websocket, param);
      CommonListUpdate("faq_list");
      handleClose();
    }
  };

  // When editing API calling, at that time list API calling.
  const CommonListUpdate = (url) => {
    if (url) {
      let param = {
        transmit: "broadcast",
        url: url,
        request: {
          filter: "all",
          limit: 10,
          page_no: 1,
          search: "",
          DeviceId: device_id,
          type:"admin"
        },
      };
      wsSend_request(websocket, param);
      setCurrentPage(1);
    }
  };

  return (
    <Modal
      className={
        showModalNew?.title === "Create Supplier"
          ? "commonModal addSupplierModel"
          : "commonModal"
      }
      show={showModalNew?.open}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title
          style={{
            width: showModalNew?.title === "Are you sure?" ? "100%" : "",
          }}
        >
          {showModalNew?.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showModalNew?.modalType === "form" ? (
          <></>
        ) : (
          <>
            <div className="notformsection">
              {showModalNew?.subtitle === "Logout" && (
                <i className="bi bi-box-arrow-right logoutIconbtn"></i>
              )}
              <p
                dangerouslySetInnerHTML={{ __html: showModalNew?.description }}
              ></p>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          className="addbtncmn"
          onClick={CommonFunction}
        >
          {showModalNew?.button}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CommonewModel;
