export const LOGIN_URL = "/login";
export const FORGOT_PASSWORD_URL = "/forgot-password";
export const RESET_PASSWORD_URL = "/reset-password";
export const DASHBOARD_URL = "/dashboard";
export const USER_MANAGEMENT_URL = "/user-management";
export const ADD_USER_URL = "/add-user";
export const EDIT_USER_URL = "/edit-user";
export const VIEW_PROFILE_URL = "/profile";
export const DOCUMENT_MANAGEMENT_URL="/document-management";
export const ADD_DOCUMENT_MANAGEMENT_URL = "/add-document";
export const EDIT_DOCUMENT_MANAGEMENT_URL = "/edit-document";
export const VIEW_DOCUMENT_MANAGEMENT_URL = "/view-document";
export const FAQ_MANAGEMENT_URL="/faq-management";
export const ADD_FAQ_MANAGEMENT_URL = "/add-faq";
export const EDIT_FAQ_MANAGEMENT_URL = "/edit-faq";
export const VIEW_FAQ_MANAGEMENT_URL = "/view-faq";
export const NOTI_MANAGEMENT_URL="/notification-management";
export const ADD_NOTI_MANAGEMENT_URL = "/add-notification";
export const EDIT_NOTI_MANAGEMENT_URL = "/edit-notification";
export const VIEW_NOTI_MANAGEMENT_URL = "/view-notification";
export const SCHEME_MANAGEMENT_URL = "/scheme-management";
export const ADD_SCHEME_MANAGEMENT_URL = "/add-scheme";
export const EDIT_SCHEME_MANAGEMENT_URL = "/edit-scheme";
export const VIEW_SCHEME_MANAGEMENT_URL = "/view-scheme";
export const CONTACT_MANAGEMENT_URL = "/contact-us";
export const ADD_CONTACT_MANAGEMENT_URL = "/add-contact-us";
export const EDIT_CONTACT_MANAGEMENT_URL = "/edit-contact-us";
export const VIEW_CONTACT_MANAGEMENT_URL = "/view-contact-us";
export const CATEGORY_MANAGEMENT_URL = "/category-management";
export const SCHEME_PROVIDER_MANAGEMENT_URL = "/scheme-provider-management";
// export const WEB_APP_URL ="https://gpolicy.appristine.in/"; 
export const WEB_APP_URL ="https://api.schemebharat.com/";
export const SEND_NOTI_URL ="admin/send-notification";
export const PRIVACY_POLICY ="/privacy-policy"
export const DELETE_USER ="/delete-user"


// Api login urls
export const API_URL = process.env.REACT_APP_BASE_URL;
export const LOGIN_API_URL = API_URL + "admin/login";
export const LOGOUT_API_URL = API_URL + "user/logout";
export const GET_DASHBOARD_API_URL = API_URL + "admin/dashboard";
export const FORGOT_PASSWORD_API_URL = API_URL + "user/forgot-password";
export const CREATE_PASSWORD_API_URL = API_URL + "user/create-password";
export const ADMIN_ADD_USER_API_URL = API_URL + "admin/user/add";
export const ADMIN_UPDATE_USER_API_URL = API_URL + "admin/user/update";
export const ADMIN_GET_USER_LIST_API_URL = API_URL + "admin/user/list";
export const ADMIN_DELETE_USER_LIST_API_URL = API_URL + "admin/user/delete";
export const ADMIN_DELETE_USER_API_URL = API_URL + "user/delete-account";



//  Contact Us List
export const CONTACT_US_LIST = API_URL + "admin/contact-us/list"

// upload file urls
export const UPLOAD_FILE_API_URL = API_URL + "file/upload";

// import product file urls
export const IPMPORT_PRODUCT_FILE_API_URL = API_URL + "file/import/product";

// export product urls
export const EXPORT_PRODUCT_FILE_API_URL = API_URL + "file/export/product";

export const LANGUAGE_LIST = API_URL + "user/languages";
 

export const englishStates = [
  "Central Government" ,
  "Andaman and Nicobar Islands",
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chandigarh",
  "Chhattisgarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Delhi",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Ladakh",
  "Lakshadweep",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Puducherry",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal"
]
export const hindiStates =[
  "केंद्र सरकार",
  "अंडमान और निकोबार द्वीप समूह",
  "आंध्र प्रदेश",
  "अरुणाचल प्रदेश",
  "असम",
  "बिहार",
  "चंडीगढ़",
  "छत्तीसगढ़",
  "दादरा और नगर हवेली और दमन और दीव",
  "दिल्ली",
  "गोवा",
  "गुजरात",
  "हरियाणा",
  "हिमाचल प्रदेश",
  "झारखंड",
  "कर्नाटक",
  "केरल",
  "लद्दाख",
  "लक्षद्वीप",
  "मध्य प्रदेश",
  "महाराष्ट्र",
  "मणिपुर",
  "मेघालय",
  "मिज़ोरम",
  "नागालैंड",
  "ओडिशा",
  "पुडुचेरी",
  "पंजाब",
  "राजस्थान"
]
export const Locations = {
  "Andaman and Nicobar Islands": "AN",
  "Andhra Pradesh": "AP",
  "Arunachal Pradesh": "AR",
  "Assam": "AS",
  "Bihar": "BR",
  "Chandigarh": "CH",
  "Chhattisgarh": "CT",
  "Dadra and Nagar Haveli and Daman and Diu": "DN",
  "Delhi": "DL",
  "Goa": "GA",
  "Gujarat": "GJ",
  "Haryana": "HR",
  "Himachal Pradesh": "HP",
  "Jharkhand": "JH",
  "Karnataka": "KA",
  "Kerala": "KL",
  "Ladakh": "LA",
  "Lakshadweep": "LD",
  "Madhya Pradesh": "MP",
  "Maharashtra": "MH",
  "Manipur": "MN",
  "Meghalaya": "ML",
  "Mizoram": "MZ",
  "Nagaland": "NL",
  "Odisha": "OR",
  "Puducherry": "PY",
  "Punjab": "PB",
  "Rajasthan": "RJ",
  "Sikkim": "SK",
  "Tamil Nadu": "TN",
  "Telangana": "TG",
  "Tripura": "TR",
  "Uttar Pradesh": "UP",
  "Uttarakhand": "UK",
  "West Bengal": "WB",
};