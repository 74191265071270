/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import DataTableFilter from "../Common/DataTableFilter";
import { Scrollbars } from "react-custom-scrollbars-2";
import CommonToggle from "../Common/CommonToggle";
import moment from "moment";
import { useSelector } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import CommonPagination from "../Common/CommonPagination";
import CommonModel from "../Common/CommonModel";

const CategoryManagement = () => {
  const { websocket } = useContext(WebSocketContext);
  const { selfInfo, categoryList } = useSelector(
    (state) => state.adminReducers
  );
  const [userType, setUserType] = useState("");
  const [userLimit, setUserLimit] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [userSearch, setUserSearch] = useState("");
  const [showModal, setShowModal] = useState({
    open: false,
    title: "",
    modalType: "",
    Data: {},
  });
  const [selectedLanguage, setselectedLanguage] = useState("");
  // select user language
  const SelectUserLangTypeFnct = (e) => {
    setselectedLanguage(e.target.value);
    setCurrentPage(1);
  };
  // page change
  const currentFunction = (page) => {
    setCurrentPage(page);
  };

  // clear search box
  const ClearSearchFiled = () => {
    setUserSearch("");
  };

  // select user type
  const SelectUserTypeFnct = (e) => {
    setUserType(e.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    let param = {
      transmit: "broadcast",
      url: "scheme_category_list",
      request: {
        filter: userType || "all",
        language: selectedLanguage || "all",
        limit: userLimit || 5,
        page_no: currentPage,
        search: userSearch.trim(),
        type: "admin",
      },
    };
    wsSend_request(websocket, param);
  }, [userSearch, userType, userLimit, currentPage, selectedLanguage]);

  // add new category
  const AddCategoryFnct = () => {
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Add Category",
      subtitle: "Add Category",
      description: ``,
      modalType: "form",
      button: "Yes",
    });
  };

  // edit category
  const EditCategory = (data_) => {
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Edit Category",
      subtitle: "Edit Category",
      description: ``,
      modalType: "form",
      button: "Save",
      Data: data_,
    });
  };

  // delete category
  const DeleteCategoryFnct = (data_) => {
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Delete Category",
      subtitle: "Delete Category",
      description: `Do you really want to delete <h5>${data_?.name}</h5>`,
      modalType: "not form",
      button: "Yes",
      Data: data_,
    });
  };

  // active 
  const AllowCategoryGet = (data_, type, booleancheck) => {
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Are you sure?",
      subtitle: "Active Category",
      description: `Do you really want to ${booleancheck ? "remove " : ""} ${
        type.split("_")[1]
      } to <h5>${data_?.name}</h5>`,
      modalType: "not form",
      button: "Yes",
      Data: data_,
    });
  };

  return (
    <section className="dashboard_wrapper">
      <DataTableFilter
        filterType={"Category"}
        tooltip="name"
        languageType={selectedLanguage}
        setselectedLanguage={setselectedLanguage}
        SelectUserLangTypeFnct={SelectUserLangTypeFnct}
        userSearch={userSearch}
        setUserSearch={setUserSearch}
        userType={userType}
        setUserType={setUserType}
        userLimit={userLimit}
        setUserLimit={setUserLimit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        ClearSearchFiled={ClearSearchFiled}
        SelectUserTypeFnct={SelectUserTypeFnct}
        buttonvisible={
          selfInfo?.is_admin || selfInfo?.permissions?.includes("add-category")
        }
        ButtonSet={{
          buttontitle: "Add Category",
          function: AddCategoryFnct,
        }}
      />
      <div
        className="tableContent"
        style={{
          height:
            window.screen.width > 540
              ? "calc(100vh - 155px)"
              : "calc(100vh - 143px)",
        }}
      >
        <Scrollbars
          style={{
            height:
            parseInt(categoryList?.pagination?.total_records) > 5
                ? window.screen.width < 767
                  ? "calc(100% - 10px)"
                  : "calc(100% - 38px)"
                : "calc(100% - 0px)",
          }}
          className="ScrollbarsWrapper"
          renderView={(props) => <div {...props} className="view" />}
        >
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Language</th>

                  {(selfInfo?.is_admin ||
                    selfInfo?.permissions?.includes("update-category")) && (
                    <th>Active</th>
                  )}
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {categoryList &&
                  categoryList?.list?.map((user, index) => {
                    return (
                      <tr key={index}>
                        <td>{user?.name}</td>
                        <td>{user?.language}</td>
                        {(selfInfo?.is_admin ||
                          selfInfo?.permissions?.includes(
                            "update-category"
                          )) && (
                          <td>
                            <CommonToggle
                              valueToggle={user?.is_active}
                              setValueToggle={() =>
                                AllowCategoryGet(
                                  user,
                                  "is_active",
                                  user?.is_active
                                )
                              }
                              name={""}
                            />
                          </td>
                        )}
                        <td>{moment(user.created_at).format("LL")}</td>
                        <td className="respoactionSupplier">
                          {(selfInfo?.is_admin ||
                            selfInfo?.permissions?.includes(
                              "update-category"
                            )) && (
                            <button
                              type="button"
                              className="btn actiontblebtn"
                              onClick={() => EditCategory(user)}
                            >
                              <i className="bi bi-pencil-square"></i>
                            </button>
                          )}
                          {(selfInfo?.is_admin ||
                            selfInfo?.permissions?.includes(
                              "delete-category"
                            )) && (
                            <button
                              type="button"
                              className="btn actiontblebtn"
                              onClick={() => DeleteCategoryFnct(user)}
                            >
                              <i
                                className="bi bi-trash-fill"
                                style={{ color: "red" }}
                              ></i>
                            </button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                {categoryList && categoryList?.list.length === 0 && (
                  <tr>
                    <td style={{ textAlign: "center" }} colSpan={8}>
                      Category Not Found !
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Scrollbars>
        {/* pagination */}
        {parseInt(categoryList?.pagination?.total_records) > 5 && (
          <CommonPagination
            currentPage={currentPage}
            paginationLength={categoryList?.pagination}
            currentFunction={currentFunction}
          />
        )}
      </div>
      {showModal?.open && (
        <CommonModel
          setUserSearch={setUserSearch}
          setCurrentPage={setCurrentPage}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </section>
  );
};

export default CategoryManagement;
