/* eslint-disable */
import React, { useEffect, useState, createContext } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "../src/assets/css/style.css";
import "../src/assets/css/media.css";
import {
  LOGIN_URL,
  FORGOT_PASSWORD_URL,
  RESET_PASSWORD_URL,
  SCHEME_PROVIDER_MANAGEMENT_URL,
  EDIT_SCHEME_MANAGEMENT_URL,
  EDIT_DOCUMENT_MANAGEMENT_URL,
  EDIT_CONTACT_MANAGEMENT_URL,
  VIEW_PROFILE_URL,
  ADD_USER_URL,
  EDIT_USER_URL,
  VIEW_CONTACT_MANAGEMENT_URL,
  VIEW_DOCUMENT_MANAGEMENT_URL,
  VIEW_SCHEME_MANAGEMENT_URL,
  ADD_CONTACT_MANAGEMENT_URL,
  DASHBOARD_URL,
  USER_MANAGEMENT_URL,
  DOCUMENTT_MANAGEMENT_URL,
  SCHEME_MANAGEMENT_URL,
  ADD_DOCUMENT_MANAGEMENT_URL,
  ADD_SCHEME_MANAGEMENT_URL,
  CONTACT_MANAGEMENT_URL,
  CATEGORY_MANAGEMENT_URL,
  DOCUMENT_MANAGEMENT_URL,
  ADD_FAQ_MANAGEMENT_URL,
  EDIT_FAQ_MANAGEMENT_URL,
  VIEW_FAQ_MANAGEMENT_URL,
  FAQ_MANAGEMENT_URL,
  NOTI_MANAGEMENT_URL,
  ADD_NOTI_MANAGEMENT_URL,
  EDIT_NOTI_MANAGEMENT_URL,
  VIEW_NOTI_MANAGEMENT_URL,
  PRIVACY_POLICY,
  DELETE_USER,
} from "./components/Shared/constant";
import GuestLayout from "./components/Shared/GuestLayout";
import AuthLayout from "./components/Shared/AuthLayout";
import Login from "./components/Login";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import Dashboard from "./components/Dashboard";
import UserManagement from "./components/UserManagement";
import SchemeMangement from "./components/SchemeMangement";
import ContactUs from "./components/ContactUs";
import AddEditScheme from "./components/SchemeMangement/AddEditScheme";
import ViewScheme from "./components/SchemeMangement/ViewScheme";
import CategoryManagement from "./components/CategoryManagement";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Profile from "./components/Profile";
import { ws_response } from "./Api/ws/ws_response";
import GetUserListAPI from "./Api/GetUserListAPI";
import {
  getUserListIdWise,
  clearRedux,
  checkLogin,
} from "./redux/actions/adminActions";
import CommonLogout from "./components/Common/CommonLogout";
import SchemeProviderManagement from "./components/SchemeProviderManagement";
import DocumentManagement from "./components/Common/DocumentManagement";
import ViewDocument from "./components/Common/DocumentManagement/ViewDocument";
import AddEditDocument from "./components/Common/DocumentManagement/AddEditDocument";
import FAQManagement from "./components/Common/FAQManagement/Index";
import ViewFAQ from "./components/Common/FAQManagement/ViewFAQ";
import AddEditFAQ from "./components/Common/FAQManagement/AddEditFAQ";
import NotiManagement from "./components/Common/NotificationManagement/Index";
import PrivacyPolicy from "./components/ContactUs/PrivacyPolicy";
import DeleteAccount from "./components/ContactUs/DeleteAccount";
export const WebSocketContext = createContext();
window.axios = axios;
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

function App(props) {
  const Dispatch = useDispatch();
  const { accessToken, is_login, ClearCaches_setww, loaderMain, selfInfo } =
    useSelector((state) => state.adminReducers);
  const [GetWEBSocket, setGetWEBSocket] = useState("");

  // websocket connections
  useEffect(() => {
    if (accessToken !== "") {
      WebSocketConnect();
    }
  }, [accessToken]);

  const WebSocketConnect = () => {
    var ws = new WebSocket(
      `${process.env.REACT_APP_SERVICE_URL}/${accessToken}/`
    );
    setGetWEBSocket(ws);
    ws.onopen = function (event) {
      console.log("connected", event);
    };
    ws.onmessage = function (evt) {
      Dispatch(ws_response({ evt: evt, ws: ws }));
      const ws_onmessage = JSON.parse(evt.data);
      if (ws_onmessage?.url === "reconnect") {
        props?.callRender(
          `${process.env.REACT_APP_SERVICE_URL}/${accessToken}/`,
          accessToken
        );
      }
    };
    ws.onclose = async function (evt) {
      console.log("websocket is closed", evt);
      setTimeout(function () {
        WebSocketConnect();
      }, 1000);
      if (navigator.onLine === false) {
        props?.callRender(
          `${process.env.REACT_APP_SERVICE_URL}/${accessToken}/`,
          accessToken
        );
      }
    };
    window.addEventListener("online", () => {
      window.location.reload();
    });
    window.addEventListener("offline", () => {
      window.location.reload();
    });
    return () => {
      ws.close();
    };
  };

  // get user list
  useEffect(() => {
    if (accessToken && selfInfo?.is_admin === true) {
      GetUserList();
    }
  }, [accessToken]);

  useEffect(() => {
    if (ClearCaches_setww === null || ClearCaches_setww === undefined) {
      Dispatch(clearRedux());
      localStorage.clear();
      Dispatch(checkLogin("no"));
    }
  }, [ClearCaches_setww]);

  const GetUserList = async () => {
    const userList = await GetUserListAPI(accessToken, 0, "all", 100, 1);
    if (userList?.status === 200) {
      Dispatch(getUserListIdWise(userList.data));
    } else if (userList?.status === 403) {
      CommonLogout(accessToken, Dispatch);
      Dispatch(getUserListIdWise([]));
    } else {
      Dispatch(getUserListIdWise([]));
    }
  };

  const RoutUrlSet = () => {
    if (accessToken === "" && is_login === "no") {
      return LOGIN_URL;
    } else {
      if (selfInfo?.is_admin || selfInfo?.permissions?.includes("dashboard")) {
        return DASHBOARD_URL;
      } else {
        if (selfInfo?.permissions !== null) {
          if (
            selfInfo?.permissions?.split(",")[0] === "user-list" ||
            selfInfo?.permissions?.split(",")[0] === "add-user" ||
            selfInfo?.permissions?.split(",")[0] === "update-user" ||
            selfInfo?.permissions?.split(",")[0] === "delete-user"
          ) {
            return USER_MANAGEMENT_URL;
          } else if (
            selfInfo?.permissions?.split(",")[0] === "product-list" ||
            selfInfo?.permissions?.split(",")[0] === "add-product" ||
            selfInfo?.permissions?.split(",")[0] === "update-product" ||
            selfInfo?.permissions?.split(",")[0] === "delete-product"
          ) {
            return SCHEME_MANAGEMENT_URL;
          } else if (
            selfInfo?.permissions?.split(",")[0] === "product-setting-list" ||
            selfInfo?.permissions?.split(",")[0] === "add-product-setting" ||
            selfInfo?.permissions?.split(",")[0] === "update-product-setting" ||
            selfInfo?.permissions?.split(",")[0] === "delete-product-setting"
          ) {
            return SCHEME_PROVIDER_MANAGEMENT_URL;
          }
        }
      }
    }
  };
  
  return (
    <WebSocketContext.Provider value={{ websocket: GetWEBSocket }}>
      <div className="wrapper">
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<Navigate replace to={RoutUrlSet()} />} />
            <Route
            path={PRIVACY_POLICY}
            element={
             
                  <PrivacyPolicy />
            
            }
          >
         
          </Route>
          <Route
          path={DELETE_USER}
          element={
            <GuestLayout>
           
                <DeleteAccount />
                </GuestLayout>
          
          }
        ></Route>
            <Route
              path="/"
              element={
                accessToken === "" && is_login === "no" ? (
                  <GuestLayout>
                    <Login />
                  </GuestLayout>
                ) : (
                  <Navigate replace to={RoutUrlSet()} />
                )
              }
            >
            
            
            
              <Route
                path={LOGIN_URL}
                element={
                  accessToken === "" && is_login === "no" ? (
                    <GuestLayout>
                      <Login />
                    </GuestLayout>
                  ) : (
                    <Navigate replace to={RoutUrlSet()} />
                  )
                }
              />
            </Route>
            <Route
              path={FORGOT_PASSWORD_URL}
              element={
                <GuestLayout>
                  <ForgotPassword />
                </GuestLayout>
              }
            />
            <Route
              path={`${RESET_PASSWORD_URL}/:roomId`}
              element={
                <GuestLayout>
                  <ResetPassword />
                </GuestLayout>
              }
            />
            <Route
              path={DASHBOARD_URL}
              element={
                accessToken !== "" && is_login !== "no" ? (
                  <AuthLayout>
                    <Dashboard />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={USER_MANAGEMENT_URL}
              element={
                accessToken !== "" && is_login !== "no" ? (
                  <AuthLayout>
                    <UserManagement />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={VIEW_PROFILE_URL}
              element={
                accessToken !== "" && is_login !== "no" ? (
                  <AuthLayout>
                    <Profile />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={SCHEME_MANAGEMENT_URL}
              element={
                accessToken !== "" && is_login !== "no" ? (
                  <AuthLayout>
                    <SchemeMangement />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={`${VIEW_SCHEME_MANAGEMENT_URL}/:roomId`}
              element={
                accessToken !== "" && is_login !== "no" ? (
                  <AuthLayout>
                    <ViewScheme />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={ADD_SCHEME_MANAGEMENT_URL}
              element={
                accessToken !== "" && is_login !== "no" ? (
                  <AuthLayout>
                    <AddEditScheme />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={`${EDIT_SCHEME_MANAGEMENT_URL}/:roomId`}
              element={
                accessToken !== "" && is_login !== "no" ? (
                  <AuthLayout>
                    <AddEditScheme />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={CONTACT_MANAGEMENT_URL}
              element={
                accessToken !== "" && is_login !== "no" ? (
                  <AuthLayout>
                    <ContactUs />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={CATEGORY_MANAGEMENT_URL}
              element={
                accessToken !== "" && is_login !== "no" ? (
                  <AuthLayout>
                    <CategoryManagement />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={SCHEME_PROVIDER_MANAGEMENT_URL}
              element={
                accessToken !== "" && is_login !== "no" ? (
                  <AuthLayout>
                    <SchemeProviderManagement />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={DOCUMENT_MANAGEMENT_URL}
              element={
                accessToken !== "" && is_login !== "no" ? (
                  <AuthLayout>
                    <DocumentManagement />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={`${VIEW_DOCUMENT_MANAGEMENT_URL}/:roomId`}
              element={
                accessToken !== "" && is_login !== "no" ? (
                  <AuthLayout>
                    <ViewDocument />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={ADD_DOCUMENT_MANAGEMENT_URL}
              element={
                accessToken !== "" && is_login !== "no" ? (
                  <AuthLayout>
                    <AddEditDocument />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={`${EDIT_DOCUMENT_MANAGEMENT_URL}/:roomId`}
              element={
                accessToken !== "" && is_login !== "no" ? (
                  <AuthLayout>
                    <AddEditDocument />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={FAQ_MANAGEMENT_URL}
              element={
                accessToken !== "" && is_login !== "no" ? (
                  <AuthLayout>
                    <FAQManagement />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={`${VIEW_FAQ_MANAGEMENT_URL}/:roomId`}
              element={
                accessToken !== "" && is_login !== "no" ? (
                  <AuthLayout>
                    <ViewFAQ />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={ADD_FAQ_MANAGEMENT_URL}
              element={
                accessToken !== "" && is_login !== "no" ? (
                  <AuthLayout>
                    <AddEditFAQ />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={`${EDIT_FAQ_MANAGEMENT_URL}/:roomId`}
              element={
                accessToken !== "" && is_login !== "no" ? (
                  <AuthLayout>
                    <AddEditFAQ />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={NOTI_MANAGEMENT_URL}
              element={
                accessToken !== "" && is_login !== "no" ? (
                  <AuthLayout>
                    <NotiManagement />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
          </Routes>
        </BrowserRouter>
        <ToastContainer />
      </div>
    </WebSocketContext.Provider>
  );
}

export default App;
